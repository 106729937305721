export const redirectGlobalActionTypes = {
  REDIRECT: 'REDIRECT',
}

export function liveCasinoSetPath(path) {
  return { type: redirectGlobalActionTypes.REDIRECT, payload: path }
}

export function CasinoSetPath(path) {
  return { type: redirectGlobalActionTypes.REDIRECT, payload: path }
}
