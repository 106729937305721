import { TombolaActionTypes } from '@clean/application/redux/tombola/constant';
import produce from 'immer';

const tombolaInitialState = {
  loadingTombola: false,
  isParticipatingTombola: false
}

const reducer = (state = tombolaInitialState, action) => {
  switch (action.type) {
    case TombolaActionTypes.REQUEST_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingTombola = true;
      });
    case TombolaActionTypes.SUCCESS_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingTombola = false;
        draft.isParticipatingTombola = action.payload;
      });
    case TombolaActionTypes.REJECTED_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingTombola = false;
        draft.isParticipatingTombola = false;
      });

    default:
      return state
  }
}

export default reducer
