import { all } from 'redux-saga/effects'

import auth from '@clean/application/redux/auth/sagas/auth';
import balances from '@clean/application/redux/balance/sagas/balances';
import layout from '@clean/application/redux/layout/sagas/layout' 
import liveCasino from '@clean/application/redux/games/sagas/liveCasino'
import casino from '@clean/application/redux/games/sagas/casino'
import bonus from '@clean/application/redux/bonus/sagas/bonus';
import deposit from '@clean/application/redux/deposit/sagas/deposit';
import bonusPromotional from '@clean/application/redux/bonus/sagas/bonusPromotional'
import depositConfirm from '@clean/application/redux/deposit/sagas/depositConfirm'
import preferences from '@clean/application/redux/games/sagas/preferences'
import bonusCodePromotional from '@clean/application/redux/bonus/sagas/bonusCodePromotional'
import fp from '@clean/application/redux/fp/sagas/fp'
import Ranking from '@clean/application/redux/leaderboard/sagas/ranking'
import Footer from '@clean/application/redux/layout/sagas/footer';
import SecurityVPN from '@clean/application/redux/security/sagas/securitySagas';
import Promotions from '@clean/application/redux/promotions/sagas/index';
import Strapi from '@clean/application/redux/strapi/sagas/leaderboard';
import Tombola from '@clean/application/redux/tombola/sagas/index';
import Leaderboard from '@clean/application/redux/leaderboard/sagas/index';
import aclTruePlay from '@clean/application/redux/aclTruePlay/sagas/index';
import responsibleGambling from '@clean/application/redux/strapi/sagas/responsibleGambling';
import sessionReminder from '@clean/application/redux/sessionReminder/sagas/sessionReminder';
import limitDeposit from '@clean/application/redux/limitDeposit/sagas/limitDeposit';
import selfExclusion from '@clean/application/redux/selfExclusion/sagas/selfExclusion';
import colorConfig from '@clean/application/redux/strapi/sagas/colorConfig';
import registerConfig from '@clean/application/redux/strapi/sagas/registerConfig';
import register from '@clean/application/redux/register/register';

export default function* rootSaga() {
  yield all([
    aclTruePlay,
    auth,
    balances,
    layout,
    deposit,
    colorConfig,
    registerConfig,
    casino,
    liveCasino,
    bonus,
    bonusPromotional,
    register,
    depositConfirm,
    preferences,
    bonusCodePromotional,
    fp,
    Footer,
    SecurityVPN,
    Ranking,
    Tombola,
    Promotions,
    Leaderboard,
    Strapi,
    responsibleGambling,
    sessionReminder,
    limitDeposit,
    selfExclusion
  ]);
}
