import produce from 'immer';
import { balanceActionTypes, balanceInitialState } from '../constants/balance';

const reducer = (state = balanceInitialState, action: any) => {
  switch (action.type) {
    case balanceActionTypes.PUTSHOWBALANCE:
      return produce(state, draft => {
        draft.showBalance = action.payload
      });
    case balanceActionTypes.PUTAMOUNTBALANCE:
      return produce(state, draft => {
        draft.sb = Number(action.payload.balances.sb);
        draft.sa = Number(action.payload.balances.sa);
        draft.spr = Number(action.payload.balances.spr);
        draft.snu = Number(action.payload.balances.snu);
        draft.su = Number(action.payload.balances.su);
        draft.total = Number(action.payload.balance);
        draft.totalRetiro = Number(action.payload.balances.su) + Number(action.payload.balances.sa);
      });
    case balanceActionTypes.CLEARBALANCE:
      return produce(state, draft => {
        draft.sb = 0;
        draft.sa = 0;
        draft.spr = 0;
        draft.snu = 0;
        draft.su = 0;
        draft.total = 0;
        draft.totalRetiro = 0;
      });
    default:
      return state
  }
}

export default reducer
