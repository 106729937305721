import {AclTruePlayRepository } from '@clean/domain/repositories/acl-truePlay/AclTruePlayRepository';

export default class ACLTruePlayUseCase implements AclTruePlayRepository {
  aclTruePlayRepo: AclTruePlayRepository;

  constructor(AR:AclTruePlayRepository) {
    this.aclTruePlayRepo = AR;
  }

  async getUrlTruePlay(
    customerId: string
    ): Promise<any>{
    return this.aclTruePlayRepo.getUrlTruePlay(customerId);
  }
  async getTPBalance(
    customerId: string
    ): Promise<any>{
    return this.aclTruePlayRepo.getTPBalance(customerId);
  }
}
