import { redirectGlobalActionTypes } from "../actions/redirectGlobal"

const redirectInitialState = {
  path: null,
}

const reducer = (state = redirectInitialState, action) => {
  switch (action.type) {
    case redirectGlobalActionTypes.REDIRECT:
      return {
        path: action.payload,
      }
    default:
      return state
  }
}

export default reducer
