import { VPNRepository } from '@clean/domain/repositories/Vpn/VpnRepository';
import axios from 'axios';

export default class VpnRepositoryImpl implements VPNRepository {

  async ValidateOrigin(): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: vpnResult } = await axios.get(`/api/v2/security/validateOrigin`);
        resolver(
          vpnResult
        );
      } catch (error) {
        resolver(
          error
        );
      }
    });
  }

}
