import { preferencesActionTypes } from "../constants/preferences"

export function putFavoriteGame (state) {
  return { type: preferencesActionTypes.PUTFAVORITEGAME, payload: state }
}

export function searchGameRequestFavorite () {
  return { type: preferencesActionTypes.SEARCHGAMEREQUESTFAVORITE }
}

export function searchGameFavoriteSuccess (payload) {
  return { type: preferencesActionTypes.SEARCHGAMESUCCESSFAVORITE, payload }
}
