export const GET_HOME_LEADERBOARD_REQUEST = '@strapi/GET_HOME_LEADERBOARD_REQUEST';
export const GET_HOME_LEADERBOARD_SUCCESS = '@strapi/GET_HOME_LEADERBOARD_SUCCESS';
export const GET_HOME_LEADERBOARD_REJECTED = '@strapi/GET_HOME_LEADERBOARD_REJECTED';

export const GET_LEADERBOARD_REQUEST = '@strapi/GET_LEADERBOARD_REQUEST';
export const GET_LEADERBOARD_SUCCESS = '@strapi/GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_REJECTED = '@strapi/GET_LEADERBOARD_REJECTED';

export const GET_PROMOTIONS_LEADERBOARD_REQUEST = '@strapi/GET_PROMOTIONS_LEADERBOARD_REQUEST';
export const GET_PROMOTIONS_LEADERBOARD_SUCCESS = '@strapi/GET_PROMOTIONS_LEADERBOARD_SUCCESS';
export const GET_PROMOTIONS_LEADERBOARD_REJECTED = '@strapi/GET_PROMOTIONS_LEADERBOARD_REJECTED';

export const GET_MENU_LEADERBOARD_REQUEST = '@strapi/GET_MENU_LEADERBOARD_REQUEST';
export const GET_MENU_LEADERBOARD_SUCCESS = '@strapi/GET_MENU_LEADERBOARD_SUCCESS';
export const GET_MENU_LEADERBOARD_REJECTED = '@strapi/GET_MENU_LEADERBOARD_REJECTED';

export const GET_COUNTDOWN_REQUEST = '@strapi/GET_COUNTDOWN_REQUEST';
export const GET_COUNTDOWN_SUCCESS = '@strapi/GET_COUNTDOWN_SUCCESS';
export const GET_COUNTDOWN_REJECTED = '@strapi/GET_COUNTDOWN_REJECTED';

export const GET_JACKPOT_LADING_BY_ID_REQUEST = '@strapi/GET_JACKPOT_LADING_BY_ID_REQUEST';
export const GET_JACKPOT_LADING_BY_ID_SUCCESS = '@strapi/GET_JACKPOT_LADING_BY_ID_SUCCESS';
export const GET_JACKPOT_LADING_BY_ID_REJECTED = '@strapi/GET_JACKPOT_LADING_BY_ID_REJECTED';

export const GET_MENU_NAV_BAR_REQUEST = '@strapi/GET_MENU_NAV_BAR_REQUEST';
export const GET_MENU_NAV_BAR_SUCCESS = '@strapi/GET_MENU_NAV_BAR_SUCCESS';
export const GET_MENU_NAV_BAR_REJECTED = '@strapi/GET_MENU_NAV_BAR_REJECTED';