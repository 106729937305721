import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import {
  loginUser,
  updatedUser,
  logoutUser,
  readMessage,
  updateBalanceRequest,
  loginSuccess,
  loginFailed,
  forceLogoutRequest,
  getNotificationsRequest,
  getFavoriteGamesRequest,
  getBadgeRequest,
} from '@clean/application/redux/auth/actions/auth';
import { resetModal } from '@clean/application/redux/modal/actions/index';
import { BalanceTruePlayRequest } from '@clean/application/redux/aclTruePlay/actions/index';
import { resetDateLB } from '@clean/application/redux/leaderboard/actions/ranking';
import { updatedCountryDetected, updatedHashFinger, getFullFP, updatedDocumentType } from '@clean/application/redux/fp/actions/fp';
import { ChangeSelected } from '@clean/application/redux/layout/actions/SelectedLanguage';
import {
  ChangeBadgePersonalData,
  ChangeBadgeVerifyAccount,
  ChangeBadgeGeneral,
  ChangeBadgeUnreadMessage,
  ChangeFirstNotification,
  ClearNotification,
} from '@clean/application/redux/notifications/actions/badge';
import { ChangeCurrency } from '@clean/application/redux/currency/actions/currency';
import { putFavoriteGame } from '@clean/application/redux/games/actions/preferences';
import { putAmountBalance, clearBalance } from '@clean/application/redux/balance/actions/balance';
import { changeViewModalBonusRegister } from '@clean/application/redux/bonus/actions/bonusRegister';
import { initialStateBonus } from '@clean/application/redux/bonus/actions/bonus';
import axios from 'axios';
import moment from 'moment';
import * as gtag from '@lib/gtag';
import { srtmCommands } from '@lib/sportRadar';
import { getLoginMessage } from '../statics';
import { ICustomer } from '@interfaces/globals';
import { clearPromotions } from '@clean/application/redux/promotions/actions';
import { resetPendingActions } from '@clean/application/redux/pendingActions/actions/pendingActions';
import { haveDeposit } from '@clean/application/redux/deposit/actions/deposit';
import { requestRejectSelfExclusionStatus } from '@clean/application/redux/selfExclusion/actions/selfExclusion';
import { requestSessionReminderStatus } from '@clean/application/redux/sessionReminder/actions/sessionReminder';
import { getStrapiLeaderboardPrincipalRequest } from '@clean/application/redux/strapi/actions';
import Cookies from 'js-cookie';
import { COUNTRIES_LIST, DEFAULT_COUNTRY } from '@redux/fp/constants/countries';

type ParamsHandleLogin = {
  payloadLogin: any;
  type: string;
};

type ParamsNotifications = {
  payload: any;
  type: string;
};

function* getFavoriteGame() {
  try {
    const userInfo = clone(yield select((state) => state.auth.userInfo));
    const payloadGetFavoriteGame = { customerId: userInfo.customerId };
    const favoriteGameResponse = yield axios.post('/api/v2/games/GetFavoriteGameByUser', payloadGetFavoriteGame);

    if (favoriteGameResponse.data && favoriteGameResponse.data.data) {
      yield put(putFavoriteGame(favoriteGameResponse.data.data.responseObj.data));
    } else {
      return [];
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

function* updatedStateNotification(payload) {
  try {
    yield axios.post('/api/v2/apiNotification/ChangeStateNotification', payload);
    return true;
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

const postVerifySelfExclusion = async () => {
  try {
    const loginResponse = await axios.post('/api/v2/apiSelfExclusion/GetSelfExclusion');
    if (loginResponse.data && loginResponse.data.data) {
      return loginResponse.data.data.responseObj;
    } else {
      return loginResponse.data.error;
    }
  } catch (err) {
    return null;
  }
};

function* getBalance(userInfo) {
  try {
    const balanceResponse = yield axios.post('/api/v2/wallet/GetBalance', { "IdCustomer": userInfo.customerId });
    if (balanceResponse.data && balanceResponse.data.data) {
      return balanceResponse.data.data.responseObj;
    } else {
      return null;
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

function* getNotifications(userInfo) {
  let userInfoLocal = userInfo;

  if (!userInfoLocal?.customerId) {
    userInfoLocal = clone(yield select((state) => state.auth.userInfo));
  }

  if (!userInfoLocal) {
    return null;
  }

  try {
    const payloadGetNotifications = { customerId: userInfoLocal.customerId };
    const notificationsResponse = yield axios.post(
      '/api/v2/apiNotification/GetNotificationsUnssen',
      payloadGetNotifications
    );

    if (notificationsResponse.data && notificationsResponse.data.data) {
      return notificationsResponse.data.data.responseObj;
    } else {
      return null;
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

const postLogin = async (payloadLogin) => {
  try {
    const loginResponse = await axios.post('/api/v2/customers/PostLogin', payloadLogin);
    if (loginResponse.data && loginResponse.data.data) {
      return loginResponse.data.data.responseObj;
    } else {
      return loginResponse.data.error;
    }
  } catch (err) {
    return null;
  }
};

function* getMessages(userInfo) {
  let initNotifications = [];
  let initCountNotifications = 0;

  let userInfoLocal = userInfo;

  if (!userInfoLocal?.customerId) {
    userInfoLocal = clone(yield select((state) => state.auth.userInfo));
  }

  if (!userInfoLocal) {
    return {
      initNotifications,
      initCountNotifications,
    };
  }

  try {
    const payload = { customerId: userInfoLocal.customerId };
    const response = yield axios.post('/api/v2/apiNotification/GetNotificationsUnssen', payload);

    if (response.data?.data?.responseObj) {
      const { notifications, count } = response.data.data.responseObj;
      initCountNotifications = count;
      initNotifications = notifications;
    } else {
      initCountNotifications = 0;
      initNotifications = [];
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
  }

  return {
    initNotifications,
    initCountNotifications,
  };
}

function* CustomerRefreshToken(idCustomer) {
  try {
    const body = { customerId: idCustomer };
    const customerResponse = yield axios.post('/api/v2/customers/refreshToken', body);
    if (customerResponse.data && customerResponse.data.data !== null && customerResponse.data.data.responseObj) {
      return customerResponse.data.data.responseObj;
    } else {
      return null;
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

function* getCustomerById(idCustomer) {
  try {
    const payloadGetCustomer = { customerId: idCustomer };
    const customerResponse = yield axios.post('/api/v2/customers/GetCustomerById', payloadGetCustomer);
    if (customerResponse.data && customerResponse.data.data) {
      return customerResponse.data.data;
    } else {
      return null;
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest());
    }
    return null;
  }
}

function clone(obj) {
  var copy;

  if (null == obj || 'object' != typeof obj) return obj;

  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

function* updateBalance() {
  try {
    const userInfo = clone(yield select((state) => state.auth.userInfo));
    if (userInfo.customerId) {
      const infoBalance = yield call(getBalance, userInfo);
      if (infoBalance !== null) {
        userInfo.total = infoBalance.balance;
        userInfo.balances = infoBalance.balances;
        
        yield put(updatedUser(userInfo));
        yield put(putAmountBalance(infoBalance.balances, infoBalance.balance));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* UpdatedBadgeMessage() {
  const userInfo = yield select((state) => state.auth.userInfo);
  yield call(updateBadge, userInfo);
}

type ParamsUpdateVerificationUser = { idCustomer: number; type: string };

function* updateVerificationUser({ idCustomer }: ParamsUpdateVerificationUser) {
  const userInfo = yield select((state) => state.auth.userInfo);
  const currentUser = yield call(getCustomerById, idCustomer);

  if (currentUser !== null) {
    const newUser = {
      ...currentUser,
      balances: userInfo.balances,
      total: userInfo.total,
    };

    yield call(updateBadge, newUser);
    yield put(updatedUser(newUser));
  }
}

function* updateBadge(userInfo: ICustomer) {
  const customer = yield select((state) => state.auth.userInfo);

  if (customer) {
    let badge = 0;

    if (
      moment(customer.birthDate).format('YYYY-MM-DD') === '0001-01-01' ||
      moment(customer.birthDate).format('YYYY-MM-DD') === '1900-01-01'
    )
      badge += 1;

    if (!customer.userValidate) badge += 1;
    if (!customer.emailActive) badge += 1;
    if (!customer.phoneActive) badge += 1;
    if (badge > 0) yield put(ChangeBadgePersonalData(badge));

    const { initNotifications, initCountNotifications } = yield call(getMessages, customer);

    if (initCountNotifications > 0) yield put(ChangeFirstNotification(initNotifications[0]));
    else yield put(ChangeFirstNotification(null));

    yield put(ChangeBadgeUnreadMessage(initCountNotifications));

    let badgeVerificationFinal = customer.userValidate ? 0 : 1;
    yield put(ChangeBadgeVerifyAccount(badgeVerificationFinal));

    let general = initCountNotifications;
    if (badge > 0) {
      general += badge + badgeVerificationFinal;
    } else {
      general += badgeVerificationFinal;
    }
    yield put(ChangeBadgeGeneral(general));
  }
  return true;
}

function* logoutApp() {
  const countryDetected = yield select((state) => state.fp.countryDetected.toLowerCase());
  try {
    yield axios.post('/api/v2/customers/PostLogout');
    yield put(resetDateLB());
    yield put(logoutUser());
    yield put(clearBalance());
    yield put(resetModal());
    yield put(initialStateBonus());
    yield put(ClearNotification());
    yield put(changeViewModalBonusRegister(false));
    yield put(clearPromotions());
    yield put(resetPendingActions());
    yield put(getStrapiLeaderboardPrincipalRequest({ country: countryDetected.toString() }));
  } catch (e) {
    console.log(e);
    yield put(resetDateLB());
    yield put(logoutUser());
    yield put(resetModal());
    yield put(clearBalance());
    yield put(ClearNotification());
  }
}

function* logoutForCookie() {
  yield put(logoutUser());
  yield put(clearBalance());
  yield put(initialStateBonus());
  yield put(ClearNotification());
  yield put(changeViewModalBonusRegister(false));
}

function* updateRefreshToken() {
  const userInfo = clone(yield select((state) => state.auth.userInfo));

  if (userInfo.customerId) {
    const refreshToken = yield call(CustomerRefreshToken, userInfo.customerId);
    if (refreshToken !== null) {
      if (refreshToken.refreshToken !== userInfo.refreshToken) {
        userInfo.refreshToken = refreshToken.refreshToken;
        yield put(updatedUser(userInfo));
      }
    }
  }
}

function* getFingerPrintPro() {
  try {
    if (typeof window !== 'undefined') {
      const fp = yield window.fpPromise;
      const result = yield fp.get({ extendedResult: true });
      const { data: fullFinger } = yield axios.post('/api/v2/fp/FullData', { result });
      return fullFinger.data.responseObj;
    }
  } catch (e) {
    console.log(e);
  }
}

function* refreshFingerPrint() {
  try {
    const fingerInfo = yield call(getFingerPrintPro);
    if (fingerInfo) {
      yield put(updatedHashFinger(fingerInfo.visitorId));
      yield axios.post('/api/v2/customers/PostInvite', { hashFinger: fingerInfo.visitorId });
    }
  } catch (e) {
    console.log(e);
  }
}

function* handleLogin({ payloadLogin }: ParamsHandleLogin) {
  try {
    const { currencies, languages } = yield select((state) => ({
      currencies: state.layout.currencies,
      languages: state.layout.languages,
    }));
    const languageSelected = yield select((state) => state.SelectedLanguage.languageSelected);
    const fingerInfo = yield call(getFingerPrintPro);

    if (fingerInfo) {
      yield put(updatedHashFinger(fingerInfo.visitorId));
      yield call(axios.post, '/api/v2/customers/PostInvite', { hashFinger: fingerInfo.visitorId });
    }

    const infoLogin = yield call(postLogin, payloadLogin);

    if (!infoLogin || infoLogin.error) {
      yield put(loginFailed(getLoginMessage(infoLogin?.details ? infoLogin.details : 'unexpected')));
      return; // Termina la ejecución si hay un error
    }

    const infoSelfExclusion = yield call(postVerifySelfExclusion);
    yield put(loginUser(infoLogin));

    const countryDetected = yield select((state) => state.fp.countryDetected.toLowerCase());
    yield put(getStrapiLeaderboardPrincipalRequest({ country: countryDetected?.toString() }));

    if (infoSelfExclusion && infoSelfExclusion?.isExcluded) {
      yield put(loginFailed(getLoginMessage({
        message: `Respetamos tu decisión porque nos preocupamos por ti y estamos disponibles si necesitas asistencia.
            Estás excluido hasta el ${moment(infoSelfExclusion.until).format('DD/MM/YYYY HH:mm')}.`,
        code: 610,
      })));
    } else {
      yield put(loginUser(infoLogin));

      yield put(requestRejectSelfExclusionStatus());
      if (process.env.NODE_ENV === 'production') {
        gtag.event('login', { method: 'normal', client_id: infoLogin.customerId });
      }
      srtmCommands('track.user.login', { action: 'complete', userId: infoLogin.customerId });

      const countryDetectionMap = COUNTRIES_LIST.find((item)=>{ return item.currency.code == infoLogin.currency });
      yield put(updatedCountryDetected(countryDetectionMap?.code?.toLowerCase() ?? 'cl'));
      yield put(updatedDocumentType(countryDetectionMap?.documentType));
      Cookies.set('country', (countryDetectionMap?.code || DEFAULT_COUNTRY), { expires: 365 });
      const currencyMap = {
        pen: {
          code: 'PEN',
          currency: 'Sol Peruano',
          text: 'Sol Peruano',
          number: 0,
          decimal: 0,
          symbol: 'S/.'
        },
        clp: {
          code: 'CLP',
          currency: 'Peso Chileno',
          text: 'Peso Chileno',
          number: 0,
          decimal: 0,
          symbol: '$'
        },
        ars: {
          code: 'ARS',
          currency: 'Peso Argentino',
          text: 'Peso Argentino',
          number: 0,
          decimal: 0,
          symbol: '$'
        },
        pyg: {
          code: 'PYG',
          currency: 'Guaraní',
          text: 'Guaraní',
          number: 0,
          decimal: 0,
          symbol: '₲'
        },
        gtq: {
          code: 'GTQ',
          currency: 'Quetzal',
          text: 'Quetzal',
          number: 0,
          decimal: 0,
          symbol: 'Q'
        }
      };
      const currency = currencyMap[infoLogin.currency.toLowerCase()];
      yield put(ChangeCurrency(currency));

      const esLanguage = languages.find(lang => lang.code.toLowerCase() === 'es');
      if (esLanguage && languageSelected?.code?.toLowerCase() !== esLanguage.code.toLowerCase()) {
        yield put(ChangeSelected(esLanguage));
      }

      const payload = { customerId: infoLogin.customerId };
      const effects = [
        put(haveDeposit()),
        put(getNotificationsRequest(payload)),
        put(updateBalanceRequest(infoLogin.customerId)),
        put(getFavoriteGamesRequest(payload)),
        put(getBadgeRequest(infoLogin)),
        put(loginSuccess()),
        put(requestSessionReminderStatus()),
        put(getFullFP()),
      ];

      // Agregar BalanceTruePlayRequest solo si NAME_COMMERCE es Juegalo.com
      if (process.env.NEXT_PUBLIC_NAME_COMMERCE === 'Juegalo.com') {
        effects.push(put(BalanceTruePlayRequest(infoLogin.customerId)));
      }

      yield all(effects);
    }
  } catch (e) {
    console.error(e);
  }
}

function* handleNotifications({ payload }: ParamsNotifications) {
  yield call(updatedStateNotification, payload);
  yield put(readMessage(payload.customerId));
  yield put(ClearNotification());
}

function* verificationCookie() {
  try {
    const hashFinger = yield select((state) => state.fp.hashFinger);

    if (hashFinger !== 'WEB') {
      yield axios.post('/api/v2/customers/PostInvite', { hashFinger });
    } else {
      yield put(getFullFP());
    }
  } catch (e) {
    console.log(e);
  }
}

export default all([
  takeLatest('@auth/UPDATE_BALANCE_REQUEST', updateBalance),
  takeLatest('@auth/GET_FAVORITE_GAME_REQUEST', getFavoriteGame),
  takeLatest('@auth/READ_MESSAGE', UpdatedBadgeMessage),
  takeLatest('@auth/UPDATE_VERIFICATION_REQUEST', updateVerificationUser),
  takeLatest('@auth/UPDATE_NOTIFICATIONS_REQUEST', handleNotifications),
  takeLatest('@auth/LOGOUT_REQUEST', logoutApp),
  takeLatest('@auth/LOGOUT_FOR_COOKIE', logoutForCookie),
  takeLatest('@auth/UPDATE_REFRESHTOKEN_REQUEST', updateRefreshToken),
  takeLatest('@auth/LOGINREQUEST', handleLogin),
  takeLatest('@auth/REFRESH_FINGERPRINT', refreshFingerPrint),
  takeLatest('@auth/NOTIFICATIONS_REQUEST', getNotifications),
  takeLatest('@auth/BADGE_REQUEST', updateBadge),
  takeLatest('@auth/VERIFY_COOKIE', verificationCookie),
]);
