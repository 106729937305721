import { BonusRegisterActionTypes } from "../constants/bonusRegister"

const RegisterBonusInitialState = {
  userViewModal: false
}

const reducer = (state = RegisterBonusInitialState, action) => {
  switch (action.type) {
    case BonusRegisterActionTypes.UPDATED_VIEW_MODAL:
      return {
        userViewModal: action.payload,
      }
    case 'RESET_STORE':
      return RegisterBonusInitialState
    default:
      return state
  }
}

export default reducer
