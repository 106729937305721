import {
  GET_COUNTDOWN_REJECTED,
  GET_COUNTDOWN_REQUEST,
  GET_COUNTDOWN_SUCCESS
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiCountdownSuccess(payload) {
  return { type: GET_COUNTDOWN_SUCCESS, payload }
}
export function getStrapiCountdownReject() {
  return { type: GET_COUNTDOWN_REJECTED }
}
export function getStrapiCountdownRequest(payload) {
  return { type: GET_COUNTDOWN_REQUEST, payload }
}