import { put } from 'redux-saga/effects';
import { getStrapiCountdownReject, getStrapiCountdownSuccess } from '@clean/application/redux/strapi/actions';
import StrapiUseCase from '@clean/domain/useCase/strapi/strapiUseCase';
import StrapiRepositoryImpl from '@clean/infrastructure/repositories/StrapiRepositoryImpl';
const StrapiRepo = new StrapiRepositoryImpl();

export function* getStrapiCountdown(action) {
  const strapiService = new StrapiUseCase(StrapiRepo);
  const { data: strapiResult } = yield strapiService.getStrapiCountdown(
    action.payload);
  if (!strapiResult?.responseObj || strapiResult?.responseCode != 200) {
    return yield put(getStrapiCountdownReject());
  }
  if (strapiResult.responseObj) {
    const payload = {
      data: strapiResult.responseObj
    }
    yield put(getStrapiCountdownSuccess(payload));
  } else {
    yield put(getStrapiCountdownReject());
  }
}

