export const getLoginMessage = (details): any => {
  const statusCode = details?.statusCode ?? details?.code;
  switch (statusCode) {
    case 401:
      switch (details.message) {
        case 'disabled':
          return {
            title: 'Su cuenta ha sido temporalmente deshabilitada.',
            message: 'Por favor, comuníquese con Soporte para más detalles',
            textConfirmButton: 'Quiero más detalles',
            action: 'openChatSupport'
          }
        case 'unexpected':
          return {
            title: 'Un error inesperado acaba de ocurrir.',
            message: 'Por favor, comuníquese con Soporte para más detalles',
            textConfirmButton: 'Quiero más detalles',
            action: 'openChatSupport'
          }
        default:
          return {
            title: 'error',
            message: 'Credenciales invalidas.',
            textConfirmButton: 'Aceptar',
            action: ''
          }
      }
    case 301:
      return {
        title: 'Un error inesperado acaba de ocurrir.',
        message: 'Nos encontramos imposibilitados para procesar tu solicitud en este preciso instante. Te sugerimos que entres en contacto con nuestro equipo de soporte.',
        textConfirmButton: 'Aceptar',
        action: ''
      }
    case 610:
      return {
        title: 'Tu bienestar es primordial para nosotros.',
        message: details.message,
        textConfirmButton: '¿Necesitas ayuda?',
        action: 'openChatSupport',
        confirmButtonColor: 'var(--orange)',
        iconHtml: "<img alt='icono de visibilidad' src='/assets/heart_icon.svg' style='heigh: 100%; width: 100%;' />",
      }
    default:
      return 'Error interno, contáctese por soporte'
  }
}
