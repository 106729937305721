export interface IProperties {
  email: string,
  phone?: string,
  FIRSTNAME: string,
  LASTNAME: string,
  customerId: number
}

export interface ITrackEventDeposit {
  id: string, //uuid
  data: {
      total: number,
      currency: string,
      method: string,
      createdDate: Date
  }
}

export interface ITrackEventRegister {
  data: {
    country: string,
    createdDate: Date
  }
}

export const SendiBlueEvent = (eventName: string,properties: IProperties, track_event) => {
  window.sendinblue.track(eventName, properties, track_event);
}
