export const GETPROMOTIONS_CUSTOMER_REQUEST = '@promotions/GETPROMOTIONS_CUSTOMER_REQUEST';
export const GETPROMOTIONS_CUSTOMER_SUCCESS = '@promotions/GETPROMOTIONS_CUSTOMER_SUCCESS';
export const GETPROMOTIONS_CUSTOMER_REJECTED = '@promotions/GETPROMOTIONS_CUSTOMER_REJECTED';

export const VIEWPROMOTIONS_CUSTOMER_REQUEST = '@promotions/VIEWPROMOTIONS_CUSTOMER_REQUEST';
export const VIEWPROMOTIONS_CUSTOMER_SUCCESS = '@promotions/VIEWPROMOTIONS_CUSTOMER_SUCCESS';
export const VIEWPROMOTIONS_CUSTOMER_REJECTED = '@promotions/VIEWPROMOTIONS_CUSTOMER_REJECTED';

export const CHANGE_ACTIVE_PROMOTIONS = 'CHANGE_ACTIVE_PROMOTIONS';

export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS';

