import { CurrencyActionTypes } from "../constants/currency"

const CurrencyInitialState = {
  currencyLocal: {
    "code": "CLP",
    "currency": "Peso Chileno",
    "text": "Peso Chileno",
    "number": 0,
    "decimal": 0,
    "symbol": "$"
  }
}

const reducer = (state = CurrencyInitialState, action) => {
  switch (action.type) {
    case CurrencyActionTypes.CHANGE_CURRENCY:
      return {
        currencyLocal: action.payload,
      }
    default:
      return state
  }
}

export default reducer
