export const modalsActionTypes = {
  OPEN_MODAL_FIRST_DEPOSIT: '@modals/OPEN_MODAL_FIRST_DEPOSIT',
  CLOSE_MODAL_FIRST_DEPOSIT: '@modals/CLOSE_MODAL_FIRST_DEPOSIT',
  OPEN_MODAL_BONUS_REGISTER: '@modals/OPEN_MODAL_BONUS_REGISTER',
  CLOSE_MODAL_BONUS_REGISTER: '@modals/CLOSE_MODAL_BONUS_REGISTER',
  OPEN_MODAL_PROMOTIONS: '@modals/OPEN_MODAL_PROMOTIONS',
  CLOSE_MODAL_PROMOTIONS: '@modals/CLOSE_MODAL_PROMOTIONS',
}

export function openModalFirstDeposit() {
  return { type: modalsActionTypes.OPEN_MODAL_FIRST_DEPOSIT }
}

export function closeModalFirstDeposit() {
  return { type: modalsActionTypes.CLOSE_MODAL_FIRST_DEPOSIT }
}

export function openModalPromotions() {
  return { type: modalsActionTypes.OPEN_MODAL_PROMOTIONS }
}

export function closeModalPromotions() {
  return { type: modalsActionTypes.CLOSE_MODAL_PROMOTIONS }
}

export function openModalBonusRegister() {
  return { type: modalsActionTypes.OPEN_MODAL_BONUS_REGISTER }
}

export function closeModalBonusRegister() {
  return { type: modalsActionTypes.CLOSE_MODAL_BONUS_REGISTER }
}
