import { referenceCodeActionTypes } from '../actions/ReferenceCode'

const ReferenceCodeInitialState = {
  CodeReference: null,
}

const reducer = (state = ReferenceCodeInitialState, action) => {
  switch (action.type) {
    case referenceCodeActionTypes.REFERENCECODE:
      return {
        CodeReference: action.payload,
      }
    default:
      return state
  }
}

export default reducer
