import axios from 'axios';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import * as Sentry from "@sentry/nextjs";
import { depositMethodSuccess, depositFailed } from '@clean/application/redux/deposit/actions/deposit';
import { setHaveDeposit } from '@clean/application/redux/auth/actions/auth';
import DepositUseCase from '@clean/domain/useCase/depositUseCase';
import DepositRepositoryImpl from '@clean/infrastructure/repositories/depositRepositoryImpl';

const depositRepo = new DepositRepositoryImpl();
const depositService = new DepositUseCase(depositRepo);


function* getMethodDeposit (payload)  {
  try{
    let payloadOneOrder = {
      transactionId: payload
    }
    const { data:response } = yield axios.post(`/api/v2/deposits/GetDepositConfirm`, payloadOneOrder)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return response.error
    }
  }catch(err){
    Sentry.captureException(err);
    return []
  }
}

function* handleGetOneOrder({payload}) {
  let MethodDeposit = yield call(getMethodDeposit,payload)
  if (MethodDeposit.error !== undefined) {
    if(MethodDeposit.code === 400){
      yield put(depositFailed({ message: 'Deposito Invalido'}))
    }
    if(MethodDeposit.code === 404){
      yield put(depositFailed({ message: 'Error interno, contáctese por soporte'}))
    }
    if(MethodDeposit.code === 500){
      yield put(depositFailed({ message: 'Error interno, contáctese por soporte'}))
    }
  } else {
    if(MethodDeposit){
      yield put(depositMethodSuccess(MethodDeposit))
    }
  }
}

function* verifyHaveDeposit(){
  let have;
  const resultHaveDeposit = yield depositService.HaveDeposit();
  resultHaveDeposit.fold(
    err => {
      have = null;
    },
    haveDeposit => {
      have = haveDeposit;
    }
  )
  yield put(setHaveDeposit(have));
}


export default all([
  takeLatest('@deposit/VERIFY_HAVE_DEPOSIT', verifyHaveDeposit),
]);
