import { selfExclusionActionTypes } from '../actions/selfExclusion'
import produce from 'immer';

const SelfExclusionInitialState = {
  isExcluded: false,
  until: '',
  loading: false,
  exclusionPeriod: null,
  viewModalConfirm: false,
  viewModalHome: false,
}

const reducer = (state = SelfExclusionInitialState, action) => {
  switch (action.type) {
    case selfExclusionActionTypes.REQUEST_SELF_EXCLUSION:
      return produce(state, draft => {
        draft.loading = true
      });
    case selfExclusionActionTypes.REQUEST_SUCCES_REQUEST_SELF_EXCLUSION:
      return produce(state, draft => {
        draft.loading = false;
        draft.exclusionPeriod = action.payload?.exclusionPeriod;
      });
    case selfExclusionActionTypes.REQUEST_REJECT_REQUEST_SELF_EXCLUSION:
      return produce(state, draft => {
        draft.loading = false
      });
    case selfExclusionActionTypes.REQUEST_SELF_EXCLUSION_STATUS:
      return produce(state, draft => {
        draft.isExcluded = false
        draft.loading = true
      });
    case selfExclusionActionTypes.REQUEST_SUCCES_REQUEST_SELF_EXCLUSION_STATUS:
      return produce(state, draft => {
        draft.loading = false;
        draft.isExcluded = action.payload?.isExcluded ?? false;
        draft.until = action.payload?.until;
      });
    case selfExclusionActionTypes.REQUEST_REJECT_REQUEST_SELF_EXCLUSION_STATUS:
      return produce(state, draft => {
        draft.loading = false
        draft.isExcluded = false
      });
    default:
      return state
  }
}

export default reducer;
