export const COUNTRIES_LIST = [
  {
    code: 'CL',
    defaultCity: 'Santiago',
    currency: {
      code: 'CLP',
      currency: 'Peso Chileno',
      text: 'Peso Chileno',
      number: 0,
      decimal: 0,
      symbol: '$'
    },
    documentType: 'Cédula'
  },
  {
    code: 'PE',
    defaultCity: 'Lima',
    currency: {
      code: 'PEN',
      currency: 'Sol Peruano',
      text: 'Sol Peruano',
      number: 0,
      decimal: 0,
      symbol: 'S/.'
    },
    documentType: 'DNI'
  },
  {
    code: 'AR',
    defaultCity: 'Buenos Aires',
    currency: {
      code: 'ARS',
      currency: 'Peso Argentino',
      text: 'Peso Argentino',
      number: 0,
      decimal: 0,
      symbol: '$'
    },
    documentType: 'DNI'
  },
  {
    code: 'PY',
    defaultCity: 'Asunción',
    currency: {
      code: 'PYG',
      currency: 'Guaraní',
      text: 'Guaraní',
      number: 0,
      decimal: 0,
      symbol: '₲'
    },
    documentType: 'Cédula'
  },
  {
    code: 'GT',
    defaultCity: 'Ciudad de Guatemala',
    currency: {
      code: 'GTQ',
      currency: 'Quetzal',
      text: 'Quetzal',
      number: 0,
      decimal: 0,
      symbol: 'Q'
    },
    documentType: 'DPI'
  }
];

export const DEFAULT_COUNTRY = 'cl';