import produce from 'immer';
import {
  GET_HOME_LEADERBOARD_REQUEST,
  GET_HOME_LEADERBOARD_SUCCESS,
  GET_HOME_LEADERBOARD_REJECTED,
  GET_LEADERBOARD_REQUEST,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_REJECTED,
  GET_PROMOTIONS_LEADERBOARD_REQUEST,
  GET_PROMOTIONS_LEADERBOARD_SUCCESS,
  GET_PROMOTIONS_LEADERBOARD_REJECTED,
  GET_MENU_LEADERBOARD_REQUEST,
  GET_MENU_LEADERBOARD_SUCCESS,
  GET_MENU_LEADERBOARD_REJECTED,
  GET_COUNTDOWN_REQUEST,
  GET_COUNTDOWN_SUCCESS,
  GET_COUNTDOWN_REJECTED,
  GET_JACKPOT_LADING_BY_ID_REQUEST,
  GET_JACKPOT_LADING_BY_ID_SUCCESS,
  GET_JACKPOT_LADING_BY_ID_REJECTED,
  GET_MENU_NAV_BAR_REQUEST,
  GET_MENU_NAV_BAR_SUCCESS,
  GET_MENU_NAV_BAR_REJECTED

} from '@clean/application/redux/strapi/constants/actionsTypes';

const INIT_STATE = {
  loadingStrapiLbHome: false,
  loadingStrapiLbPromo: false,
  loadingStrapiLb: false,
  loadingStrapiLbMenu: false,
  loadingStrapiCountdown: false,
  loadingStrapiJackpotLanding: false,
  loadingStrapiMenuNavBarLanding: false,
  strapiJackpotLanding: null,
  strapiLeaderbord: null,
  strapiLeaderbordHome: null,
  strapiLeaderbordPromo: null,
  strapiLeaderbordMenu: null,
  strapiCountdown: null,
  strapiMenuNavBar: null,
  lastUpdatedTimeLbHome: null,
  lastUpdatedTimeLbPromo: null,
  lastUpdatedTimeLbMenu: null,
  lastUpdatedTimeLb: null,
  lastUpdatedTimeCountdown: null,
  lastUpdatedTimeJackpotLanding: null,
  lastUpdatedTimeMenuNavBar: null
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOME_LEADERBOARD_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiLbHome = true;
      });
    case GET_HOME_LEADERBOARD_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiLbHome = false;
        draft.strapiLeaderbordHome = action?.payload?.data;
        draft.lastUpdatedTimeLbHome = new Date();
      });
    case GET_HOME_LEADERBOARD_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiLbHome = false;
        draft.strapiLeaderbordHome = null;
        draft.lastUpdatedTimeLbHome = new Date();
      });
    case GET_JACKPOT_LADING_BY_ID_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiJackpotLanding = true;
      });
    case GET_JACKPOT_LADING_BY_ID_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiJackpotLanding = false;
        draft.strapiJackpotLanding = action?.payload?.data;
        draft.lastUpdatedTimeJackpotLanding = new Date()
      });
    case GET_JACKPOT_LADING_BY_ID_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiJackpotLanding = false;
        draft.strapiJackpotLanding = [];
        draft.lastUpdatedTimeJackpotLanding = new Date();
      });
    case GET_LEADERBOARD_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiLb = true;
      });
    case GET_LEADERBOARD_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiLb = false;
        draft.strapiLeaderbord = action?.payload?.data;
        draft.loadingStrapiLbHome = false;
        draft.lastUpdatedTimeLb = new Date()
      });
    case GET_LEADERBOARD_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiLb = false;
        draft.strapiLeaderbord = [];
        draft.lastUpdatedTimeLb = new Date();
      });
    case GET_PROMOTIONS_LEADERBOARD_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiLbPromo = true;
      });
    case GET_PROMOTIONS_LEADERBOARD_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiLbPromo = false;
        draft.strapiLeaderbordPromo = action?.payload?.data;
        draft.lastUpdatedTimeLbPromo = new Date();
      });
    case GET_PROMOTIONS_LEADERBOARD_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiLbPromo = false;
        draft.strapiLeaderbord = null;
        draft.lastUpdatedTimeLbPromo = new Date();
      });
    case GET_MENU_LEADERBOARD_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiLbMenu = true;
      });
    case GET_MENU_LEADERBOARD_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiLbMenu = false;
        draft.strapiLeaderbordMenu = action?.payload?.data;
        draft.loadingStrapiLbHome = false;
        draft.lastUpdatedTimeLbMenu = new Date();
      });
    case GET_MENU_LEADERBOARD_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiLbMenu = false;
        draft.strapiLeaderbordMenu = null;
        draft.lastUpdatedTimeLbMenu = new Date();
      });
    case GET_COUNTDOWN_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiCountdown = true;
      });
    case GET_COUNTDOWN_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiCountdown = false;
        draft.strapiCountdown = (action?.payload?.data != null && Object.keys(action.payload.data).length === 0)
          ? null : action.payload.data ?? null;
        draft.lastUpdatedTimeCountdown = new Date();
      });
    case GET_COUNTDOWN_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiCountdown = false;
        draft.strapiCountdown = null;
        draft.lastUpdatedTimeCountdown = new Date();
      });
    case GET_MENU_NAV_BAR_REQUEST:
      return produce(state, (draft) => {
        draft.loadingStrapiMenuNavBarLanding = true;
      });
    case GET_MENU_NAV_BAR_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingStrapiMenuNavBarLanding = false;
        draft.strapiMenuNavBar = action?.payload?.data;
        draft.lastUpdatedTimeMenuNavBar = new Date();
      });
    case GET_MENU_NAV_BAR_REJECTED:
      return produce(state, (draft) => {
        draft.loadingStrapiMenuNavBarLanding = false;
        draft.strapiCountdown = null;
        draft.lastUpdatedTimeMenuNavBar = new Date();
      });
    default:
      return state
  }
};

export default reducer;
