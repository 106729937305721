import { LayoutActionTypes } from "../constants/layout"


export function UpdatedDataLayoutRequest() {
  return { type: LayoutActionTypes.UPDATED_INFO_LAYOUT_REQUEST }
}

export function UpdatedDataLayout(payload) {
  return { type: LayoutActionTypes.UPDATED_INFO_LAYOUT, payload }
}

export function layoutReady(){
  return { type: LayoutActionTypes.LAYOUT_READY }
}

export function layoutLoad(){
  return { type: LayoutActionTypes.LAYOUT_LOAD }
}

export function UpdatedCountryAvailable(payload) {
  return { type: LayoutActionTypes.UPDATED_COUNTRYAVAILABLE, payload }
}

export function changeCurrentPath(payload) {
  return { type: LayoutActionTypes.CHANGE_CURRENTPATH, payload }
}

