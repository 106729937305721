export const SecurityActionTypes = {
  VPNVALIDATION_REQUEST: '@security/VPNVALIDATION_REQUEST',
  VPNVALIDATION_SUCCESS: '@security/VPNVALIDATION_FULLFILLED',
  VPNVALIDATION_REJECTED: '@security/VPNVALIDATION_REJECTED',
}

export function getVpnValidationRequest () {
  return { type: SecurityActionTypes.VPNVALIDATION_REQUEST }
}

export function getVpnValidationSuccess (payload) {
  return { type: SecurityActionTypes.VPNVALIDATION_SUCCESS, payload }
}
export function getVpnValidationRejected () {
  return { type: SecurityActionTypes.VPNVALIDATION_REJECTED }
}
