import { balanceActionTypes } from "../constants/balance"

export function putBalanace (state) {
    return { type: balanceActionTypes.PUTSHOWBALANCE, payload: state }
}

export function putAmountBalanceRequest () {
  return { type: balanceActionTypes.PUTAMOUNTBALANCEREQUEST }
}

export function putAmountBalance (balances,balance) {
  return { type: balanceActionTypes.PUTAMOUNTBALANCE, payload: { balances, balance } }
}

export function clearBalance () {
  return { type: balanceActionTypes.CLEARBALANCE }
}
