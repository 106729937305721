export const selfExclusionActionTypes = {
  REQUEST_SELF_EXCLUSION: '@selfExclusion/REQUEST_SELF_EXCLUSION',
  REQUEST_SUCCES_REQUEST_SELF_EXCLUSION: '@selfExclusion/REQUEST_SUCCES_REQUEST_SELF_EXCLUSION',
  REQUEST_REJECT_REQUEST_SELF_EXCLUSION: '@selfExclusion/REQUEST_REJECT_RANKING',
  REQUEST_SELF_EXCLUSION_STATUS: '@selfExclusion/REQUEST_SELF_EXCLUSION_STATUS',
  REQUEST_SUCCES_REQUEST_SELF_EXCLUSION_STATUS: '@selfExclusion/REQUEST_SUCCES_REQUEST_SELF_EXCLUSION_STATUS',
  REQUEST_REJECT_REQUEST_SELF_EXCLUSION_STATUS: '@selfExclusion/REQUEST_REJECT_REQUEST_SELF_EXCLUSION_STATUS',
}

export function requestSelfExclusion(payload) {
  return { type: selfExclusionActionTypes.REQUEST_SELF_EXCLUSION, payload }
}
export function requestRejectSelfExclusion() {
  return { type: selfExclusionActionTypes.REQUEST_REJECT_REQUEST_SELF_EXCLUSION }
}
export function requestSuccessSelfExclusion(payload) {
  return { type: selfExclusionActionTypes.REQUEST_SUCCES_REQUEST_SELF_EXCLUSION, payload }
}

export function requestSelfExclusionStatus() {
  return { type: selfExclusionActionTypes.REQUEST_SELF_EXCLUSION_STATUS}
}
export function requestRejectSelfExclusionStatus() {
  return { type: selfExclusionActionTypes.REQUEST_REJECT_REQUEST_SELF_EXCLUSION_STATUS }
}
export function requestSuccessSelfExclusionStatus(payload) {
  return { type: selfExclusionActionTypes.REQUEST_SUCCES_REQUEST_SELF_EXCLUSION_STATUS, payload }
}