import { SelectedLanguageActionTypes } from "../constants/SelectedLanguage"

const SelectedLanguageInitialState = {
  languageSelected: {
    code: "ES",
    icon: "https://storage.googleapis.com/bucket.juegalo.com/assets/icons/flags/es.svg",
    language: "Español"
  },
}

const reducer = (state = SelectedLanguageInitialState, action) => {
  switch (action.type) {
    case SelectedLanguageActionTypes.CHANGE_LANGUAGE:
      return {
        languageSelected: action.payload,
      }
    default:
      return state
  }
}

export default reducer
