import { put, all, takeLatest } from 'redux-saga/effects';
import { LeaderboardActionTypes } from '@clean/application/redux/leaderboard/constant';
import LeaderboardUseCase from '@clean/domain/useCase/leaderboard/leaderboardUseCase';
import LeaderboardRepositoryImpl from '@clean/infrastructure/repositories/LeaderboardRepositoryImpl';
import { getStatusPlayerSuccess, getStatusPlayerRejected } from '@clean/application/redux/leaderboard/actions/getStatusPlayer'
const LeaderboardRepo = new LeaderboardRepositoryImpl();
const LeaderboardService = new LeaderboardUseCase(LeaderboardRepo);

function* GetStatusPlayer (params)  {
  try{
    const status = yield LeaderboardService.GetStatusPlayer(params.payload);
    yield put(getStatusPlayerSuccess(status.isParticipatingLeaderboard))
  }catch(err){
    yield put(getStatusPlayerRejected()) 
  }
}

export default all([
  takeLatest(LeaderboardActionTypes.REQUEST_GET_STATUS_PLAYER, GetStatusPlayer)
]);
