import { all, select, takeLatest, call, cancelled, put } from 'redux-saga/effects'
import axios from 'axios'
import { bonocashbackSuccess, bonoCashbackHistorySuccess } from '@clean/application/redux/bonus/actions/bonus'
import { forceLogoutRequest } from '@clean/application/redux/auth/actions/auth'
import * as Sentry from "@sentry/nextjs";
import { bonusActionsTypes } from '../constants/bonus';

function* getHistoryBonoCashabckWithFilter(payload) {
  const cancelSource = axios.CancelToken.source()
  try {
    let PayloadGetBonus = {
      customerId: payload.userInfo.customerId,
      limit: payload.Limit,
      offset: payload.Offset,
    }
    const bonusResponse = yield axios.post(`/api/v2/cashback/cashbackHistory`, PayloadGetBonus, {
      cancelToken: cancelSource.token,
    })
    if (bonusResponse.data && bonusResponse.data.data) {
      return bonusResponse.data.data
    } else {
      return []
    }
  } catch (err) {
    Sentry.captureException(err);
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest())
    }
    return []
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel)
    }
  }
}

function clone(obj) {
  var copy

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = []
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i])
    }
    return copy
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {}
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr])
    }
    return copy
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}

function* getDetailsBonoCashabck(userInfo) {
  try {
    const payloadGetBonus = {
      customerId: userInfo.customerId,
    }
    let bonusResponse = yield axios.post(`/api/v2/cashback/newCashbackWeek`, payloadGetBonus);
    if (bonusResponse.data && bonusResponse.data.data) {
      return bonusResponse.data.data
    } else {
      return []
    }
  } catch (err) {
    Sentry.captureException(err);
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest())
    }
    return null
  }
}

function* handleBonusCashback() {
  //order for search and isMobile
  const userInfo = clone(yield select((state) => state.auth.userInfo))
  const DetailsBonus = yield call(getDetailsBonoCashabck, userInfo)

  const payloadBono = {
    detail: DetailsBonus,
  }

  yield put(bonocashbackSuccess(payloadBono))
}

function* handleBonusCashbackHistory({ payload }) {
  let userInfo = clone(yield select((state) => state.auth.userInfo))

  const HistoryBonus = yield call(getHistoryBonoCashabckWithFilter, { ...payload, userInfo })

  yield put(bonoCashbackHistorySuccess(HistoryBonus))
}

export default all([
  takeLatest(bonusActionsTypes.BONUS_CASHBACK_REQUEST, handleBonusCashback),
  takeLatest(bonusActionsTypes.BONOCASHBACKHISTORY_REQUEST, handleBonusCashbackHistory),
])
