// actions/colorActions.js
export const REQUEST_COLORS = 'REQUEST_COLORS';
export const SUCESS_REQUEST_COLOR = 'SUCESS_REQUEST_COLOR';
export const FAILED_REQUEST_COLOR = 'FAILED_REQUEST_COLOR';
export const SET_LAST_FETCH = 'SET_LAST_FETCH';

export const requestColors = () => ({
    type: REQUEST_COLORS,
})

export const successRequestColors = (colors) => ({
  type: SUCESS_REQUEST_COLOR,
  payload: colors,
});

export const failedRequestColors = () => ({
    type: FAILED_REQUEST_COLOR
  });

export const setLastFetch = (timestamp) => ({
  type: SET_LAST_FETCH,
  payload: timestamp,
});
