export const ChangeBadgeActionTypes = {
    CHANGE_BADGE_PERSONAL_DATA: 'CHANGE_BADGE_PERSONAL_DATA',
    CHANGE_BADGE_VERIFY_ACCOUNT: 'CHANGE_BADGE_VERIFY_ACCOUNT',
    CHANGE_BADGE_GENERAL: 'CHANGE_BADGE_GENERAL',
    INCREMENT_BADGE_GENERAL: 'INCREMENT_BADGE_GENERAL',
    DECREMENT_BADGE_GENERAL: 'DECREMENT_BADGE_GENERAL',
    CHANGE_BADGE_UNREADMESSAGE: 'CHANGE_BADGE_UNREADMESSAGE',
    CHANGE_PREVIEW_UNREADMESSAGE: 'CHANGE_PREVIEW_UNREADMESSAGE',
    FIRST_NOTIFICATION: 'FIRST_NOTIFICATION',
    CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION'
}
    
export function ChangeBadgePersonalData (newBadge) {
    return { type: ChangeBadgeActionTypes.CHANGE_BADGE_PERSONAL_DATA, payload: newBadge }
}

export function ChangeBadgeVerifyAccount (newBadge) {
    return { type: ChangeBadgeActionTypes.CHANGE_BADGE_VERIFY_ACCOUNT, payload: newBadge }
}

export function ChangeBadgeGeneral (newBadge) {
    return { type: ChangeBadgeActionTypes.CHANGE_BADGE_GENERAL, payload: newBadge }
}

export function IncrementBadgeGeneral () {
    return { type: ChangeBadgeActionTypes.INCREMENT_BADGE_GENERAL}
}

export function DecrementBadgeGeneral () {
    return { type: ChangeBadgeActionTypes.DECREMENT_BADGE_GENERAL }
}

export function ClearNotification () {
    return { type: ChangeBadgeActionTypes.CLEAR_NOTIFICATION}
}

export function ChangeBadgeUnreadMessage (newBadge) {
    return { type: ChangeBadgeActionTypes.CHANGE_BADGE_UNREADMESSAGE, payload: newBadge }
}

export function ChangePreviewUnreadMessage (ticket) {
    return { type: ChangeBadgeActionTypes.CHANGE_PREVIEW_UNREADMESSAGE, payload: ticket }
}

export function ChangeFirstNotification (ticket) {
    return { type: ChangeBadgeActionTypes.FIRST_NOTIFICATION, payload: ticket }
}