import type { DataError } from '@clean/domain/entities/dataError';
import { Either } from '@clean/domain/entities/either';
import { EitherAsync } from '../entities/eitherAsync';
import { FPRepository } from '../repositories/fpRepository';

class FPUseCase implements FPRepository {
  fpRepo: FPRepository;

  constructor(FR: FPRepository) {
    this.fpRepo = FR;
  }

  async GetFullData(Visitor: string): Promise<Either<DataError, any>> {
    const result = EitherAsync.fromPromise(this.fpRepo.GetFullData(Visitor));

    return result
      .flatMap(async (fullDataFP) => {
        return fullDataFP;
      })
      .run();
  }
}

export default FPUseCase;
