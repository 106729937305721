const HttpApi = require('i18next-http-backend')

module.exports = {
  i18n: {
    defaultLocale: 'es',
    locales: [ 'es'],
    localeDetection: false
  },
  preload: ['es'],
  whitelist: ['es'],
  use: [HttpApi],
  defaultLanguage: ["es"],
  ns: ['common'],
  fallbackLng: ['es'],
  defaultNS: "common",
  localeStructure: "{{lng}}/{{ns}}",
  debug: false,
  browserLanguageDetection: false,
  serializeConfig: false,
  customHeaders: {
    'Content-Type': 'application/json'
  },
  react: {
    useSuspense: true,
  },
  backend: {
    loadPath: `${process.env.NEXT_PUBLIC_BUCKET_URL}/{{lng}}/{{ns}}.json`,
    crossDomain: true,
    keySeparator: false,
    nsSeparator: false,
    reloadInterval: 180000,
    mode: 'no-cors',
    credentials: 'same-origin',
    cache: 'default'
  }
}
