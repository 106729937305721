import {
  GET_MENU_LEADERBOARD_REJECTED,
  GET_MENU_LEADERBOARD_REQUEST, 
  GET_MENU_LEADERBOARD_SUCCESS
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiLeaderboardMenuRequest(payload) {
  return { type: GET_MENU_LEADERBOARD_REQUEST, payload }
}
export function getStrapiLeaderboardMenuSuccess(payload) {
  return { type: GET_MENU_LEADERBOARD_SUCCESS, payload }
}
export function getStrapiLeaderboardMenuRejected() {
  return { type: GET_MENU_LEADERBOARD_REJECTED }
}
