import { call, put, all, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  sessionReminderActionTypes,
  requestSessionReminder,
  requestSuccessSessionReminderDelete,
  requestSuccessSessionReminder,
  requestSuccessSessionReminderStatus,
  requestRejectSessionReminder,
  requestRejectSessionReminderDelete,
  requestRejectSessionReminderStatus,
  requestSuccessSessionReminderLogout,
  requestRejectSessionReminderLogout,
  requestSuccessSessionReminderCloseModal,
  requestRejectSessionReminderCloseModal
} from '@clean/application/redux/sessionReminder/actions/sessionReminder';
import { logoutRequest } from '@clean/application/redux/auth/actions/auth';
import { hideModal } from '@clean/application/redux/modal/actions';

function* GetSessionReminderCustomer() {
  try {
    let result = yield axios.post(`/api/v2/apiSessionReminder/GetSessionReminder`);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* PostSessionReminderCustomer(data) {
  try {
    let result = yield axios.post(`/api/v2/apiSessionReminder/PostSessionReminder`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* DeleteSessionReminderCustomer(data) {
  try {
    let result = yield axios.post(`/api/v2/apiSessionReminder/DeleteSessionReminder`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* LogoutSessionReminderCustomer() {
  try {
    let result = yield axios.post(`/api/v2/apiSessionReminder/PostLogout`);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* CloseModalSessionReminderCustomer() {
  try {
    let result = yield axios.post(`/api/v2/apiSessionReminder/PostCloseModal`);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* handleGetSessionReminder() {
  try {
    let Status = yield call(GetSessionReminderCustomer)
    if (Status !== null) {
      yield put(requestSuccessSessionReminder(Status))
    } else {
      yield put(requestRejectSessionReminder())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handlePostSessionReminderCustomer({ payload }) {
  try {
    let srData = yield call(GetSessionReminderCustomer);

    let data;
    if (payload?.reminderDuration) {
      data = payload
    } else {
      if (srData?.reminderDuration) {
        data = { reminderDuration: srData?.reminderDuration }
      } else {
        yield put(requestRejectSessionReminder())
      }
    }

    let Status = yield call(PostSessionReminderCustomer, data)
    if (Status !== null) {
      yield put(requestSuccessSessionReminderStatus(Status))
      yield put(requestSessionReminder())
    } else {
      yield put(requestRejectSessionReminderStatus())
      yield put(requestSessionReminder())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleDeleteSessionReminderCustomer({ payload }) {
  try {
    let Status = yield call(DeleteSessionReminderCustomer, payload)
    if (Status !== null) {
      yield put(requestSuccessSessionReminderDelete(Status))
      yield put(requestSessionReminder())
    } else {
      yield put(requestRejectSessionReminderDelete())
      yield put(requestSessionReminder())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleLogoutSessionReminderCustomer() {
  try {
    let Status = yield call(LogoutSessionReminderCustomer)
    if (Status !== null) {
      yield put(requestSuccessSessionReminderLogout(Status))
    } else {
      yield put(requestRejectSessionReminderLogout())
    }
    yield put(requestSuccessSessionReminder(null))
    yield put(logoutRequest())
  } catch (e) {
    console.log(e)
  }
}

function* handleCloseModalSessionReminderCustomer() {
  try {
    let Status = yield call(CloseModalSessionReminderCustomer)
    if (Status !== null) {
      yield put(requestSuccessSessionReminderCloseModal(Status))
      yield put(hideModal())
    } else {
      yield put(requestRejectSessionReminderCloseModal())
      yield put(hideModal())
    }
  } catch (e) {
    console.log(e)
  }
}

export default all([
  takeLatest(sessionReminderActionTypes.REQUEST_SESSION_REMINDER, handleGetSessionReminder),
  takeLatest(sessionReminderActionTypes.REQUEST_SESSION_REMINDER_POST, handlePostSessionReminderCustomer),
  takeLatest(sessionReminderActionTypes.REQUEST_SESSION_REMINDER_DELETE, handleDeleteSessionReminderCustomer),
  takeLatest(sessionReminderActionTypes.REQUEST_SESSION_REMINDER_LOGOUT, handleLogoutSessionReminderCustomer),
  takeLatest(sessionReminderActionTypes.REQUEST_SESSION_REMINDER_CLOSE_MODAL, handleCloseModalSessionReminderCustomer)
]);
