export const liveCasinoActionTypes = {
  SEARCH_GAME_REQUEST: '@liveCasino/SEARCH_GAME_REQUEST',
  SEARCH_GAME_SUCCESS: '@liveCasino/SEARCH_GAME_SUCCESS',
  SEARCH_GAME_FAILED: '@liveCasino/SEARCH_GAME_FAILED',
  CHANGE_PRODUCER: '@liveCasino/CHANGE_PRODUCER',
  CHANGE_PAGE: '@liveCasino/CHANGE_PAGE',
  LOAD_MORE_GAME: '@liveCasino/LOAD_MORE_GAME',
  CHANGE_SEARCH_INPUT: '@liveCasino/CHANGE_SEARCH_INPUT',
  CHANGE_FILTER_LIMIT: '@liveCasino/CHANGE_FILTER_LIMIT',
  CASINORESETERROR: '@liveCasino/CASINORESETERROR',
  CHANGE_TAB: '@liveCasino/CHANGE_TAB',
  ALL_GAME_SHOW: '@liveCasino/ALL_GAME_SHOW',
  CHANGE_IS_MOBILE: '@liveCasino/CHANGE_IS_MOBILE',
  CASINORESET: '@liveCasino/CASINORESET',
  GET_HOME_LIVECASINO_GAME: 'liveCasino/GET_HOME_LIVECASINO_GAME',
  GET_HOME_LIVECASINO_GAME_SUCCESS: 'liveCasino/GET_HOME_LIVECASINO_GAME_SUCCESS'
}