import { select, put, all, takeLatest } from 'redux-saga/effects';
import {
  putAmountBalance
} from '@clean/application/redux/balance/actions/balance'

function* refreshBalance() {

  let userInfo = yield select(state =>
    state.auth.userInfo
  );

  yield put(putAmountBalance(userInfo.balances, userInfo.balance))

}


export default all([
  takeLatest('@balance/PUTAMOUNTBALANCEREQUEST', refreshBalance),
]);
