import { AclTruePlayRepository } from '@clean/domain/repositories/acl-truePlay/AclTruePlayRepository';
import axios from 'axios';

export default class ACLTruePlayRepositoryImpl implements AclTruePlayRepository {

  async getUrlTruePlay(
    customerId: string
    ): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: urlResult} = await axios.post(
          `/api/v2/acl-truePlay/getUrlTruePlay`,
          {
            customerId: customerId
          }
        );
        resolver(urlResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getTPBalance(
    customerId: string
    ): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: urlResult} = await axios.post(
          `/api/v2/acl-truePlay/getTPBalance`,
          {
            customerId: customerId
          }
        );
        resolver(urlResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
