import produce from 'immer';
import { OrdersActionTypes } from '../constants/depositConfirm';

const OrdersInitialState = {
  errorsDeposit: [],
  depositInfo: {},
  loadingGetOneOrder: true,
  proccesGetOneOrder: false,
  loadingPatchDepositDelivery: false,
  proccesPatchDepositDelivery: false
}

export default function toasts(state = OrdersInitialState, action) {
  const { payload, type } = action;

  switch (type) {
     //GetOne
     case OrdersActionTypes.GETONEORDERREQUEST:
      return produce(state, draft => {
        draft.loadingGetOneOrder = true
        draft.proccesGetOneOrder = false
      });
    case OrdersActionTypes.GETONEORDERSUCCESS:
      return produce(state, draft => {
        draft.depositInfo = action.payload
        draft.proccesGetOneOrder = true
        draft.loadingGetOneOrder = false
      });
    //PatchOne
    case OrdersActionTypes.PATCHDEPOSITDELIVERYREQUEST:
      return produce(state, draft => {
        draft.loadingPatchDepositDelivery = true
        draft.proccesPatchDepositDelivery = false
      });
    case OrdersActionTypes.PATCHDEPOSITDELIVERYSUCCESS:
      return produce(state, draft => {
        draft.proccesPatchDepositDelivery = true
        draft.loadingPatchDepositDelivery = false
      });
    //COMMON
    case OrdersActionTypes.RESETDEPOSIT:
      return produce(state, draft => {
        draft.loadingGetOneOrder = true
        draft.proccesGetOneOrder = false
      });
    case OrdersActionTypes.RESETERRORSDEPOSIT:
      return produce(state, draft => {
        draft.errorsDeposit = []
      });
    case OrdersActionTypes.DEPOSITFAILED:
      return produce(state,draft => {
        draft.loadingGetOneOrder = false
        draft.proccesGetOneOrder = false
        draft.errorsDeposit.push(action.payload)
        draft.depositInfo = {}
      })
    default:
      return state;
  }
}
