import { put, all, takeLatest } from 'redux-saga/effects';
import { AclTruePlayActionTypes } from '@clean/application/redux/aclTruePlay/constant';
import ACLTruePlayUseCase from '@clean/domain/useCase/acl-truePlay/aclTruePlayUseCase';
import AclTruePlayRepositoryImpl from '@clean/infrastructure/repositories/AclTruePlayRepositoryImpl';
import {
  UrlTruePlaySuccess,
  UrlTruePlayRejected,
  BalanceTruePlaySuccess,
  BalanceTruePlayRejected
} from '@clean/application/redux/aclTruePlay/actions/index'
const truePlayRepo = new AclTruePlayRepositoryImpl();
const truePlayService = new ACLTruePlayUseCase(truePlayRepo);

function* GetUrlTruePlay (params)  {
  try{
    const urlResult = yield truePlayService.getUrlTruePlay(params.payload);
    yield put(UrlTruePlaySuccess(urlResult.url))
  }catch(err){
    yield put(UrlTruePlayRejected())
  }
}

function* GetBalanceTruePlay (params)  {
  try{
    const balanceResult = yield truePlayService.getTPBalance(params.payload);
    if(balanceResult?.statusCode === 404){
      return yield put(BalanceTruePlayRejected())
    }
    if(balanceResult.balance){
      return yield put(BalanceTruePlaySuccess(balanceResult.balance))
    }
    return yield put(BalanceTruePlayRejected())
  }catch(err){
    yield put(BalanceTruePlayRejected())
  }
}

export default all([
  takeLatest(AclTruePlayActionTypes.REQUEST_GET_URL_TRUE_PLAY, GetUrlTruePlay),
  takeLatest(AclTruePlayActionTypes.REQUEST_GET_BALANCE_TRUE_PLAY, GetBalanceTruePlay)
]);
