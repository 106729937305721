import { ChangeBadgeActionTypes } from '../actions/badge'

const BadgeInitialState = {
  badge_personal_data: 0,
  badge_verify_account: 0,
  badge_general: 0,
  badge_unread_message: 0,
  previewTicket_unread_message: '',
  firstNotification: null,
}

const reducer = (state = BadgeInitialState, action) => {
  switch (action.type) {
    case ChangeBadgeActionTypes.CHANGE_BADGE_PERSONAL_DATA:
      return {
        ...state,
        badge_personal_data: action.payload,
      }
    case ChangeBadgeActionTypes.CHANGE_BADGE_VERIFY_ACCOUNT:
      return {
        ...state,
        badge_verify_account: action.payload,
      }
    case ChangeBadgeActionTypes.CHANGE_BADGE_GENERAL:
      return {
        ...state,
        badge_general: action.payload,
      }
    case ChangeBadgeActionTypes.INCREMENT_BADGE_GENERAL:
      return {
        ...state,
        badge_general: state.badge_general + 1,
      }

    case ChangeBadgeActionTypes.DECREMENT_BADGE_GENERAL:
      return {
        ...state,
        badge_general: state.badge_general - 1,
      }
    case ChangeBadgeActionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        firstNotification: null,
      }

    case ChangeBadgeActionTypes.CHANGE_BADGE_UNREADMESSAGE:
      return {
        ...state,
        badge_unread_message: action.payload,
      }
    case ChangeBadgeActionTypes.CHANGE_PREVIEW_UNREADMESSAGE:
      return {
        ...state,
        previewTicket_unread_message: action.payload,
      }
    case ChangeBadgeActionTypes.FIRST_NOTIFICATION:
      return {
        ...state,
        firstNotification: action.payload,
      }
    default:
      return state
  }
}

export default reducer
