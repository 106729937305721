// actions/colorActions.js
export const REQUEST_REGISTER_CONFIG = 'REQUEST_REGISTER_CONFIG';
export const SUCESS_REQUEST_REGISTER_CONFIG = 'SUCESS_REQUEST_REGISTER_CONFIG';
export const FAILED_REQUEST_REGISTER_CONFIG = 'FAILED_REQUEST_REGISTER_CONFIG';
export const SET_LAST_FETCH = 'SET_LAST_FETCH';

export const requestRegisterConfig = (country) => ({
  type: REQUEST_REGISTER_CONFIG,
  payload: country
})

export const successRequestRegisterConfig = (country) => ({
  type: SUCESS_REQUEST_REGISTER_CONFIG,
  payload: country,
});

export const failedRequestRegisterConfig = () => ({
  type: FAILED_REQUEST_REGISTER_CONFIG
});

export const setLastFetch = (timestamp) => ({
  type: SET_LAST_FETCH,
  payload: timestamp,
});
