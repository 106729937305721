import { ACLSeonRepository } from '@clean/domain/repositories/acl-seon/AclSeonRepository';
import { DTOVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoRequest';
import { DTOResponseVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoResponse';
export default class ACLSeonUseCase implements  ACLSeonRepository {
  aclSeonRepo: ACLSeonRepository;

  constructor(AR: ACLSeonRepository) {
    this.aclSeonRepo = AR;
  }

  async VerifyFraud(
    Body: DTOVerifyFraud
    ): Promise<DTOResponseVerifyFraud>{
    return this.aclSeonRepo.VerifyFraud(Body);
  }
}
