import { redirectActionTypes } from '../actions/redirect';
import produce from 'immer';

const redirectInitialState = {
  currentPath: null,
  pendingRedirect: {
    redirectTo: null, // si no es nulo, existe un path pendiente
    credentials: false, // por si el redirect necesita estar logeado previamente para realizarlo
  },
}

const reducer = (state = redirectInitialState, action) => {
  switch (action.type) {
    case redirectActionTypes.REDIRECT:
      return produce(state, draft => {
        draft.currentPath = null
        draft.pendingRedirect = {
          redirectTo: null,
          credentials: false,
        }
      });
    case redirectActionTypes.CHANGECURRENT:
      return produce(state, draft => {
        draft.currentPath = action.payload
      });
    case redirectActionTypes.CHANGEPENDINGREDIRECT:
      return produce(state, draft => {
        draft.pendingRedirect = action.payload
      });
    default:
      return state
  }
}

export default reducer
