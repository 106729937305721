import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createWrapper } from 'next-redux-wrapper'
import auth from '@clean/application/redux/auth/reducers/auth'
import configurationPages from '@clean/application/redux/layout/reducers/configurationPages'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import SelectedLanguage from '@clean/application/redux/layout/reducers/SelectedLanguage'
import liveCasino from '@clean/application/redux/games/reducers/liveCasino';
import badge from '@clean/application/redux/notifications/reducers/badge'
import games from '@clean/application/redux/games/reducers/games'
import rootSaga from './rootSaga'
import redirectGlobal from '@clean/application/redux/redirect/reducers/redirectGlobal'
import faq from '@clean/application/redux/layout/reducers/faq'
import ReferenceCode from '@clean/application/redux/register/reducers/ReferenceCode'
import redirectMatch from '@clean/application/redux/match/reducers/redirectMatch'
import currency from '@clean/application/redux/currency/reducers/currency'
import balance from '@clean/application/redux/balance/reducers/balance'
import preferences from '@clean/application/redux/games/reducers/preferences'
import modals from '@clean/application/redux/modal/reducers/modals'
import layout from '@clean/application/redux/layout/reducers/layout'
import bonus from '@clean/application/redux/bonus/reducers/bonus'
import register from '@clean/application/redux/register/reducers/register'
import depositConfirm from '@clean/application/redux/deposit/reducers/depositConfirm'
import package_json from '../../../package.json'
import fp from '@clean/application/redux/fp/reducers/fp'
import Ranking from '@clean/application/redux/leaderboard/reducers/ranking'
import Footer from '@clean/application/redux/layout/reducers/footer';
import SecurityVPN from '@clean/application/redux/security/reducers/securityReducer';
import Redirect from '@clean/application/redux/redirect/sagas/redirect';
import Promotions from '@clean/application/redux/promotions/reducers';
import Tombola from '@clean/application/redux/tombola/reducers/index';
import Leaderboard from '@clean/application/redux/leaderboard/reducers/index';
import Strapi from '@clean/application/redux/strapi/reducers/leaderboard';
import PendingActions from '@clean/application/redux/pendingActions/reducers/pendingActions';
import TruePlay from '@clean/application/redux/aclTruePlay/reducers/index';
import ConfigModals from '@clean/application/redux/modal/reducers/index';
import responsibleGambling from '@clean/application/redux/strapi/reducers/responsibleGambling'
import sessionReminder from '@clean/application/redux/sessionReminder/reducers/sessionReminder'
import limitDeposit from '@clean/application/redux/limitDeposit/reducers/limitDeposit'
import selfExclusion from '@clean/application/redux/selfExclusion/reducers/selfExclusion'
import colorConfig from '@clean/application/redux/strapi/reducers/colorConfig'
import registerConfig from '@clean/application/redux/strapi/reducers/registerConfig'
import bonusPromotional from '@clean/application/redux/bonus/reducers/bonusPromotional'
import bonusCodePromotional from '@clean/application/redux/bonus/reducers/bonusCodePromotional'
import bonusRegister from '@clean/application/redux/bonus/reducers/bonusRegister'
import bonusDeposit from '@clean/application/redux/bonus/reducers/bonusDeposit'
import casino from '@clean/application/redux/games/reducers/casino'

const combinedReducer = combineReducers({
  TruePlay,
  auth,
  configurationPages,
  SelectedLanguage,
  redirectGlobal,
  badge,
  games,
  currency,
  colors: colorConfig,
  registerConfig,
  faq,
  Footer,
  ReferenceCode,
  redirectMatch,
  balance,
  preferences,
  liveCasino,
  modals,
  layout,
  casino,
  bonus,
  Tombola,
  ConfigModals,
  Leaderboard,
  bonusPromotional,
  register,
  depositConfirm,
  Promotions,
  bonusCodePromotional,
  bonusRegister,
  bonusDeposit,
  fp,
  Ranking,
  SecurityVPN,
  Redirect,
  PendingActions,
  Strapi,
  responsibleGambling,
  sessionReminder,
  limitDeposit,
  selfExclusion,
})

const rootReducer = (state, action) => {
  return combinedReducer(state, action)
}

const makeConfiguredStore = (reducer) => {
  const sagaMiddleware = createSagaMiddleware()
  const store: any = createStore(reducer, undefined, applyMiddleware(sagaMiddleware))
  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}

// create a makeStore function
const makeStore = () => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    return makeConfiguredStore(rootReducer)
  } else {
    // we need it only on client side
    const { createMigrate, persistStore, persistReducer } = require('redux-persist')
    const storage = require('redux-persist/lib/storage').default
    const versionStore = package_json.storeV

    const migrations = {
      15: (state) => {
        return {
          ...state,
          fp: {
            countryDetected: 'cl',
            documentType: 'Cédula',
            userIp: 'juegaloIp',
            userCity: 'juegaloCity',
            hashFinger: 'WEB',
            createdFinger: null,
            countryBlockDetected: false,
            browserNotAllowed: false
          },
        }
      },
      16: (state) => {
        return {
          ...state,
          Ranking: {
            lastUpdated: null,
            lastUpdatedPosition: null,
            loadingRanking: false,
            loadingRankingTop10: false,
            loadingPosition: false,
            detailsRankingCustomer: {
              position: 0,
              tickets: 0
            },
            top: [],
            position: [],
            top10: [],
            statusPlayer: false
          }
        }
      },
      17: (state) => {
        return {
          ...state,
          Footer: {
            countryUsed: '',
            menuFooter: [],
            contents: [],
            socialMedia: [],
            providersFooter: [],
            lastUpdatedFooter: null
          },
        }
      },
      18: (state) => {
        return {
          ...state,
          SecurityInitialState: {
            loadingIpDetail: true,
            ip: null,
            security: {
              vpn: true,
              proxy: true,
              tor: true,
              relay: true,
            },
            location: {
              city: 'Santiago',
              region: 'Santiago Metropolitan',
              country: 'Chile',
              continent: 'South America',
              region_code: 'RM',
              country_code: 'CL',
              continent_code: 'SA',
              latitude: '',
              longitude: '',
              time_zone: 'America/Santiago',
              locale_code: 'en',
              metro_code: '',
              is_in_european_union: false,
            },
            network: {
              network: '',
              autonomous_system_number: '',
              autonomous_system_organization: '',
            },
          }
        }
      },
      19: (state) => {
        return {
          ...state,
          Promotions: {
            loadingGetPromotions: false,
            loadingViewPromotions: false,
            allPromotions: [],
            promotionActive: {
              id: null,
              title: '',
              description: '',
              startDate: null,
              endDate: null,
              images: [],
              trigger: '',
              status: '',
            }
          }
        }
      },
      20: (state) => {
        return {
          ...state,
          Redirect: {
            currentPath: null,
            pendingRedirect: {
              redirectTo: null,
              credentials: false,
            },
          }
        }
      },
      21: (state) => {
        return {
          ...state,
          PendingActions: {
            actions: []
          }
        }
      },
      22: (state) => {
        return {
          ...state,
          casino: {
            searchGame: '',
            producerSelected: [],
            page: 1,
            filterOffset: 0,
            filterLimit: 24,
            loadingInitialGame: false,
            tabSelected: 'rating',
            loadingMoreGame: false,
            allGameShowReady: false,
            listGames: [],
            errosCasino: [],
            isMobile: false
          },
          liveCasino: {
            searchGame: '',
            producerSelected: [],
            page: 1,
            filterOffset: 0,
            filterLimit: 24,
            loadingInitialGame: false,
            tabSelected: 'rating',
            loadingMoreGame: false,
            allGameShowReady: false,
            listGames: [],
            listGameForCategory: [],
            loadingGameForCategory: false,
            errosCasino: [],
            isMobile: false
          }
        }
      },
      23: (state) => {
        return {
          ...state,
          TruePlay: {
            loadingGetUrlTruePlay: false,
            urlTruePlay: '',
            loadingGetBalanceTruePlay: false,
            balanceTokens: 0
          }
        }
      },
      24: (state) => {
        return {
          ...state,
          auth: {
            ...state.auth,
            haveDeposit: false
          },
          ConfigModals: {
            modalType: null,
            isOpen: false,
            modalProps: {},
            modalsView: {
              popUpMarketing: false
            }
          }
        }
      },
      25: (state) => {
        return {
          ...state,
          auth: {
            ...state.auth,
            haveDeposit: false
          },
          ConfigModals: {
            modalType: null,
            isOpen: false,
            modalProps: {},
            modalsView: {
              popUpMarketing: false
            }
          }
        }
      },
      26: (state) => {
        return {
          ...state,
          ConfigModals: {
            modalType: null,
            isOpen: false,
            modalProps: {},
            modalsView: {
              popUpMarketing: false
            }
          }
        }
      },
      27: (state) => {
        return {
          ...state,
          liveCasino: {
            ...state.liveCasino,
            searchListGame: []
          }
        }
      },
      28: (state) => {
        return {
          ...state,
          ConfigModals: {
            ...state.ConfigModals,
            modalsView: {
              popUpMarketing: false,
              popUpMarketingCL: false
            }
          }
        }
      },
      29: (state) => {
        return {
          ...state,
          bonus: {
            ...state.bonus,
            detailBonoStrapi: null
          }
        }
      },
      30: (state) => {
        return {
          ...state,
          register: {
            ...state.register,
            needFraudValidation: false
          }
        }
      },
      31: (state) => {
        return {
          ...state,
          Strapi: {
            ...state.Strapi,
            strapiLeaderbordMenu: null,
            strapiCountdown: null,
            loadingStrapiCountdown: false,
            lastUpdatedTimeLbHome: null,
            lastUpdatedTimeLbPromo: null,
            lastUpdatedTimeLbMenu: null,
            lastUpdatedTimeLb: null,
            lastUpdatedTimeCountdown: null,
          }
        }
      },
      32: (state) => {
        return {
          ...state,
          selfExclusion: {
            ...state.selfExclusion,
            isExclude: null,
            until: null
          }
        }
      },
      33: (state) => {
        return {
          ...state,
          responsibleGambling: {
            ...state.responsibleGambling,
            depositLimit: [],
            sessionReminder: [],
            selfExclusion: [],
            blockDeposit: false,
            loading: false,
            activeTab: null
          }
        }
      },
      34: (state) => {
        return {
          ...state,
          colors: {
            currentColors: null,
            lastFetch: null,
          }
        }
      },
      35: (state) => {
        return {
          ...state,
          colors: {
            currentColors: null,
            loadingGetColors: false,
            lastFetch: null
          }
        }
      },
      36: (state) => {
        return {
          ...state,
          Strapi: {
            ...state.Strapi,
            loadingStrapiJackpotLanding: false,
            loadingStrapiMenuNavBarLanding: false,
            strapiJackpotLanding: null,
            strapiMenuNavBar: null,
            lastUpdatedTimeJackpotLanding: null,
            lastUpdatedTimeMenuNavBar: null
          }
        }
      }
    }

    const persistConfig = {
      key: process.env.NEXT_PUBLIC_KEY_PERSIST,
      storage,
      version: versionStore,
      debug: true,
      stateReconciler: hardSet,
      migrate: createMigrate(migrations, { debug: false })
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = makeConfiguredStore(persistedReducer)

    store.__persistor = persistStore(store) // Nasty hack

    return store
  }
}

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: false })
