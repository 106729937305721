import { faqActionTypes } from "../constants/faq"

const faqInitialState = {
  faqTypeClassSelection: null,
}

const reducer = (state = faqInitialState, action) => {
  switch (action.type) {
    case faqActionTypes.FAQDATA:
      return {
        ...state,
        faqTypeClassSelection: action.payload,
      }
    default:
      return state
  }
}

export default reducer
