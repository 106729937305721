import { responsibleGamblingActionTypes } from '../actions/responsibleGambling'
import produce from 'immer';

const ResponsibleGamblingInitialState = {
  depositLimit: [],
  sessionReminder: [],
  selfExclusion: [],
  blockDeposit: false,
  loading: false,
  activeTab: null
}

const reducer = (state = ResponsibleGamblingInitialState, action) => {
  switch (action.type) {
    case responsibleGamblingActionTypes.REQUEST_RESPOSIBLE_GAMBLING:
      return produce(state, draft => {
        draft.loading = true
      });
    case responsibleGamblingActionTypes.REQUEST_SUCCESS_RESPOSIBLE_GAMBLING:
      return produce(state, draft => {
        draft.loading = false;
        draft.depositLimit = action.payload?.depositLimit ?? [];
        draft.sessionReminder = action.payload?.sessionReminder ?? [];
        draft.selfExclusion = action.payload?.selfExclusion ?? [];
        draft.blockDeposit = action.payload?.blockDeposit ?? false;
        draft.activeTab = action.payload?.activeTab;
      });
    case responsibleGamblingActionTypes.REQUEST_REJECT_RESPOSIBLE_GAMBLING:
      return produce(state, draft => {
        draft.loading = false
      });
    default:
      return state
  }
}

export default reducer;
