// reducers/colorReducer.js
import {
    REQUEST_REGISTER_CONFIG,
    SUCESS_REQUEST_REGISTER_CONFIG,
    FAILED_REQUEST_REGISTER_CONFIG,
    SET_LAST_FETCH
} from '@clean/application/redux/strapi/actions/registerConfig';

const initialState = {
    appliesKYC: false,
    appliesSms: false,
    loading: false,
    lastFetch: null,
};

const registerConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCESS_REQUEST_REGISTER_CONFIG:
            return {
                ...state,
                appliesKYC: action.payload.appliesKYC,
                appliesSms: action.payload.appliesSms,
                loading: false
            };
        case REQUEST_REGISTER_CONFIG:
            return {
                ...state,
                loading: true,
            };
        case FAILED_REQUEST_REGISTER_CONFIG:
            return {
                ...state,
                loading: false,
            };
        case SET_LAST_FETCH:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default registerConfigReducer;
