import { modalsActionTypes } from '../actions/modals'
import produce from 'immer'

type Modal = {
  showFirstDepositModal: boolean,
  showBonusRegisterModal: boolean,
  showPromotionsModal: boolean
}

const modalsInitialState: Modal = {
  showFirstDepositModal: false,
  showBonusRegisterModal: false,
  showPromotionsModal: false
}

const reducer = (state = modalsInitialState, action) => {
  switch (action.type) {
    case modalsActionTypes.OPEN_MODAL_FIRST_DEPOSIT:
      return produce(state, (draft) => {
        draft.showFirstDepositModal = true
      })
    case modalsActionTypes.CLOSE_MODAL_FIRST_DEPOSIT:
      return produce(state, (draft) => {
        draft.showFirstDepositModal = false
      })
    case modalsActionTypes.OPEN_MODAL_PROMOTIONS:
      return produce(state, (draft) => {
        draft.showPromotionsModal = true
      })
    case modalsActionTypes.CLOSE_MODAL_PROMOTIONS:
      return produce(state, (draft) => {
        draft.showPromotionsModal = false
      })
    case modalsActionTypes.OPEN_MODAL_BONUS_REGISTER:
      return produce(state, (draft) => {
        draft.showBonusRegisterModal = true
      })
    case modalsActionTypes.CLOSE_MODAL_BONUS_REGISTER:
      return produce(state, (draft) => {
        draft.showBonusRegisterModal = false
      })
    default:
      return state
  }
}

export default reducer
