import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  requestGetDepositLimit,
  requestRejectGetDepositLimit,
  requestRejectPatchCancelDepositLimit,
  requestRejectPatchUpdateDepositLimit,
  requestRejectPostDepositLimit,
  requestSuccessGetDepositLimit,
  requestSuccessPatchCancelDepositLimit,
  requestSuccessPatchUpdateDepositLimit,
  requestSuccessPostDepositLimit
} from '@clean/application/redux/limitDeposit/actions/limitDeposit';
import axios from 'axios';
import { depositLimitActionTypes } from '../constants/limitDeposit';

function* GetDepositLimit() {
  try {
    let result = yield axios.post(`/api/v2/apiDepositLimit/GetDepositLimit`);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* PostDepositLimit(data) {
  try {
    let result = yield axios.post(`/api/v2/apiDepositLimit/PostDepositLimit`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* PatchCancelDepositLimit(data) {
  try {
    let result = yield axios.post(`/api/v2/apiDepositLimit/PatchCancelDepositLimit`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* PatchUpdateDepositLimit(data) {
  try {
    let result = yield axios.post(`/api/v2/apiDepositLimit/PatchUpdateDepositLimit`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}


function* handleGetDepositLimit() {
  try {
    let Result = yield call(GetDepositLimit)
    if (Result !== null) {
      yield put(requestSuccessGetDepositLimit(Result))
    } else {
      yield put(requestRejectGetDepositLimit())
    }
  } catch (e) {
    console.log(e)
  }
}


function* handlePostDepositLimit({ payload }) {
  try {
    let Result = yield call(PostDepositLimit, payload)
    if (Result !== null) {
      yield put(requestSuccessPostDepositLimit(Result))
      yield put(requestGetDepositLimit())
    } else {
      yield put(requestRejectPostDepositLimit())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handlePatchCancelDepositLimit({ payload }) {
  try {
    let Result = yield call(PatchCancelDepositLimit, payload)
    if (Result !== null) {
      yield put(requestSuccessPatchCancelDepositLimit(Result))
      yield put(requestGetDepositLimit())
    } else {
      yield put(requestRejectPatchCancelDepositLimit())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handlePatchUpdateDepositLimit({ payload }) {
  try {
    let Result = yield call(PatchUpdateDepositLimit, payload)
    if (Result !== null) {
      yield put(requestSuccessPatchUpdateDepositLimit(Result))
      yield put(requestGetDepositLimit())
    } else {
      yield put(requestRejectPatchUpdateDepositLimit())
    }
  } catch (e) {
    console.log(e)
  }
}

export default all([
  takeLatest(depositLimitActionTypes.REQUEST_GET_DEPOSIT_LIMIT, handleGetDepositLimit),
  takeLatest(depositLimitActionTypes.REQUEST_PATCH_CANCEL_DEPOSIT_LIMIT, handlePatchCancelDepositLimit),
  takeLatest(depositLimitActionTypes.REQUEST_PATCH_UPDATE_DEPOSIT_LIMIT, handlePatchUpdateDepositLimit),
  takeLatest(depositLimitActionTypes.REQUEST_POST_DEPOSIT_LIMIT, handlePostDepositLimit)
]);
