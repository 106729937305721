import { LeaderboardActionTypes } from '@clean/application/redux/leaderboard/constant';
import produce from 'immer';

const leaderInitialState = {
  loadingLeaderboard: false,
  isParticipatingLeaderboard: false
}

const reducer = (state = leaderInitialState, action) => {
  switch (action.type) {
    case LeaderboardActionTypes.REQUEST_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingLeaderboard = true;
      });
    case LeaderboardActionTypes.SUCCESS_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingLeaderboard = false;
        draft.isParticipatingLeaderboard = action.payload;
      });
    case LeaderboardActionTypes.REJECTED_GET_STATUS_PLAYER:
      return produce(state, draft => {
        draft.loadingLeaderboard = false;
        draft.isParticipatingLeaderboard = false;
      });

    default:
      return state
  }
}

export default reducer
