import { FooterActionsTypes } from "../constants/footer"


export function getMenuFooter () {
  return { type: FooterActionsTypes.GET_MENU_FOOTER }
}

export function getMenuFooterSuccess (payload) {
  return { type: FooterActionsTypes.GET_MENU_FOOTER_SUCCESS, payload }
}

export function getMenuFooterReject () {
  return { type: FooterActionsTypes.GET_MENU_FOOTER_REJECT }
}
