import { PromotionsRepository } from '@clean/domain/repositories/promotions/promotionsRepository';

export default class PromotionsUseCase implements PromotionsRepository {
  promoRepo: PromotionsRepository;

  constructor(PR:PromotionsRepository) {
    this.promoRepo = PR;
  }

  async getPromotionCustomer(customerId: number): Promise<any>{
    return this.promoRepo.getPromotionCustomer(customerId);
  }
  async viewPromotionCustomer(customerId: number, promotionId: number): Promise<any>{
    return this.promoRepo.viewPromotionCustomer(customerId, promotionId);
  }
}
