import { all, takeLatest } from 'redux-saga/effects';
import {
  GET_HOME_LEADERBOARD_REQUEST,
  GET_LEADERBOARD_REQUEST,
  GET_MENU_LEADERBOARD_REQUEST,
  GET_PROMOTIONS_LEADERBOARD_REQUEST,
  GET_COUNTDOWN_REQUEST,
  GET_MENU_NAV_BAR_REQUEST
} from '@clean/application/redux/strapi/constants/actionsTypes';
import { getStrapiLeaderboardPrincipal } from './leaderboardPrincipal';
import { getStrapiLeaderboardPromotions } from './leaderboardPromotions';
import { getStrapiLeaderboardMenu } from './leaderboardMenu';
import { getStrapiLeaderboard } from './leaderboardPage';
import { getStrapiCountdown } from './countdown';
import { getStrapiMenuNavbar } from './menuNavbar';

export default all([
  takeLatest(GET_HOME_LEADERBOARD_REQUEST, getStrapiLeaderboardPrincipal),
  takeLatest(GET_LEADERBOARD_REQUEST, getStrapiLeaderboard),
  takeLatest(GET_PROMOTIONS_LEADERBOARD_REQUEST, getStrapiLeaderboardPromotions),
  takeLatest(GET_MENU_LEADERBOARD_REQUEST, getStrapiLeaderboardMenu),
  takeLatest(GET_COUNTDOWN_REQUEST, getStrapiCountdown),
  takeLatest(GET_MENU_NAV_BAR_REQUEST, getStrapiMenuNavbar)
]);
