import {LeaderboardRepository } from '@clean/domain/repositories/leaderboard/LeadeboardRepository';
import axios from 'axios';

export default class LeaderboardRepositoryImpl implements LeaderboardRepository {

  async GetStatusPlayer(data): Promise<any>{
    return new Promise(async (resolver, _reject) => {  
      try {
        const { data: verifyResult } = await axios.post(
          `/api/v2/apiLeaderboard/GetStatusPlayer`,
          data
        );
        resolver(verifyResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
