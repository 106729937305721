import { depositLimitActionTypes } from "../constants/limitDeposit"

export function requestPostDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_POST_DEPOSIT_LIMIT, payload }
}
export function requestRejectPostDepositLimit() {
  return { type: depositLimitActionTypes.REQUEST_POST_REJECT_REQUEST_DEPOSIT_LIMIT }
}
export function requestSuccessPostDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_POST_SUCCESS_REQUEST_DEPOSIT_LIMIT, payload }
}

export function requestGetDepositLimit() {
  return { type: depositLimitActionTypes.REQUEST_GET_DEPOSIT_LIMIT }
}
export function requestRejectGetDepositLimit() {
  return { type: depositLimitActionTypes.REQUEST_GET_REJECT_REQUEST_DEPOSIT_LIMIT }
}
export function requestSuccessGetDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_GET_SUCCESS_REQUEST_DEPOSIT_LIMIT, payload }
}

export function requestPatchCancelDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_PATCH_CANCEL_DEPOSIT_LIMIT, payload }
}
export function requestRejectPatchCancelDepositLimit() {
  return { type: depositLimitActionTypes.REQUEST_PATCH_CANCEL_REJECT_REQUEST_DEPOSIT_LIMIT }
}
export function requestSuccessPatchCancelDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_PATCH_CANCEL_REQUEST_DEPOSIT_LIMIT, payload }
}

export function requestPatchUpdateDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_PATCH_UPDATE_DEPOSIT_LIMIT, payload }
}
export function requestRejectPatchUpdateDepositLimit() {
  return { type: depositLimitActionTypes.REQUEST_PATCH_UPDATE_REJECT_REQUEST_DEPOSIT_LIMIT }
}
export function requestSuccessPatchUpdateDepositLimit(payload) {
  return { type: depositLimitActionTypes.REQUEST_PATCH_UPDATE_REQUEST_DEPOSIT_LIMIT, payload }
}
