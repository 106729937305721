import { BonusFirstDepositActionTypes } from "../constants/bonusFirstDeposit"

const FirstDepositBonusInitialState = {
  userViewModal: false
}

const reducer = (state = FirstDepositBonusInitialState, action) => {
  switch (action.type) {
    case BonusFirstDepositActionTypes.UPDATED_VIEW_MODAL:
      return {
        userViewModal: action.payload,
      }
    case 'RESET_STORE':
      return FirstDepositBonusInitialState
    default:
      return state
  }
}

export default reducer
