import { DepositActionsTypes } from "../constants/deposit"


//GET
export function depositMethodRequest () {
  return { type: DepositActionsTypes.DEPOSIT_METHODLIST_REQUEST }
}

export function depositMethodSuccess (payload) {
  return { type: DepositActionsTypes.DEPOSIT_METHODLIST_SUCCESS, payload }
}

export function haveDeposit  () {
  return { type: DepositActionsTypes.VERIFY_HAVE_DEPOSIT }
}

//COMMON
export function depositFailed(error) {
  return { type: DepositActionsTypes.DEPOSITFAILED, payload: error }
}

export function resetErrorsDeposit() {
    return { type: DepositActionsTypes.RESETERRORSDEPOSIT }
}

export function resetDeposit () {
  return { type: DepositActionsTypes.RESETDEPOSIT }
}



