export const rankingActionTypes = {
  REQUEST_RANKING: '@ranking/REQUEST_RANKING',
  REQUEST_STATUS: '@ranking/REQUEST_STATUS',
  REQUEST_SUCCES_RANKING: '@ranking/REQUEST_SUCCES_RANKING',
  REQUEST_SUCCES_STATUS: '@ranking/REQUEST_SUCCES_STATUS',
  REQUEST_RANKINGTOP10: '@ranking/REQUESTRANKINGTOP10',
  REQUEST_SUCCES_RANKINGTOP10: '@ranking/REQUEST_SUCCES_RANKINGTOP10',
  REQUEST_POSITION: '@ranking/REQUEST_POSITION',
  REQUEST_SUCCESS_POSITION: '@ranking/REQUEST_SUCCESS_POSITION',
  RESET_DATE: '@ranking/RESET_DATE',
  REQUEST_REJECT_RANKING: '@ranking/REQUEST_REJECT_RANKING',
  REQUEST_REJECT_STATUS: '@ranking/REQUEST_REJECT_RANKING',
  REQUEST_REJECT_RANKINGTOP10: '@ranking/REQUEST_REJECT_RANKINGTOP10',
  REQUEST_REJECT_POSITION: '@ranking/REQUEST_REJECT_POSITION',
  REQUEST_CLEAR_STATUS: '@ranking/REQUEST_CLEAR_STATUS',
  REQUEST_STATUS_CASINO: '@ranking/REQUEST_STATUS_CASINO',
  REQUEST_STATUS_SPORTBOOK: '@ranking/REQUEST_STATUS_SPORTBOOK',
  REQUEST_STATUS_DEPOSIT: '@ranking/REQUEST_STATUS_DEPOSIT',
}

export function resetDateLB() {
  return { type: rankingActionTypes.RESET_DATE }
}
export function requestRejectRanking() {
  return { type: rankingActionTypes.REQUEST_REJECT_RANKING }
}
export function requestRejectStatus() {
  return { type: rankingActionTypes.REQUEST_REJECT_STATUS }
}

export function requestRejectRankingTop10() {
  return { type: rankingActionTypes.REQUEST_REJECT_RANKINGTOP10 }
}

export function requestRejectPosition() {
  return { type: rankingActionTypes.REQUEST_REJECT_POSITION }
}

export function requestPosition(payload) {
  return { type: rankingActionTypes.REQUEST_POSITION, payload }
}

export function clearStatus() {
  return { type: rankingActionTypes.REQUEST_CLEAR_STATUS }
}

export function requestSuccessPosition(payload) {
  return { type: rankingActionTypes.REQUEST_SUCCESS_POSITION, payload }
}

export function requestRanking(payload) {
  return { type: rankingActionTypes.REQUEST_RANKING, payload }
}
export function requestStatus(payload) {
  return { type: rankingActionTypes.REQUEST_STATUS, payload }
}
export function requestStatusCasino(payload) {
  return { type: rankingActionTypes.REQUEST_STATUS_CASINO, payload }
}
export function requestStatusSportbook(payload) {
  return { type: rankingActionTypes.REQUEST_STATUS_SPORTBOOK, payload }
}
export function requestStatusDeposit(payload) {
  return { type: rankingActionTypes.REQUEST_STATUS_DEPOSIT, payload }
}
export function requestSuccessRanking(payload) {
  return { type: rankingActionTypes.REQUEST_SUCCES_RANKING, payload }
}

export function requestSuccessStatus(payload) {
  return { type: rankingActionTypes.REQUEST_SUCCES_STATUS, payload }
}

export function requestRankingTop10(payload) {
  return { type: rankingActionTypes.REQUEST_RANKINGTOP10, payload }
}

export function requestSuccessRankingTop10(payload) {
  return { type: rankingActionTypes.REQUEST_SUCCES_RANKINGTOP10, payload }
}
