export class DTOVerifyFraud {
  readonly email: string;
  readonly ip: string;
  readonly phone: number;
  readonly session_id: string

  constructor(
    params: {
      email: string,
      ip: string,
      phone: number,
      session_id: string
    }
  ){
    this.email = params.email;
    this.ip = params.ip;
    this.phone = params.phone;
    this.session_id = params.session_id;
  }
}
