import { OrdersActionTypes } from "../constants/depositConfirm"


//Get One
export function getOneOrderRequest (payload) {
  return { type: OrdersActionTypes.GETONEORDERREQUEST, payload }
}

export function getOneOrderSuccess (payload) {
  return { type: OrdersActionTypes.GETONEORDERSUCCESS, payload }
}
//Patch
export function patchDepositDelivery (payload) {
  return { type: OrdersActionTypes.PATCHDEPOSITDELIVERYREQUEST, payload }
}

export function patchDepositDeliverySuccess () {
  return { type: OrdersActionTypes.PATCHDEPOSITDELIVERYSUCCESS }
}
//COMMON
export function depositFailed(error) {
  return { type: OrdersActionTypes.DEPOSITFAILED, payload: error }
}

export function resetErrorsDeposit() {
    return { type: OrdersActionTypes.RESETERRORSDEPOSIT }
}

export function resetDeposit () {
  return { type: OrdersActionTypes.RESETDEPOSIT }
}
