import {
  TombolaActionTypes
} from '@clean/application/redux/tombola/constant';

export function getStatusPlayer (payload) {
  return { type: TombolaActionTypes.REQUEST_GET_STATUS_PLAYER, payload }
}


export function getStatusPlayerSuccess (payload) {
  return { type: TombolaActionTypes.SUCCESS_GET_STATUS_PLAYER, payload }
}

export function getStatusPlayerRejected () {
  return { type: TombolaActionTypes.REJECTED_GET_STATUS_PLAYER }
}
