import { put } from 'redux-saga/effects';
import { getStrapiLeaderboardRejected, getStrapiLeaderboardSuccess } from '@clean/application/redux/strapi/actions';
import StrapiRepositoryImpl from '@clean/infrastructure/repositories/StrapiRepositoryImpl';
import StrapiUseCase from '@clean/domain/useCase/strapi/strapiUseCase';
const StrapiRepo = new StrapiRepositoryImpl();

export function* getStrapiLeaderboard(action) {
  const strapiService = new StrapiUseCase(StrapiRepo);
  const { data: strapiResult } = yield strapiService.getStrapiLeaderboard(
    action.payload.country, action.payload.url);

  if (!strapiResult?.responseObj || strapiResult?.responseCode != 200) {
    return yield put(getStrapiLeaderboardRejected());
  }

  if (strapiResult.responseObj) {
    const payload = {
      data: strapiResult.responseObj
    }
    yield put(getStrapiLeaderboardSuccess(payload));
  } else {
    yield put(getStrapiLeaderboardRejected());
  }
}

