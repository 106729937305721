import produce from 'immer';
import { casinoActionTypes } from '../constants/casino';

const casinoInitialState = {
  searchGame: '',
  producerSelected: [],
  page: 1,
  filterOffset: 0,
  filterLimit: 24,
  loadingInitialGame: false,
  tabSelected: 'rating',
  loadingMoreGame: false,
  allGameShowReady: false,
  listGames: [],
  searchListGames: [],
  errosCasino: [],
  isMobile: false
}

const reducer = (state = casinoInitialState, action) => {
  switch (action.type) {
    //Si se detecta que el usuario esta en mobile
    case casinoActionTypes.CHANGE_IS_MOBILE:
      return produce(state, draft => {
        draft.isMobile = action.payload
      });
    case casinoActionTypes.CHANGE_TAB:
      return produce(state, draft => {
        draft.searchGame = ""
        draft.page = 1
        draft.filterLimit = 24
        draft.producerSelected = []
        draft.tabSelected = action.payload
      });
    case casinoActionTypes.CHANGE_SEARCH_INPUT:
      return produce(state, draft => {
        draft.searchGame = action.payload
        draft.page = 1
        draft.filterLimit = 24
      });
    case casinoActionTypes.ALL_GAME_SHOW:
        return produce(state, draft => {
          draft.allGameShowReady = action.payload
        });
    case casinoActionTypes.CHANGE_PRODUCER:
      return produce(state, draft => {
        draft.producerSelected = action.payload
        draft.searchGame = ""
        draft.page = 1
        draft.filterLimit = 24
      });
    case casinoActionTypes.CHANGE_FILTER_LIMIT:
      return produce(state, draft => {
        draft.filterLimit = action.payload
      });
    case casinoActionTypes.CHANGE_PAGE:
      return produce(state, draft => {
        draft.page = action.payload
      });
    case casinoActionTypes.SEARCH_GAME_REQUEST:
      return produce(state, draft => {
        draft.loadingInitialGame = true
      });
    case casinoActionTypes.SEARCH_GAME_SUCCESS:
      return produce(state, draft => {
        draft.loadingInitialGame = false
        draft.listGames = action.games
        draft.loadingMoreGame = false
      });
    case casinoActionTypes.SEARCH_GAME_FAILED:
      return produce(state, draft => {
        draft.loadingInitialGame = false
        draft.loadingMoreGame = false
        draft.errosCasino.push(action.payload)
      });
    case casinoActionTypes.LOAD_MORE_GAME:
      return produce(state, draft => {
        draft.page = action.payload.page
        draft.filterLimit = action.payload.limit
        draft.loadingMoreGame = true
      });
    case casinoActionTypes.CASINORESETERROR:
      return produce(state, draft => {
        draft.errorsLogin = []
      })
    case casinoActionTypes.CASINORESETINPUTSEARCH:
      return produce(state, draft => {
        draft.searchGame = ''
      })
    case casinoActionTypes.CASINORESET:
      return casinoInitialState;
    default:
      return state
  }
}

export default reducer
