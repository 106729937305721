import { put, select } from 'redux-saga/effects';
import PromotionsUseCase from '@clean/domain/useCase/promotions/promotionsUseCase';
import PromotionsRepositoryImpl from '@clean/infrastructure/repositories/PromotionsRepositoryImpl';
import { getPromotionCustomerRequest, viewPromotionCustomerSuccess, viewPromotionCustomerRejected } from '../actions/index';
import { closeModalPromotions } from '@clean/application/redux/modal/actions/modals';
const PromotionRepo = new PromotionsRepositoryImpl();

export function* viewPromotionsCustomer(action) {
  const { customerId, promotionId } = action.payload;
  const promotionService = new PromotionsUseCase(PromotionRepo);
  const resultPromotion = yield promotionService.viewPromotionCustomer(customerId, promotionId);
  if (resultPromotion.response === 'ok') {
    yield put(viewPromotionCustomerSuccess(customerId));
  } else {
    yield put(viewPromotionCustomerRejected());
  }
  yield put(viewPromotionCustomerRejected());
}

export function* viewPromotionsCustomerSuccess(action) {
  yield put((closeModalPromotions()))
  yield put(getPromotionCustomerRequest(action.payload))

}
