// reducers/colorReducer.js
import {
    REQUEST_COLORS,
    SUCESS_REQUEST_COLOR,
    FAILED_REQUEST_COLOR,
    SET_LAST_FETCH
} from '@clean/application/redux/strapi/actions/colorConfig';

const initialState = {
    currentColors: null,
    loadingGetColors: false,
    lastFetch: null,
};

const colorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCESS_REQUEST_COLOR:
            return {
                ...state,
                currentColors: action.payload,
                loadingGetColors: false
            };
        case REQUEST_COLORS:
            return {
                ...state,
                loadingGetColors: true,
            };
        case FAILED_REQUEST_COLOR:
            return {
                ...state,
                loadingGetColors: false,
            };
        case SET_LAST_FETCH:
            return {
                ...state,
                lastFetch: action.payload,
            };
        default:
            return state;
    }
};

export default colorReducer;
