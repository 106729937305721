import { bonusCodePromotionalActionsTypes } from "../constants/bonusCodePromotional"

//Post
export function addBonusCodePromotionalRequest (payload) {
  return { type: bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_REQUEST, payload }
}

export function addBonusCodePromotionalSuccess (payload) {
  return { type: bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_SUCCESS, payload }
}

//COMMON
export function bonusCodePromotionalFailed(error) {
  return { type: bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_FAILED, payload: error }
}

export function resetErrorsBonusCodePromotional() {
    return { type: bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_RESETERRORS }
}

export function resetBonusCodePromotional () {
  return { type: bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_RESET }
}



