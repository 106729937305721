import { fpActionTypes } from "../constants/fp"

export function getFullFP() {
  return { type: fpActionTypes.GETFULLFP }
}
export function setVisitor(data) {
  return { type: fpActionTypes.SETVISITOR, payload: data }
}
export function revalidateCountry(payload) {
  return { type: fpActionTypes.REVALIDATECOUNTRY, payload }
}
export function updatedDocumentType(documentType) {
  return { type: fpActionTypes.DOCUMENT_TYPE, payload: documentType }
}
export function updatedCountryDetected(country) {
  return { type: fpActionTypes.COUNTRYDETECTED, payload: country }
}
export function updateRefreshTokenDigitain (tokens){
  return { type: fpActionTypes.UPDATEDHASHFINGER, payload: tokens }
}
export function updatedIp(ipUser) {
  return { type: fpActionTypes.UPDATEDIP, payload: ipUser }
}
export function updatedCity(cityUser) {
  return { type: fpActionTypes.UPDATEDCITY, payload: cityUser }
}
export function updatedHashFinger (hash){
  return { type: fpActionTypes.UPDATEDHASHFINGER, payload: hash }
}
export function updatedBlockCountry(payload) {
  return { type: fpActionTypes.VERIFICATION_BLOCK_COUNTRY_SUCCESS, payload }
}
export function broserNotAllowed(){
  return { type: fpActionTypes.BROWSER_NOT_ALLOWED}
}
export function resetBroserNotAllowed(){
  return { type: fpActionTypes.RESET_BROWSER_NOT_ALLOWED}
}
