export const responsibleGamblingActionTypes = {
  REQUEST_RESPOSIBLE_GAMBLING: '@responsibleGambling/REQUEST_RESPOSIBLE_GAMBLING',
  REQUEST_SUCCESS_RESPOSIBLE_GAMBLING: '@responsibleGambling/REQUEST_SUCCESS_RESPOSIBLE_GAMBLING',
  REQUEST_REJECT_RESPOSIBLE_GAMBLING: '@responsibleGambling/REQUEST_REJECT_RESPOSIBLE_GAMBLING'
}

export function requestResponsibleGamblingConfig() {
  return { type: responsibleGamblingActionTypes.REQUEST_RESPOSIBLE_GAMBLING }
}
export function requestRejectResponsibleGamblingConfig() {
  return { type: responsibleGamblingActionTypes.REQUEST_REJECT_RESPOSIBLE_GAMBLING }
}
export function requestSuccessResponsibleGamblingConfig(payload) {
  return { type: responsibleGamblingActionTypes.REQUEST_SUCCESS_RESPOSIBLE_GAMBLING, payload }
}