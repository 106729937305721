import {TombolaRepository } from '@clean/domain/repositories/tombolaRepository/TombolaRepository';
import { DTOResponseVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoResponse';
import { DTOVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoRequest';
import axios from 'axios';

export default class TombolaRepositoryImpl implements TombolaRepository {

  async GetStatusPlayer(
    CustomerId: string
    ): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: verifyResult } = await axios.post(
          `/api/v2/tombola/getStatusPlayer`,
          {
            CustomerId
          }
        );
        resolver(verifyResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
