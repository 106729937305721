import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  requestRejectSelfExclusion,
  requestRejectSelfExclusionStatus,
  requestSuccessSelfExclusion,
  requestSuccessSelfExclusionStatus,
} from '@clean/application/redux/selfExclusion/actions/selfExclusion';
import axios from 'axios';
import { selfExclusionActionTypes } from '@clean/application/redux/selfExclusion/actions/selfExclusion';
import { forceLogoutRequest, loginFailed, logoutRequest } from '@clean/application/redux/auth/actions/auth';
import { getLoginMessage } from '../../auth/statics';

function* GetSelfStatusCustomer() {
  try {
    let result = yield axios.post(`/api/v2/apiSelfExclusion/GetSelfExclusion`);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* PostSelfExclusion(data) {
  try {
    let result = yield axios.post(`/api/v2/apiSelfExclusion/PostSelfExclusion`, data);
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}


function* handleGetSelfExclusionStatus() {
  try {
    let Result = yield call(GetSelfStatusCustomer)
    if (Result !== null) {
      yield put(requestSuccessSelfExclusionStatus(Result))
    } else {
      yield put(requestRejectSelfExclusionStatus())
    }

  } catch (e) {
    console.log(e)
  }
}

function* handlePostSelfExclusion({ payload }) {
  try {
    let Result = yield call(PostSelfExclusion, payload)
    if (Result !== null) {
      yield put(requestSuccessSelfExclusion(Result))
      //yield put(logoutRequest())
      //yield put(forceLogoutRequest())
    } else {
      yield put(loginFailed(getLoginMessage({
        code: 301
      })))
      yield put(requestRejectSelfExclusion())
    }
  } catch (e) {
    console.log(e)
  }
}

export default all([
  takeLatest(selfExclusionActionTypes.REQUEST_SELF_EXCLUSION, handlePostSelfExclusion),
  takeLatest(selfExclusionActionTypes.REQUEST_SELF_EXCLUSION_STATUS, handleGetSelfExclusionStatus),
]);
