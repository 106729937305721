import produce from 'immer';
import { liveCasinoActionTypes } from '../constants/liveCasino';

const liveCasinoInitialState = {
  searchGame: '',
  producerSelected: [],
  page: 1,
  filterOffset: 0,
  filterLimit: 24,
  loadingInitialGame: false,
  tabSelected: 'rating',
  loadingMoreGame: false,
  allGameShowReady: false,
  listGames: [],
  searchListGame: [],
  listGameForCategory: [],
  loadingGameForCategory: false,
  errosCasino: [],
  isMobile: false
}

const reducer = (state = liveCasinoInitialState, action) => {
  switch (action.type) {
    case liveCasinoActionTypes.CHANGE_IS_MOBILE:
      return produce(state, draft => {
        draft.isMobile = action.payload
      });
    case liveCasinoActionTypes.CHANGE_TAB:
      return produce(state, draft => {
        draft.searchGame = ""
        draft.page = 1
        draft.filterLimit = 24
        draft.producerSelected = []
        draft.tabSelected = action.payload
      });
    case liveCasinoActionTypes.CHANGE_SEARCH_INPUT:
      return produce(state, draft => {
        draft.searchGame = action.payload
        draft.page = 1
        draft.filterLimit = 24
      });
    case liveCasinoActionTypes.ALL_GAME_SHOW:
        return produce(state, draft => {
          draft.allGameShowReady = action.payload
        });
    case liveCasinoActionTypes.CHANGE_PRODUCER:
      return produce(state, draft => {
        draft.producerSelected = action.payload
        draft.searchGame = ""
        draft.page = 1
        draft.filterLimit = 24
      });
    case liveCasinoActionTypes.CHANGE_FILTER_LIMIT:
      return produce(state, draft => {
        draft.filterLimit = action.payload
      });
    case liveCasinoActionTypes.CHANGE_PAGE:
      return produce(state, draft => {
        draft.page = action.payload
      });

    case liveCasinoActionTypes.GET_HOME_LIVECASINO_GAME:
      return produce(state, draft => {
        draft.loadingGameForCategory = true
      });
    case liveCasinoActionTypes.GET_HOME_LIVECASINO_GAME_SUCCESS:
      return produce(state, draft => {
        draft.loadingInitialGame = false
        draft.loadingGameForCategory = false
        draft.listGameForCategory = action.games
        draft.loadingMoreGame = false
      });

      case liveCasinoActionTypes.SEARCH_GAME_REQUEST:
      return produce(state, draft => {
        draft.loadingInitialGame = true
      });
    case liveCasinoActionTypes.SEARCH_GAME_SUCCESS:
      return produce(state, draft => {
        draft.loadingInitialGame = false
        draft.listGames = state.searchGame !== '' ? draft.listGames : action.games;
        draft.searchListGame = action.games
        draft.loadingMoreGame = false
      });

    case liveCasinoActionTypes.SEARCH_GAME_FAILED:
      return produce(state, draft => {
        draft.loadingInitialGame = false
        draft.loadingMoreGame = false
        draft.errosCasino.push(action.payload)
      });

    case liveCasinoActionTypes.LOAD_MORE_GAME:
      return produce(state, draft => {
        draft.page = action.payload.page
        draft.filterLimit = action.payload.limit
        draft.loadingMoreGame = true
      });

    case liveCasinoActionTypes.CASINORESETERROR:
      return produce(state, draft => {
        draft.errorsLogin = []
      })

    case liveCasinoActionTypes.CASINORESET:
      return liveCasinoInitialState;
    default:
      return state
  }
}

export default reducer
