export const redirectActionTypes = {
  REDIRECT: 'redirect/REDIRECT',
  CHANGECURRENT: 'redirect/CHANGECURRENT',
  CHANGEPENDINGREDIRECT: 'redirect/CHANGEPENDINGREDIRECT'
}


export function changeCurrentPath(path) {
  return { type: redirectActionTypes.CHANGECURRENT, payload: path }
}

export function changePendingRedirect(path) {
  return { type: redirectActionTypes.CHANGEPENDINGREDIRECT, payload: path }
}

export function resetRedirect() {
  return { type: redirectActionTypes.REDIRECT }
}
