import produce from 'immer';
import { LayoutActionTypes } from '../constants/layout';

const LayoutInitialState = {
  loadLayout: true,
  languages: [],
  menuNavbar: [],
  menuFooter: [],
  socialMedia: [],
  providers: [],
  countryCallingCodes: [],
  currencies: [],
  supports: [],
  contents: [],
  onlyWithInvitation: false,
  countryAvailable: [],
  currentPath: ''
}

const reducer = (state = LayoutInitialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_CURRENTPATH:
      return produce(state, draft => {
        draft.currentPath = action.payload
      });
    case LayoutActionTypes.LAYOUT_LOAD:
      return produce(state, draft => {
        draft.loadLayout = true
      });
    case LayoutActionTypes.LAYOUT_READY:
      return produce(state, draft => {
        draft.loadLayout = false
      });
    case LayoutActionTypes.UPDATED_INFO_LAYOUT:
      return produce(state, draft => {
        draft.loadLayout = false
        draft.languages = action.payload.languages,
        draft.menuNavbar = action.payload.menuNavbar,
        draft.menuFooter = action.payload.menuFooter,
        draft.socialMedia = action.payload.socialMedia,
        draft.providers = action.payload.providers,
        draft.countryCallingCodes = action.payload.countryCallingCodes,
        draft.currencies = action.payload.currencies,
        draft.supports = action.payload.supports,
        draft.contents = action.payload.contents,
        draft.onlyWithInvitation = action.payload.onlyWithInvitation
      });
    case LayoutActionTypes.UPDATED_COUNTRYAVAILABLE:
      return produce(state, draft => {
        draft.countryAvailable = action.payload
      });
    default:
      return state
  }
}

export default reducer
