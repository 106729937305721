import { AclTruePlayActionTypes } from '@clean/application/redux/aclTruePlay/constant';
import produce from 'immer';

const truePlayInitialState = {
  loadingGetUrlTruePlay: false,
  urlTruePlay: '',
  loadingGetBalanceTruePlay: false,
  balanceTokens: 0
}

const reducer = (state = truePlayInitialState, action) => {
  switch (action.type) {
    case AclTruePlayActionTypes.REQUEST_GET_URL_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetUrlTruePlay = true;
      });
    case AclTruePlayActionTypes.SUCCESS_GET_URL_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetUrlTruePlay = false;
        draft.urlTruePlay = action.payload;
      });
    case AclTruePlayActionTypes.REJECTED_GET_URL_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetUrlTruePlay = false;
        draft.urlTruePlay = '';
      });
    case AclTruePlayActionTypes.REQUEST_GET_BALANCE_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetBalanceTruePlay = true;
      });
    case AclTruePlayActionTypes.SUCCESS_GET_BALANCE_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetBalanceTruePlay = false;
        draft.balanceTokens = action.payload;
      });
    case AclTruePlayActionTypes.REJECTED_GET_BALANCE_TRUE_PLAY:
      return produce(state, draft => {
        draft.loadingGetBalanceTruePlay = false;
        draft.balanceTokens = 0;
      });
    default:
      return state
  }
}

export default reducer
