import type { DataError } from '@clean/domain/entities/dataError';
import { Either } from '@clean/domain/entities/either';
import type { DepositRepository } from '@clean/domain/repositories/depositRepository';
import axios from 'axios';
import { PaginationQueryDto } from '@clean/domain/dtos/deposit/paginationQuery.dto';
import { DepositDto } from  '@clean/domain/dtos/deposit/deposit.dto';
import { DepositDetailsDto } from '@clean/domain/dtos/deposit/depositDetails.dto';
import { axiosApiDeposit } from '../http';

class DepositRepositoryImpl implements DepositRepository {

  async HaveDeposit(): Promise<Either<DataError, any>> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: depositResult }  = await axios.get(
          `/api/v2/deposits/HaveDeposit`
        );
        resolver(Either.right(depositResult?.data?.responseObj?.haveDeposit));
      } catch (error) {
        resolver(Either.left({ kind: 'UnexpectedError', error }));
      }
    });
  }
}

export default DepositRepositoryImpl;
