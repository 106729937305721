import {
  LeaderboardActionTypes
} from '@clean/application/redux/leaderboard/constant';

export function getStatusPlayer (payload) {
  return { type: LeaderboardActionTypes.REQUEST_GET_STATUS_PLAYER, payload }
}

export function getStatusPlayerSuccess (payload) {
  return { type: LeaderboardActionTypes.SUCCESS_GET_STATUS_PLAYER, payload }
}

export function getStatusPlayerRejected () {
  return { type: LeaderboardActionTypes.REJECTED_GET_STATUS_PLAYER }
}
