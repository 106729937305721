import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { getMenuFooterSuccess } from '../actions/footer';
import axios from 'axios';
import { FooterActionsTypes } from '../constants/footer';

function* GetContents (payload)  {
  try{
    const { countryDetected } = payload;
    const { data:response } = yield axios.get(`/api/v2/apiConfig/GetContents?cy=${countryDetected}`)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return null
    }
  }catch(err){
    return null
  }
}

function* GetMenuFooter (payload)  {
  try{
    const { countryDetected } = payload;
    const { data:response } = yield axios.get(`/api/v2/apiConfig/GetMenuFooter?cy=${countryDetected}`)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return null
    }
  }catch(err){
    return null
  }
}

function* GetSocialMedia ()  {
  try{
    const { data:response } = yield axios.get(`/api/v2/apiConfig/GetSocialMediaFooter`)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return null
    }
  }catch(err){
    return null
  }
}

function* GetProviders ()  {
  try{
    const { data:response } = yield axios.get(`/api/v2/apiConfig/GetProvidersFooter`)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return null
    }
  }catch(err){
    return null
  }
}

function* handleGetContent({payload}) {

  try {
	  const countryDetected = yield select((state) => state.fp.countryDetected)

	  const Contents = yield call(GetContents,{ countryDetected });

	  const SocialMedia = yield call(GetSocialMedia);

	  const MenuFooter = yield call(GetMenuFooter,{ countryDetected });

	  const Providers = yield call(GetProviders);

	  yield put(getMenuFooterSuccess(
	    {
	      menuFooter: MenuFooter !== null ? MenuFooter.menuFooter : [],
	      contents: Contents !== null ? Contents.contents: [],
	      socialMedia: SocialMedia !== null ? SocialMedia.socialMedia : [],
	      providersFooter: Providers !== null ? Providers.providerFooter : [],
	      countryUsed: countryDetected
	    }
	  ))
  } catch (e) {
    console.log(e)
  }

}


export default all([
  takeLatest(FooterActionsTypes.GET_MENU_FOOTER, handleGetContent)
]);
