import produce from 'immer';
import { bonusActionsTypes } from '../constants/bonus';

const bonusInitialState = {
  detailBonoCashback: '',
  historyCashback: [],
  loadingHistoryCashback: false,
  processHistory: false,
  loadingBonoCashback: false,
  errorBonos: [],
  detailBonoStrapi: null
}

const reducer = (state = bonusInitialState, action) => {
  switch (action.type) {
    case bonusActionsTypes.BONOCASHBACK_REQUEST:
      return produce(state, draft => {
        draft.loadingBonoCashback = true
      });
    case bonusActionsTypes.BONOCASHBACK_SUCCESS:
      return produce(state, draft => {
        draft.loadingBonoCashback = false
        draft.detailBonoCashback = action.bono.detail
      });
    case bonusActionsTypes.BONOCASHBACKHISTORY_REQUEST:
      return produce(state, draft => {
        draft.loadingHistoryCashback = true
        draft.processHistory = false
      });
    case bonusActionsTypes.BONOCASHBACKHISTORY_SUCCESS:
      return produce(state, draft => {
        draft.loadingHistoryCashback = false
        draft.historyCashback = action.payload
        draft.processHistory = true
      });
    case bonusActionsTypes.BONOCASHBACK_FAILED:
      return produce(state, draft => {
        draft.loadingBonoCashback = false
        draft.errorBonos.push(action.payload)
      });
    case bonusActionsTypes.SAVE_STRAPI_BONUS:
      return produce(state, draft => {
        draft.detailBonoStrapi = action.detailBono
      })
    default:
      return state
  }
}

export default reducer
