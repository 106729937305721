export const RegisterActionTypes = {
  REGISTERREQUEST: '@register/REGISTERREQUEST',
  REGISTERRESETFORM: '@register/REGISTERRESETFORM',
  REGISTERSUCCESS: '@register/REGISTERSUCCESS',
  REGISTERFAILED: '@register/REGISTERFAILED',
  REGISTERRESETERROR: '@register/REGISTERRESETERROR',
  CHANGENEEDFRAUDVALIDATION: '@register/CHANGENEEDFRAUDVALIDATION',
};

export function registerResetForm() {
  return { type: RegisterActionTypes.REGISTERRESETFORM };
}

export function changeNeedFraudValidation(payload){
  return { type: RegisterActionTypes.CHANGENEEDFRAUDVALIDATION, payload };
}

export function registerRequest(payloadRegister) {
  return { type: RegisterActionTypes.REGISTERREQUEST, payloadRegister };
}

export function registerSuccess() {
  return { type: RegisterActionTypes.REGISTERSUCCESS };
}

export function registerFailed(error) {
  return { type: RegisterActionTypes.REGISTERFAILED, payload: error };
}

export function registerResetErrors() {
  return { type: RegisterActionTypes.REGISTERRESETERROR };
}
