import {
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_REQUEST,
  GET_LEADERBOARD_REJECTED
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiLeaderboardRequest (payload) {
  return { type: GET_LEADERBOARD_REQUEST, payload }
}
export function getStrapiLeaderboardSuccess (payload) {
  return { type: GET_LEADERBOARD_SUCCESS, payload }
}
export function getStrapiLeaderboardRejected () {
  return { type: GET_LEADERBOARD_REJECTED }
}
