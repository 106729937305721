import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { searchGameFavoriteSuccess } from '@clean/application/redux/games/actions/preferences';
import CloneObj from '@utils/CloneObj'
import * as Sentry from "@sentry/nextjs";
import { preferencesActionTypes } from '../constants/preferences';

function* handleSearchGameFavorite() {
  try{
    const searchGame = yield select((state) => state.casino.searchGame)
    const favoriteGame = yield select((state) => state.preferences.favoriteGame)
    let cloneFavorite = CloneObj(favoriteGame)
    let filterFavoriteGame = cloneFavorite.filter((game)=>{
      return game.game.title.toLowerCase().includes(searchGame.toLowerCase())
    })
    yield put(searchGameFavoriteSuccess(filterFavoriteGame))
  }catch(e){
    Sentry.captureException(e);
    console.log(e)
  }
}

export default all([
  takeLatest(preferencesActionTypes.SEARCHGAMEREQUESTFAVORITE, handleSearchGameFavorite)
]);
