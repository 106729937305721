import type { DataError } from '@clean/domain/entities/dataError';
import { Either } from '@clean/domain/entities/either';
import type { locationWorkerRepository } from '@clean/domain/repositories/locationWorkerRepository';

class LocationWorkerImpl implements locationWorkerRepository {
  async GetLocationByWorker(): Promise<Either<DataError, any>> {
    return new Promise(async (resolver, _reject) => {
      try {
        const locationResult = await fetch(`https://ip.juegalo.dev`);
        if (!locationResult.ok) {
            throw new Error('HTTP error ' + locationResult.status);
        }
        const location = await locationResult.json();
        resolver(Either.right(location));
      } catch (error) {
        resolver(Either.left({ kind: 'UnexpectedError', error }));
      }
    });
  }
}

export default LocationWorkerImpl;
