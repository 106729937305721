import { initialState } from '@clean/application/redux/modal/initialStateModal';
import {
  ModalActionTypes,
  MODAL_TYPES
} from '@clean/application/redux/modal/constant';
import produce from 'immer';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return produce(state, draft => {
        draft.isOpen = true;
        draft.modalType = MODAL_TYPES[action.payload];
        draft.modalsView.popUpMarketing = draft.modalsView.popUpMarketing !== true ? (MODAL_TYPES[action.payload] === MODAL_TYPES.POPUP_MARKETING_PE ? true : false) : draft.modalsView.popUpMarketing;
        draft.modalsView.popUpMarketingCL = draft.modalsView.popUpMarketingCL !== true ? (MODAL_TYPES[action.payload] === MODAL_TYPES.POPUP_MARKETING_CL ? true : false) : draft.modalsView.popUpMarketingCL;
        draft.modalsView.popUpSessionReminder = draft.modalsView.popUpSessionReminder !== true ? (MODAL_TYPES[action.payload] === MODAL_TYPES.POPUP_SESSION_REMINDER ? true : false) : draft.modalsView.popUpSessionReminder;
      });
    case ModalActionTypes.HIDE_MODAL:
      return produce(state, draft => {
        draft.isOpen = initialState.isOpen;
        draft.modalType = initialState.modalType;
        draft.modalProps = initialState.modalProps;
      });
    case ModalActionTypes.RESET_MODAL:
      return produce(state, draft => {
        draft.isOpen = initialState.isOpen;
        draft.modalType = initialState.modalType;
        draft.modalProps = initialState.modalProps;
        draft.modalsView = initialState.modalsView;
      });
    default:
      return state
  }
}

export default reducer
