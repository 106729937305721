import produce from 'immer';
import {
  GETPROMOTIONS_CUSTOMER_REQUEST,
  GETPROMOTIONS_CUSTOMER_SUCCESS,
  GETPROMOTIONS_CUSTOMER_REJECTED,
  VIEWPROMOTIONS_CUSTOMER_REQUEST,
  VIEWPROMOTIONS_CUSTOMER_SUCCESS,
  VIEWPROMOTIONS_CUSTOMER_REJECTED,
  CLEAR_PROMOTIONS
} from '@clean/application/redux/promotions/constants/actionsTypes';

const INIT_STATE = {
  loadingGetPromotions: false,
  loadingViewPromotions: false,
  allPromotions: [],
  promotionActive: {
    id: null,
    title: '',
    description: '',
    startDate: null,
    endDate: null,
    images: [],
    trigger: '',
    status: '',
  }
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GETPROMOTIONS_CUSTOMER_REQUEST:
      return produce(state, (draft) => {
        draft.loadingGetPromotions = true;
        draft.allPromotions = action.payload;
      });
    case GETPROMOTIONS_CUSTOMER_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingGetPromotions = false;
        draft.allPromotions = action.payload.allPromotions;
        draft.promotionActive = action.payload.promotionActive
      });
    case CLEAR_PROMOTIONS:
      return produce(state, (draft) => {
        draft.allPromotions = [];
        draft.promotionActive = {
          id: null,
          title: '',
          description: '',
          startDate: null,
          endDate: null,
          images: [],
          trigger: '',
          status: ''
        }
      });
    case GETPROMOTIONS_CUSTOMER_REJECTED:
      return produce(state, (draft) => {
        draft.loadingGetPromotions = false;
        draft.promotionActive = {
          id: null,
          title: '',
          description: '',
          startDate: null,
          endDate: null,
          images: [],
          trigger: '',
          status: ''
        }
      });
    case VIEWPROMOTIONS_CUSTOMER_REQUEST:
      return produce(state, (draft) => {
        draft.loadingViewPromotions = true;
      });
    case VIEWPROMOTIONS_CUSTOMER_SUCCESS:
      return produce(state, (draft) => {
        draft.loadingViewPromotions = false;
        draft.promotionActive = {
          id: null,
          title: '',
          description: '',
          startDate: null,
          endDate: null,
          images: [],
          trigger: '',
          status: ''
        }
      });
    case VIEWPROMOTIONS_CUSTOMER_REJECTED:
      return produce(state, (draft) => {
        draft.loadingViewPromotions = false;
      });
    default:
      return state
  }
};

export default reducer;
