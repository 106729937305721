import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  updatedIp,
  updatedCity,
  updatedHashFinger,
  updatedCountryDetected,
  updatedDocumentType
} from '@clean/application/redux/fp/actions/fp';
import { isAfter, addDays } from 'date-fns'
import { changePendingRedirect } from '@clean/application/redux/redirect/actions/redirect';
import FPUseCase from '@clean/domain/useCase/fpUseCase';
import FPRepositoryImpl from '@clean/infrastructure/repositories/fpRepositoryImpl'
import LocationWorkerUseCase from '@clean/domain/useCase/locationWorkerUseCase';
import LocationWorkerRepositoryImpl from '@clean/infrastructure/repositories/locationWorkerRepositoryImpl';
import * as Sentry from "@sentry/nextjs";
import { COUNTRIES_LIST } from '../constants/countries';

const fpRepo = new FPRepositoryImpl();
const fpService = new FPUseCase(fpRepo);
const locationWorkerRepo = new LocationWorkerRepositoryImpl();
const locationWorkerService = new LocationWorkerUseCase(locationWorkerRepo);

const COUNTRY_DEFAULT = process.env.NEXT_PUBLIC_NAME_COMMERCE === 'Betnroll.net' ? 'pe' : 'cl';
const EXCLUDED_PATHS = [
  "/ganadores-sorteoaniversario",
  "/paymentFail",
  "/change-password",
  "/payOk",
  "/verifyFinish"
];

function isExcludedPath(pathname: string) {
  return EXCLUDED_PATHS.some(path => pathname.startsWith(path));
}

function* getFingerPrintPro() {
  try {
    const hashFinger = yield select((state) => state.fp.hashFinger)
    const createdFinger = yield select((state) => state.fp.createdFinger)
    if (window !== undefined) {
      const fp = yield window.fpPromise;
      const result = yield fp.get({
        extendedResult: true
      });
      if (createdFinger !== null) {
        var limitDateFP = addDays(createdFinger, 1)
      }
      if (hashFinger !== result.visitorId) {
        const fingerResult: any = yield fpService.GetFullData(
          result.visitorId
        );
        return fingerResult
      } else {
        if (hashFinger === result.visitorId) {
          if (createdFinger !== null && isAfter(new Date(), limitDateFP)
          ) {
            const fingerResult: any = yield fpService.GetFullData(
              result.visitorId
            );
            return fingerResult;
          }
        }
      }
    }
  } catch (e) {
    console.error("getFingerPrintPro error", e)
    Sentry.captureException(e);
  }
}

function* revalidateCountry({ payload }) {
  try {
    const countryAccess = payload?.query?.country?.toLowerCase();
    if (countryAccess) {
      const userLogged = yield select((state) => state.auth.userLogged);
      const countryDetected = yield select((state) => state.fp.countryDetected.toLowerCase());
      // Verificar si el país al que se intenta acceder está permitido
      const isCountryAvailable = COUNTRIES_LIST.some(c => c.code.toLowerCase() === countryAccess);
      if (!isCountryAvailable) {
        // Si el país al que se intenta acceder no está permitido, redirigir al país detectado o al país por defecto
        const redirectCountry = COUNTRIES_LIST.find(c => c.code.toLowerCase() === countryDetected) ? countryDetected : COUNTRY_DEFAULT;
        yield put(changePendingRedirect({
          redirectTo: `/${redirectCountry}`,
          credentials: false,
        }));
        return;
      }

      if (countryAccess !== countryDetected && !userLogged) {
        if (typeof window !== 'undefined') {
          const fullFinger: any = yield getFingerPrintPro();
          if (fullFinger) {
            const countryCode = fullFinger?.data?.responseObj?.visits[0]?.ipLocation?.country?.code?.toLowerCase();
            if (countryCode) {
              const findCountry = COUNTRIES_LIST.find(c => c.code.toLowerCase() === countryCode);
              const detectedCountry = findCountry ? findCountry.code.toLowerCase() : COUNTRY_DEFAULT;
              const detecteDocumentType = findCountry ? findCountry?.documentType : 'Cédula';

              yield put(updatedCountryDetected(detectedCountry));
              yield put(updatedDocumentType(detecteDocumentType));
              Cookies.set('country', detectedCountry, { expires: 365 });
              yield put(updatedIp(fullFinger?.data?.responseObj?.visits[0].ip));
              yield put(updatedCity(fullFinger?.data?.responseObj?.visits[0]?.ipLocation?.city?.name || findCountry?.defaultCity));
              yield put(updatedHashFinger(fullFinger?.data?.responseObj?.visitorId));
            }
          }
        }
      } else if (countryAccess !== countryDetected && userLogged) {
        if (!isExcludedPath(payload.pathname)) {
          yield put(changePendingRedirect({
            redirectTo: payload.pathname,
            credentials: false,
          }));
        }
      }
    }

  } catch (e) {
    console.error("revalidateCountry error", e);
  }
}

function* verifyAndSetCountry(fingerInfo) {
  try {
    const countryCode = fingerInfo.visits[0].ipLocation.country.code.toLowerCase();
    let countryAvailable = yield select((state) => state.layout.countryAvailable);
    const countryFound = COUNTRIES_LIST.find(c => c.code.toLowerCase() === countryCode ?? COUNTRY_DEFAULT);

    // Usar países por defecto si no hay disponibles en el estado
    if (!countryAvailable.length) {
      countryAvailable = COUNTRIES_LIST;
    }
    // Verificar si el país detectado está disponible
    const country = countryAvailable.find(c => c.code.toLowerCase() === countryCode);
    if (country) {
      const countryDetected = yield select((state) => state.fp.countryDetected);
      if (countryDetected !== countryCode) {
        yield put(updatedCountryDetected(countryCode));
        yield put(updatedDocumentType(countryFound?.documentType ?? 'Cédula'));
        Cookies.set('country', countryCode, { expires: 365 }); // La cookie expirará en 1 año
      }
    } else {
      // Si el país no está disponible, establecer país por defecto (Perú)
      yield put(updatedCountryDetected(COUNTRY_DEFAULT));
      yield put(updatedDocumentType('Cédula'));
      Cookies.set('country', COUNTRY_DEFAULT, { expires: 365 }); // La cookie expirará en 1 año
    }
  } catch (e) {
    console.error("verifyAndSetCountry error", e)
    Sentry.captureException(e)
  }
}

// Función para manejar la generación de cookies de invitación
function* handlePostInvite(hashFinger) {
  try {
    yield call(axios.post, '/api/v2/customers/PostInvite', { hashFinger });
  } catch (e) {
    console.log('Error in handlePostInvite:', e)
    Sentry.captureException(e)
  }
}

// Función para obtener y manejar datos de fingerprint
function* handleGetFullDataFP() {
  try {
    const userLogged = yield select((state) => state.auth.userLogged);


    // Obtener datos de fingerprint
    const fingerInfo = yield call(getFingerPrintPro);
    if (!fingerInfo || !fingerInfo.visits[0]) {
      yield handlePostInvite(null);
      return;
    }

    // Manejar verificación y actualización de país si el usuario no está logueado
    if (!userLogged) {
      yield call(verifyAndSetCountry, fingerInfo);
    }

    // Actualizar detalles en el estado
    yield put(updatedIp(fingerInfo.visits[0].ip));
    const cityName = fingerInfo.visits[0]?.ipLocation?.city?.name || 'Santiago';
    yield put(updatedCity(cityName));
    yield put(updatedHashFinger(fingerInfo.visitorId));

    // Generar cookie de invitación basada en fingerprint
    yield handlePostInvite(fingerInfo.visitorId);
  } catch (e) {
    console.log('Error in handleGetFullDataFP:', e);
    Sentry.captureException(e)
  }
}

export default all([
  takeLatest('@fp/GETFULLFP', handleGetFullDataFP),
  takeLatest("@fp/REVALIDATECOUNTRY", revalidateCountry),
]);
