import '../styles/styles.scss';
import '../styles/css/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@styles/css/datepicker.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS
import '../styles/gamanza-fix.scss';

import ColorConfig from '@components/Template/ColorConfig/index';
import { useReportWebVitals } from 'next/web-vitals'
import ProgressBar from '@badrap/bar-of-progress';
import ErrorBoundary from '@components/Template/ErrorBoundary';
import { config } from '@fortawesome/fontawesome-svg-core';
import { wrapper } from '@clean/application/redux/store';
import FlagProvider from '@unleash/proxy-client-react';
import Router from 'next/router';
import { appWithTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import nextI18NextConfig from '../next-i18next.config.js';
// Tell Font Awesome to skip adding the CSS automatically since it's being imported above
config.autoAddCss = false;

const progress = new ProgressBar({
  size: 4,
  color: 'var(--orange)',
  className: 'z-50',
  delay: 100,
});

const configFF = {
  url: process.env.NEXT_PUBLIC_URL_UNLEASH,
  clientKey: process.env.NEXT_PUBLIC_CLIENTKEY_UNLEASH,
  refreshInterval: 15,
  appName: process.env.NEXT_PUBLIC_APP_UNLEASH
};

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

const Noop: FC<any> = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }: any) {
  const store: any = useStore();

  const Layout = Component.Layout || Noop;

  useReportWebVitals((metric) => {
    //console.log(metric)
  })

  return (
    <FlagProvider config={configFF}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <ErrorBoundary>
          <ColorConfig />
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </ErrorBoundary>
      </PersistGate>
    </FlagProvider>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp, nextI18NextConfig));
