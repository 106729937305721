import { DTOVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoRequest';
import type { DTOResponseVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoResponse';
import ACLSeonUseCase from '@clean/domain/useCase/acl-seon/aclSeonUseCase';
import ACLSeonRepositoryImpl from '@clean/infrastructure/repositories/AclSeonRepositoryImpl';
import * as gtag from '@lib/gtag';
import type { IProperties, ITrackEventRegister } from '@lib/sendiBlue';
import { SendiBlueEvent } from '@lib/sendiBlue';
import { srtmCommands } from '@lib/sportRadar';
import { registerFailed } from '@clean/application/redux/register/actions/register';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { handleErrorResponseRegister } from './handleErrorResponse';
import { handleSuccessResponse } from './handleSuccessResponse';

const AclSeonRepo = new ACLSeonRepositoryImpl();
const AclSeonService = new ACLSeonUseCase(AclSeonRepo);

const postRegister = async (payloadRegister) => {
  // // console.log('postRegister', payloadRegister);
  try {
    const registerResponse = await axios.post(
      '/api/v2/customers/PostRegister',
      payloadRegister
    );
    if (registerResponse.data && registerResponse.data.data) {
      return registerResponse.data.data.responseObj;
    }
    return registerResponse.data.error;
  } catch (err) {
    Sentry.captureException(err);
    return err.response?.data?.message;
  }
};

const postInvitation = async (payloadRegister) => {
  // console.log('postInvitation', payloadRegister);
  try {
    const validResponse = await axios.post(
      '/api/v2/ValidUser/valid',
      payloadRegister
    );
    if (validResponse.data) {
      return validResponse.data.valid;
    }
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

function* handleRegister({ payloadRegister }) {
  // comprobando configuracion del sitio
  // console.log('handleRegister', payloadRegister);
  const onlyWithInvitation = yield select(
    (state) => state.layout.onlyWithInvitation
  );
  const needFraudValidation = yield select(
    (state) => state.register.needFraudValidation
  );
  let UserValid = true;
  if (onlyWithInvitation) {
    UserValid = yield call(postInvitation, {
      email: payloadRegister.customer.email.toLowerCase(),
    });
  }
  if (!UserValid) {
    yield put(
      registerFailed({
        message: 'Debes Registrarte con el correo de invitación.',
      })
    );
  } else {
    const ipUser = yield select((state) => state.fp.userIp);
    let isFraud = false;
    if (needFraudValidation) {
      let verifyFraudResult;
      if (ipUser) {
        const BodyVerifyFraud = new DTOVerifyFraud({
          email: payloadRegister.customer.email.toLowerCase(),
          ip: ipUser,
          phone:
            payloadRegister.customer.phoneCode +
            payloadRegister.customer.phoneNumber,
          session_id: uuidv4(),
        });
        verifyFraudResult = yield AclSeonService.VerifyFraud(BodyVerifyFraud);
        if (verifyFraudResult.error) {
          yield put(
            registerFailed({ message: 'Problemas al terminar el registro' })
          );
          return false;
        }
      }
      isFraud = yield handleResponseSeon(verifyFraudResult.data);
    }
    if (!isFraud) {
      const infoRegister = yield call(postRegister, payloadRegister);
      if (infoRegister.error) {
        //error register
        yield handleErrorResponseRegister(infoRegister);
      } else if (infoRegister.id) {
        //register success
        if (process.env.NODE_ENV === 'production') {
          gtag.event('signup', {
            method: 'normal',
            email: payloadRegister.customer.email,
            userId: infoRegister.id,
            affiliateId: '',
          });
          handleSendEventSendinblue(payloadRegister, infoRegister.id);
        
        }
        if(window?._mgq){
          window._mgq.push(["MgSensorInvoke", "registro862886"])
        } 
        srtmCommands('track.user.registration', {
          action: 'complete',
          userId: infoRegister.id,
        });
        yield handleSuccessResponse();
      } else {
        yield put(
          registerFailed({ message: 'Problemas al terminar el registro' })
        );
      }
    }
  }
}

const handleSendEventSendinblue = (payloadRegister, customerId) => {
  // console.log('postRegister', payloadRegister, customerId);
  const Properties: IProperties = {
    email: payloadRegister.customer.email,
    FIRSTNAME: payloadRegister.customer.firstName,
    LASTNAME: payloadRegister.customer.lastName,
    customerId,
  };
  const TrackEvent: ITrackEventRegister = {
    data: {
      country: payloadRegister.customer.currency,
      createdDate: new Date(),
    },
  };
  const EVENT_NAME = 'register';
  SendiBlueEvent(EVENT_NAME, Properties, TrackEvent);
};

function* handleResponseSeon(BodyVerifyFraud: DTOResponseVerifyFraud) {
  // console.log('handleResponseSeon', BodyVerifyFraud);
  if (!BodyVerifyFraud) {
    yield put(
      registerFailed({
        message:
          'No fue posible realizar verificaciones de fraude, por favor actualice la págine y reintente',
      })
    );
    return true;
  }
  if (!BodyVerifyFraud.email.deliverable) {
    yield put(registerFailed({ message: 'Por favor ingrese un email válido' }));
    return true;
  }
  if (BodyVerifyFraud.email.disposable) {
    yield put(registerFailed({ message: 'Por favor ingrese un email válido' }));
    return true;
  }
  if (
    BodyVerifyFraud.ip.public_proxy ||
    BodyVerifyFraud.ip.tor ||
    BodyVerifyFraud.ip.vpn ||
    BodyVerifyFraud.ip.web_proxy
  ) {
    yield put(
      registerFailed({
        message:
          'Lo sentimos, no estás autorizado para registrarte en nuestro sitio.',
      })
    );
    return true;
  }
  if (!BodyVerifyFraud.phone.valid) {
    yield put(
      registerFailed({ message: 'Por favor intente con otro número móvil' })
    );
    return true;
  }
  return false; // si retorna false, es porque no es fraude
}

export default all([takeLatest('@register/REGISTERREQUEST', handleRegister)]);
