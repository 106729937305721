import { put, all, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_REGISTER_CONFIG,
    failedRequestRegisterConfig,
    setLastFetch,
    successRequestRegisterConfig
} from '@clean/application/redux/strapi/actions/registerConfig'
import StrapiUseCase from '@clean/domain/useCase/strapi/strapiUseCase';
import StrapiRepositoryImpl from '@clean/infrastructure/repositories/StrapiRepositoryImpl';
const StrapiRepo = new StrapiRepositoryImpl();
const StrapiService = new StrapiUseCase(StrapiRepo);

function* handleGetRegisterConfig(country) {
    try {
        const response = yield StrapiService.getCountry(country);

        if (response && response.data && response.data.responseObj) {
            const appliesKYC = response.data.responseObj?.appliesKYC;
            const appliesSms = response.data.responseObj?.appliesSms;
            yield put(successRequestRegisterConfig({ appliesKYC, appliesSms }));
            yield put(setLastFetch(new Date()));
        } else {
            console.error("Attributes not found in response", response);
            yield put(failedRequestRegisterConfig());
        }
    } catch (error) {
        console.error("Error fetching register config: ", error);
        yield put(successRequestRegisterConfig({
            appliesKYC: false,
            appliesSms: false
        }));
        yield put(failedRequestRegisterConfig());
    }
}


export default all([
    takeLatest(REQUEST_REGISTER_CONFIG, handleGetRegisterConfig)
]);
