import {
  GET_MENU_NAV_BAR_SUCCESS,
  GET_MENU_NAV_BAR_REJECTED,
  GET_MENU_NAV_BAR_REQUEST
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiMenuNavbarSuccess(payload) {
  return { type: GET_MENU_NAV_BAR_SUCCESS, payload }
}
export function getStrapiMenuNavbarReject() {
  return { type: GET_MENU_NAV_BAR_REJECTED }
}
export function getStrapiMenuNavbarRequest(payload) {
  return { type: GET_MENU_NAV_BAR_REQUEST, payload }
}