export const fpActionTypes = {
  GETFULLFP: '@fp/GETFULLFP',
  UPDATEDIP: 'UPDATEDIP',
  UPDATEDHASHFINGER: '@auth/UPDATEDHASHFINGER',
  UPDATEDCITY: 'UPDATEDCITY',
  COUNTRYDETECTED: 'COUNTRYDETECTED',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  SETVISITOR: '@fp/SETVISITOR',
  BROWSER_NOT_ALLOWED: '@auth/BROWSER_NOT_ALLOWED',
  RESET_BROWSER_NOT_ALLOWED: '@auth/RESET_BROWSER_NOT_ALLOWED',
  VERIFICATION_BLOCK_COUNTRY_SUCCESS: '@auth/VERIFICATION_BLOCK_COUNTRY_SUCCESS',
  REVALIDATECOUNTRY: '@fp/REVALIDATECOUNTRY'
}