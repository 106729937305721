import { ACLSeonRepository } from '@clean/domain/repositories/acl-seon/AclSeonRepository';
import { DTOResponseVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoResponse';
import { DTOVerifyFraud } from '@clean/domain/repositories/acl-seon/dtoRequest';
import axios from 'axios';

export default class ACLSeonRepositoryImpl implements  ACLSeonRepository {

  async VerifyFraud(
    Body: DTOVerifyFraud
    ): Promise<DTOResponseVerifyFraud>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: verifyResult } = await axios.post(
          `/api/v2/acl-seon/verifyFraud`,
          Body
        );
        resolver(verifyResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
