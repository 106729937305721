import { sessionReminderActionTypes } from '../actions/sessionReminder'
import produce from 'immer';

const SessionReminderInitialState = {
  depositLimit: [],
  sessionReminder: [],
  selfExclusion: [],
  loading: false,
  reminderDuration: 0,
  startTime: null,
  endTime: null,
  isActive: false,
  postCompleted: true,
  isForm: false
}

const reducer = (state = SessionReminderInitialState, action) => {
  switch (action.type) {
    case sessionReminderActionTypes.REQUEST_SET_TIME_REMINDER:
      return produce(state, draft => {
        //draft.loading = true;
      });
    case sessionReminderActionTypes.REQUEST_SESSION_REMINDER:
    case sessionReminderActionTypes.REQUEST_SESSION_REMINDER_CLOSE_MODAL:
    case sessionReminderActionTypes.REQUEST_SESSION_REMINDER_LOGOUT:
      return produce(state, draft => {
        draft.loading = true;
      });
    case sessionReminderActionTypes.REQUEST_SESSION_REMINDER_DELETE:
      return produce(state, draft => {
        draft.loading = true
      });
    case sessionReminderActionTypes.REQUEST_SESSION_REMINDER_POST:
      return produce(state, draft => {
        draft.loading = true;
        draft.isForm = action.payload?.isForm ?? false;
      });
    case sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_POST:
      return produce(state, draft => {
        draft.loading = false;
        if (draft.isForm) {
          draft.postCompleted = draft.isForm;
        } else {
          draft.postCompleted = false;
        }
        draft.isForm = false;
      });
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_POST:
      return produce(state, draft => {
        draft.loading = false;
      });
    case sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_DELETE:
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_DELETE:
      return produce(state, draft => {
        draft.reminderDuration = 0;
        draft.startTime = "";
        draft.endTime = "";
        draft.loading = false;
        draft.postCompleted = false;
      });
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_DELETE:
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_LOGOUT:
    case sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_LOGOUT:
    case sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_CLOSE_MODAL:
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_CLOSE_MODAL:
      return produce(state, draft => {
        draft.loading = false
      });
    case sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER:
      return produce(state, draft => {
        draft.reminderDuration = action.payload?.reminderDuration ?? 0;
        draft.endTime = action.payload?.endTime ?? null;
        draft.startTime = action.payload?.startTime ?? null;
        draft.isActive = action.payload?.isActive ?? false;
        draft.postCompleted = false;
        draft.loading = false;
      });
    case sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER:
      return produce(state, draft => {
        draft.reminderDuration = 0;
        draft.startTime = "";
        draft.endTime = "";
        draft.isActive = false;
        draft.loading = false;
        draft.postCompleted = false;
      });
    default:
      return state
  }
}

export default reducer;
