import { bonusActionsTypes } from "../constants/bonus"

export function saveBonusStrapi (detailBono) {
  return { type: bonusActionsTypes.SAVE_STRAPI_BONUS, detailBono }
}

export function bonusCashBackRequest () {
  return { type: bonusActionsTypes.BONUS_CASHBACK_REQUEST }
}

export function bonusCashBackSuccess(bono) {
  return { type: bonusActionsTypes.BONUS_CASHBACK_SUCCESS, bono }
}

export function bonusCashBackFailed(error) {
  return { type: bonusActionsTypes.BONUS_CASHBACK_FAILED, payload: error }
}

export function initialStateBonus(){
  return { type: bonusActionsTypes.BONUS_INITIAL_STATE}
}

export function bonocashbackRequest () {
  return { type: bonusActionsTypes.BONOCASHBACK_REQUEST }
}

export function bonoCashbackHistoryRequest (payload) {
  return { type: bonusActionsTypes.BONOCASHBACKHISTORY_REQUEST, payload }
}

export function bonoCashbackHistorySuccess (payload) {
  return { type: bonusActionsTypes.BONOCASHBACKHISTORY_SUCCESS, payload }
}

export function bonocashbackSuccess(bono) {
  return { type: bonusActionsTypes.BONOCASHBACK_SUCCESS, bono }
}

export function bonocashbackFailed(error) {
  return { type: bonusActionsTypes.BONOCASHBACK_FAILED, payload: error }
}

