export const bonusActionsTypes = {
  BONUS_CASHBACK_REQUEST: '@bonus/BONUS_CASHBACK_REQUEST',
  BONUS_CASHBACK_SUCCESS: '@bonus/BONUS_CASHBACK_SUCCESS',
  BONUS_CASHBACK_FAILED: '@bonus/BONUS_CASHBACK_FAILED',
  BONUS_INITIAL_STATE: 'BONUS_INITIAL_STATE',
  BONOCASHBACK_REQUEST: '@bonus/BONOCASHBACK_REQUEST',
  BONOCASHBACK_SUCCESS: '@bonus/SEARCH_GAME_SUCCESS',
  BONOCASHBACK_FAILED: '@bonus/SEARCH_GAME_FAILED',
  BONOCASHBACKHISTORY_REQUEST: '@bonus/BONOCASHBACKHISTORY_REQUEST',
  BONOCASHBACKHISTORY_SUCCESS: '@bonus/BONOCASHBACKHISTORY_SUCCESS',
  SAVE_STRAPI_BONUS: '@bonus/SAVE_STRAPI_BONUS'
}