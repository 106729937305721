import { select, put, all, call, takeLatest } from 'redux-saga/effects';
import {
  resetBroserNotAllowed
} from '@clean/application/redux/fp/actions/fp'
import axios from 'axios';
import {
  getFullFP
} from '@clean/application/redux/fp/actions/fp'
import {
  UpdatedDataLayout,
  UpdatedCountryAvailable,
  layoutReady,
  layoutLoad
} from '@clean/application/redux/layout/actions/layout'
import { LayoutActionTypes } from '../constants/layout';


function* getDataNavbar() {
  try {
    const countryDetected = yield select((state) => state.fp.countryDetected);
    const infoResponse = yield call(axios.post, '/api/v2/apiConfig/GetDataNavbar', { country: countryDetected });
    yield put(resetBroserNotAllowed());

    if (infoResponse.data && infoResponse.data.data && infoResponse.data.data.responseObj) {
      return infoResponse.data.data.responseObj;
    } else if (infoResponse.data && infoResponse.data.responseObj) {
      return infoResponse.data.responseObj;
    } else {
      return getEmptyResponse();
    }
  } catch (err) {
    console.error('Error in getDataNavbar:', err);
    return getEmptyResponse();
  }
}

function getEmptyResponse() {
  return {
    infoGenerals: [],
    languages: [],
    countryCallingCodes: [],
    currencies: [],
    onlyWithInvitation: false,
    contents: []
  };
}

const getCountryAvailable = async () => {
  try {
    const infoResponse = await axios.post('/api/v2/Config/GetCountries', {})
    if (infoResponse.data && infoResponse.data.data) {
      return infoResponse.data.data
    } else {
      return [{
        code: 'CL'
      }, {
        code: 'PE'
      }, {
        code: 'AR'
      }, {
        code: 'PY'
      }, {
        code: 'GT'
      }]
    }
  } catch (err) {
    return [{
      code: 'CL'
    }, {
      code: 'PE'
    }, {
      code: 'AR'
    }, {
      code: 'PY'
    }, {
      code: 'GT'
    }]
  }
}

function* UpdatedInfoLayout() {
  try {
    const languages = yield select((state) => state.layout.languages);
    const currencies = yield select((state) => state.layout.currencies);
    const countryAvailable = [
      { code: 'CL' },
      { code: 'PE' },
      { code: 'AR' },
      { code: 'PY' },
      { code: 'GT' }
    ];
    const contents = yield select((state) => state.layout.contents);

    // Verificar si las listas están definidas y tienen elementos
    if (!languages || !languages.length || !currencies || !currencies.length || !contents || !contents.length) {
      yield put(layoutLoad());
      const data = yield call(getDataNavbar);
      if (data) {
        yield put(UpdatedDataLayout(data));
      }
    }

    // Verificar si countryAvailable está definido y tiene elementos
    if (!countryAvailable || !countryAvailable.length) {
      const cyAvailable = yield call(getCountryAvailable);
      if (cyAvailable) {
        yield put(UpdatedCountryAvailable(cyAvailable));
      }
    }

    yield put(layoutReady());

    // Fingerprint Actions
    yield put(getFullFP());

  } catch (e) {
    console.error(e);
  }
}

export default all([
  takeLatest(LayoutActionTypes.UPDATED_INFO_LAYOUT_REQUEST, UpdatedInfoLayout)
]);
