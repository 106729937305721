import produce from 'immer';
import { bonusActionsTypes } from '../constants/bonusPromotional';

const bonusInitialState = {
  bonoPromotionalSucess: false,
  loadingBonoPromotional: false,
  errorBonoPromotional: []
}

const reducer = (state = bonusInitialState, action) => {
  switch (action.type) {
    case bonusActionsTypes.RESETBONOPROMOTIONAL:
      return produce(state, draft => {
        draft.loadingOrder = false
        draft.bonoPromotionalSucess = false
      });
    case bonusActionsTypes.BONOPROMOTIONAL_REQUEST:
      return produce(state, draft => {
        draft.loadingBonoPromotional = true
        draft.bonoPromotionalSucess = false
      });
    case bonusActionsTypes.BONOPROMOTIONAL_SUCCESS:
      return produce(state, draft => {
        draft.loadingBonoPromotional = false
        draft.bonoPromotionalSucess = true
      });
    case bonusActionsTypes.RESETERRORSBONOPROMOTIONAL:
      return produce(state, draft => {
        draft.errorBonoPromotional = []
      });
    case bonusActionsTypes.BONOPROMOTIONAL_FAILED:
      return produce(state, draft => {
        draft.loadingBonoPromotional = false
        draft.bonoPromotionalSucess = false
        draft.errorBonoPromotional.push(action.payload)
      });
    default:
      return state
  }
}

export default reducer
