import { redirectMatchActionTypes } from "../constants/redirectMatch"

const redirectInitialState = {
  idMatch: null,
  idLeague: null,
  idRegion: null,
}

const reducer = (state = redirectInitialState, action) => {
  switch (action.type) {
    case redirectMatchActionTypes.REDIRECT:
      return {
        ...state,
        idMatch: action.payload,
      }
    case redirectMatchActionTypes.REDIRECTLEAGUE:
      return {
        ...state,
        idLeague: action.payload,
      }
    case redirectMatchActionTypes.REDIRECTREGION:
      return {
        ...state,
        idRegion: action.payload,
      }
    default:
      return state
  }
}

export default reducer
