import { SecurityActionTypes } from '@clean/application/redux/security/actions/securityActions';
import produce from 'immer';

const SecurityInitialState = {
  lastUpdated: null,
  loadingIpDetail: true,
  ip: null,
  security: {
    vpn: true,
    proxy: true,
    tor: true,
    relay: true,
  },
  location: {
    city: 'Santiago',
    region: 'Santiago Metropolitan',
    country: 'Chile',
    continent: 'South America',
    region_code: 'RM',
    country_code: '',
    continent_code: 'SA',
    latitude: '',
    longitude: '',
    time_zone: 'America/Santiago',
    locale_code: 'en',
    metro_code: '',
    is_in_european_union: false,
  },
  network: {
    network: '',
    autonomous_system_number: '',
    autonomous_system_organization: '',
  },
}

const reducer = (state = SecurityInitialState, action) => {
  switch (action.type) {
    case SecurityActionTypes.VPNVALIDATION_REQUEST:
      return produce(state, draft => {
        draft.loadingIpDetail = true;
      });
    case SecurityActionTypes.VPNVALIDATION_SUCCESS:
      return produce(state, draft => {
        draft.ip = action.payload.ip;
        draft.security = action.payload.security;
        draft.location = action.payload.location;
        draft.network = action.payload.network;
        draft.loadingIpDetail = false;
        draft.lastUpdated = new Date();
      });
    case SecurityActionTypes.VPNVALIDATION_REJECTED:
      return produce(state, draft => {
        draft = SecurityInitialState
      });
    default:
      return state
  }
}

export default reducer;
