import {TombolaRepository } from '@clean/domain/repositories/tombolaRepository/TombolaRepository';
export default class TombolaUseCase implements TombolaRepository {
  tombolaRepo: TombolaRepository;

  constructor(TR:TombolaRepository) {
    this.tombolaRepo = TR;
  }

  async GetStatusPlayer(
    CustomerId: string
    ): Promise<any>{
    return this.tombolaRepo.GetStatusPlayer(CustomerId);
  }
}
