export const pendingActionTypes = {
  ADDACTIONPENDING: '@pendingActions/ADDACTIONPENDING',
  REMOVEACTIONPENDING: '@pendingActions/REMOVEACTIONPENDING',
  RESETPENDINGACTIONS: '@pendingActions/RESETPENDINGACTIONS'
}

export function addPendingActions(action) {
  return {
    type: pendingActionTypes.ADDACTIONPENDING,
    payload: action
  }
}

export function removePendingActions(id) {
  return {
    type: pendingActionTypes.REMOVEACTIONPENDING,
    payload: id
  }
}

export function resetPendingActions() {
  return {
    type: pendingActionTypes.RESETPENDINGACTIONS
  }
}

