export const sessionReminderActionTypes = {
  REQUEST_SESSION_REMINDER: '@sessionReminder/REQUEST_SESSION_REMINDER',
  REQUEST_SUCCES_REQUEST_SESSION_REMINDER: '@sessionReminder/REQUEST_SUCCES_REQUEST_SESSION_REMINDER',
  REQUEST_REJECT_REQUEST_SESSION_REMINDER: '@sessionReminder/REQUEST_REJECT_REQUEST_SESSION_REMINDER',
  REQUEST_SESSION_REMINDER_POST: '@sessionReminder/REQUEST_SESSION_REMINDER_POST',
  REQUEST_SUCCES_REQUEST_SESSION_REMINDER_POST: '@sessionReminder/REQUEST_SUCCES_REQUEST_SESSION_REMINDER_POST',
  REQUEST_REJECT_REQUEST_SESSION_REMINDER_POST: '@sessionReminder/REQUEST_REJECT_REQUEST_SESSION_REMINDER_POST',
  REQUEST_SESSION_REMINDER_DELETE: '@sessionReminder/REQUEST_SESSION_REMINDER_DELETE',
  REQUEST_SUCCES_REQUEST_SESSION_REMINDER_DELETE: '@sessionReminder/REQUEST_SUCCES_REQUEST_SESSION_REMINDER_DELETE',
  REQUEST_REJECT_REQUEST_SESSION_REMINDER_DELETE: '@sessionReminder/REQUEST_REJECT_REQUEST_SESSION_REMINDER_DELETE',
  REQUEST_SESSION_REMINDER_LOGOUT: '@sessionReminder/REQUEST_SESSION_REMINDER_LOGOUT',
  REQUEST_SUCCES_REQUEST_SESSION_REMINDER_LOGOUT: '@sessionReminder/REQUEST_SUCCES_REQUEST_SESSION_REMINDER_LOGOUT',
  REQUEST_REJECT_REQUEST_SESSION_REMINDER_LOGOUT: '@sessionReminder/REQUEST_REJECT_REQUEST_SESSION_REMINDER_LOGOUT',
  REQUEST_SESSION_REMINDER_CLOSE_MODAL: '@sessionReminder/REQUEST_SESSION_REMINDER_CLOSE_MODAL',
  REQUEST_SUCCES_REQUEST_SESSION_REMINDER_CLOSE_MODAL: '@sessionReminder/REQUEST_SUCCES_REQUEST_SESSION_REMINDER_CLOSE_MODAL',
  REQUEST_REJECT_REQUEST_SESSION_REMINDER_CLOSE_MODAL: '@sessionReminder/REQUEST_REJECT_REQUEST_SESSION_REMINDER_CLOSE_MODAL',
  REQUEST_SET_TIME_REMINDER: '@sessionReminder/REQUEST_SET_TIME_REMINDER',
}

export function requestSessionReminder() {
  return { type: sessionReminderActionTypes.REQUEST_SESSION_REMINDER }
}
export function requestSetSessionReminder(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SET_TIME_REMINDER, payload}
}
export function requestRejectSessionReminder() {
  return { type: sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER }
}
export function requestSuccessSessionReminder(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER, payload }
}
export function requestSessionReminderStatus(payload?) {
  return { type: sessionReminderActionTypes.REQUEST_SESSION_REMINDER_POST , payload}
}
export function requestRejectSessionReminderStatus() {
  return { type: sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_POST }
}
export function requestSuccessSessionReminderStatus(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_POST, payload }
}
export function requestSessionReminderDelete(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SESSION_REMINDER_DELETE}
}
export function requestRejectSessionReminderDelete() {
  return { type: sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_DELETE }
}
export function requestSuccessSessionReminderDelete(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_DELETE, payload }
}
export function requestSessionReminderLogout() {
  return { type: sessionReminderActionTypes.REQUEST_SESSION_REMINDER_LOGOUT}
}
export function requestRejectSessionReminderLogout() {
  return { type: sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_LOGOUT }
}
export function requestSuccessSessionReminderLogout(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_LOGOUT, payload }
}
export function requestSessionReminderCloseModal() {
  return { type: sessionReminderActionTypes.REQUEST_SESSION_REMINDER_CLOSE_MODAL}
}
export function requestRejectSessionReminderCloseModal() {
  return { type: sessionReminderActionTypes.REQUEST_REJECT_REQUEST_SESSION_REMINDER_CLOSE_MODAL }
}
export function requestSuccessSessionReminderCloseModal(payload) {
  return { type: sessionReminderActionTypes.REQUEST_SUCCES_REQUEST_SESSION_REMINDER_CLOSE_MODAL, payload }
}