import type { DataError } from '@clean/domain/entities/dataError';
import type { Either } from '@clean/domain/entities/either';
import { PaginationQueryDto } from '@clean/domain/dtos/deposit/paginationQuery.dto';
import { DepositDto } from  '@clean/domain/dtos/deposit/deposit.dto';
import { DepositDetailsDto } from '@clean/domain/dtos/deposit/depositDetails.dto';
import type { DepositRepository } from '../repositories/depositRepository';

class DepositUseCase implements DepositRepository {
  depositRepo: DepositRepository;

  constructor(DR: DepositRepository) {
    this.depositRepo = DR;
  }

  async HaveDeposit(): Promise<Either<DataError, any>> {
    return this.depositRepo.HaveDeposit();
  }
}

export default DepositUseCase;
