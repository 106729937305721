import { ChangeBadgeActionTypes } from '../../notifications/actions/badge'
import { ICustomer } from '@interfaces/globals'

import produce from 'immer';
import { authActionTypes } from '../constants/auth';

interface IAuthState {
  userLogged: Boolean,
  userInfo: ICustomer,
  isInvite: Boolean,
  loadingLogin: Boolean,
  modalLogin: String,
  errorsLogin: Array<String>,
  loadingInvite: Boolean,
  errorsInvite: Array<String>,
  forceLogout: Boolean,
  loadingViewNotification: Boolean,
  haveDeposit: Boolean | null
}

const authInitialState: IAuthState = {
  userLogged: false,
  userInfo: null,
  isInvite: false,
  loadingLogin: false,
  modalLogin: 'close',
  errorsLogin: [],
  loadingInvite: false,
  errorsInvite: [],
  forceLogout: false,
  loadingViewNotification: false,
  haveDeposit: null
}

const reducer = (state = authInitialState, action: any) => {
  switch (action.type) {
    case ChangeBadgeActionTypes.CLEAR_NOTIFICATION:
      return produce(state, draft => {
        draft.loadingViewNotification = false
      });
    case authActionTypes.UPDATE_NOTIFICATIONS_REQUEST:
      return produce(state, draft => {
        draft.loadingViewNotification = true
      });
    case authActionTypes.LOGOUT_FORCE_REQUEST:
      return produce(state, draft => {
        draft.forceLogout = true
      });
    case authActionTypes.LOGOUT_FORCE_SUCCESS:
      return produce(state, draft => {
        draft.forceLogout = false
      });
    case authActionTypes.LOGINREQUEST:
      return produce(state, draft => {
        draft.loadingLogin = true
        draft.errorsLogin = []
        draft.modalLogin = 'open' 
      });
    case authActionTypes.LOGINRESETERROR:
      return produce(state, draft => {
        draft.errorsLogin = []
      })
    case authActionTypes.LOGIN:
      return produce(state, draft => {
        draft.userInfo = action.payload
      });
    case authActionTypes.LOGINSUCCESS:
      return produce(state, draft => {
        draft.loadingLogin = false
        draft.userLogged = true
        draft.modalLogin = 'close'
      });
    case authActionTypes.LOGINFAILED:
      return produce(state, draft => {
        draft.loadingLogin = false
        draft.userLogged = false
        draft.errorsLogin.push(action.payload)
      });
    case authActionTypes.INVITERESETERROR:
      return produce(state, draft => {
        draft.errorsInvite = []
      })
    case authActionTypes.INVITESUCCESS:
      return produce(state, draft => {
        draft.loadingInvite = false
        draft.isInvite = true
      });
    case authActionTypes.INVITEFAILED:
      return produce(state, draft => {
        draft.loadingInvite = false
        draft.isInvite = false
        draft.errorsInvite.push(action.payload)
      });
    case authActionTypes.GET_USER:
      return {
        ...state,
        userInfo: action.payload,
      }
    case authActionTypes.UPDATEDINFO:
      return produce(state, draft => {
        draft.userInfo = action.payload
      });
    case authActionTypes.RESET_LOGIN:
      return produce(state, draft => {
        draft.loadingLogin = false
      });
    case authActionTypes.LOGOUT:
      return produce(state, draft => {
        draft.userLogged = false
        draft.userInfo = null
        draft.haveDeposit = null
      });
    case authActionTypes.SET_HAVE_DEPOSIT:
      return produce(state, draft => {
        draft.haveDeposit = action.payload;
      });
    default:
      return state
  }
}

export default reducer
