export const balanceActionTypes = {
    PUTSHOWBALANCE: 'PUTSHOWBALANCE',
    PUTAMOUNTBALANCEREQUEST: '@balance/PUTAMOUNTBALANCEREQUEST',
    PUTAMOUNTBALANCE: '@balance/PUTAMOUNTBALANCE',
    CLEARBALANCE: '@balance/CLEARBALANCE',
}

export const balanceInitialState = {
    showBalance: true,
    sb: 0,
    sa: 0,
    spr: 0,
    snu: 0,
    su: 0,
    total: 0,
    totalRetiro: 0
  }