import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import * as Sentry from "@sentry/nextjs";
import { addBonusCodePromotionalSuccess, bonusCodePromotionalFailed } from '@clean/application/redux/bonus/actions/bonusCodePromotional';

import axios from 'axios'

function* PostActiveBonusByCode (payload)  {
  try{
    const { data:response } = yield axios.post('/api/v2/apiBonus/PostActiveBonusByCode', payload)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return response.error
    }
  }catch(err){
    Sentry.captureException(err);
    return []
  }
}

function* handleAddBonusCodePromotional({payload}) {
  let CodePromotional = yield call(PostActiveBonusByCode,payload)
  if (CodePromotional.error !== undefined) {
    if(CodePromotional.code === 400){
      yield put(bonusCodePromotionalFailed({ message: 'Deposito Invalido'}))
    }
    if(CodePromotional.code === 404){
      yield put(bonusCodePromotionalFailed({ message: 'Error interno, contáctese por soporte'}))
    }
    if(CodePromotional.code === 500){
      yield put(bonusCodePromotionalFailed({ message: 'Error interno, contáctese por soporte'}))
    }
  } else {
    if(CodePromotional){
      yield put(addBonusCodePromotionalSuccess(CodePromotional))
    }
  }
}


export default all([
  takeLatest('@bonus/BONUS_CODE_PROMOTIONAL_REQUEST', handleAddBonusCodePromotional)
]);
