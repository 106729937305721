import { rankingActionTypes } from '../actions/ranking'
import produce from 'immer';

interface IRankingState {
  lastUpdated: Date,
  lastUpdatedPosition: Date,
  loadingRanking: boolean,
  loadingRankingTop10: boolean,
  loadingPosition: boolean,
  detailsRankingCustomer: {
    position: number,
    tickets: number,
  },
  detailsRanking: {
    casino: any
    sportbook: any,
    deposit: any,
  },
  topData: Array<String>,
  position: Array<string>,
  top10: Array<String>
  statusPlayer: {
    casino,
    sportbook,
    deposit
  }
}

const RankingInitialState: IRankingState = {
  lastUpdated: null,
  lastUpdatedPosition: null,
  loadingRanking: false,
  loadingRankingTop10: false,
  loadingPosition: false,
  detailsRankingCustomer: {
    position: 0,
    tickets: 0
  },
  topData: [],
  position: [],
  top10: [],
  statusPlayer: {
    casino: null,
    sportbook: null,
    deposit: null
  },
  detailsRanking: {
    casino: {
      position: 0,
      tickets: 0,
    },
    sportbook: {
      position: 0,
      tickets: 0,
    },
    deposit: {
      position: 0,
      tickets: 0,
    },
  }
}

const reducer = (state = RankingInitialState, action) => {
  switch (action.type) {
    case rankingActionTypes.REQUEST_POSITION:
      return produce(state, draft => {
        draft.loadingPosition = true
      });
    case rankingActionTypes.REQUEST_SUCCESS_POSITION:
      return produce(state, draft => {
        draft.loadingRanking = false;
        draft.detailsRankingCustomer.position = action.payload.customer?.position ?? 0;
        draft.detailsRankingCustomer.tickets = action.payload.customer?.tickets ?? 0;
        draft.position = action.payload.topData;
        draft.lastUpdatedPosition = new Date()
      });
    case rankingActionTypes.REQUEST_RANKING:
      return produce(state, draft => {
        draft.loadingRanking = false
      });
    case rankingActionTypes.REQUEST_STATUS:
      return produce(state, draft => {
        draft.loadingRanking = false
      });
    case rankingActionTypes.REQUEST_SUCCES_RANKING:
      return produce(state, draft => {
        draft.loadingRanking = false;
        draft.detailsRankingCustomer.position = action.payload?.customer?.position ?? 0;
        draft.detailsRankingCustomer.tickets = action.payload?.customer?.tickets ?? 0;
        draft.topData = action.payload?.topData ?? 0;
        draft.lastUpdated = new Date()
      });
    case rankingActionTypes.REQUEST_SUCCES_STATUS:
      return produce(state, draft => {
        draft.statusPlayer[action.payload?.vertical] = action.payload
        draft.lastUpdated = new Date()
      });
    case rankingActionTypes.REQUEST_RANKINGTOP10:
      return produce(state, draft => {
        draft.loadingRankingTop10 = true
      });
    case rankingActionTypes.REQUEST_SUCCES_RANKINGTOP10:
      return produce(state, draft => {
        draft.loadingRankingTop10 = false;
        draft.top10 = action.payload;
        draft.lastUpdated = new Date()
      });
    case rankingActionTypes.RESET_DATE:
      return produce(state, draft => {
        draft.lastUpdated = null
      });
    case rankingActionTypes.REQUEST_REJECT_POSITION:
      return produce(state, draft => {
        draft.loadingPosition = false
      });
    case rankingActionTypes.REQUEST_CLEAR_STATUS:
      return produce(state, draft => {
        draft.statusPlayer = {
          casino: null,
          sportbook: null,
          deposit: null
        }
      });
    case rankingActionTypes.REQUEST_REJECT_RANKING:
      return produce(state, draft => {
        draft.loadingRanking = false
      });
    case rankingActionTypes.REQUEST_REJECT_STATUS:
      return produce(state, draft => {
        draft.loadingRanking = false
      });
    case rankingActionTypes.REQUEST_REJECT_RANKINGTOP10:
      return produce(state, draft => {
        draft.loadingRankingTop10 = false
      });
    default:
      return state
  }
}

export default reducer;
