import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { TombolaActionTypes } from '@clean/application/redux/tombola/constant';
import TombolaUseCase from '@clean/domain/useCase/tombola/tombolaUseCase';
import TombolaRepositoryImpl from '@clean/infrastructure/repositories/TombolaRepositoryImpl';
import { getStatusPlayerSuccess, getStatusPlayerRejected } from '@clean/application/redux/tombola/actions/getStatusPlayer'
const TombolaRepo = new TombolaRepositoryImpl();
const TombolaService = new TombolaUseCase(TombolaRepo);

function* GetStatusPlayer (params)  {
  try{
    const status = yield TombolaService.GetStatusPlayer(params.payload);
    yield put(getStatusPlayerSuccess(status.isParticipating))
  }catch(err){
    yield put(getStatusPlayerRejected())
  }
}

export default all([
  takeLatest(TombolaActionTypes.REQUEST_GET_STATUS_PLAYER, GetStatusPlayer)
]);
