interface StateType {
  modalType: any; // or you could be more specific if you know possible types
  isOpen: boolean;
  modalProps: { [key: string]: any }; // Object with any set of key-value pairs
  modalsView: {
    popUpMarketing: boolean,
    popUpMarketingCL: boolean,
    popUpSessionReminder: boolean
  }
}

export const initialState : StateType = {
  modalType: null,
  isOpen: false,
  modalProps: {},
  modalsView: {
    popUpMarketing: false,
    popUpMarketingCL: false,
    popUpSessionReminder: false
  },
};
