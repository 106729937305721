import { select, put, all, takeLatest } from 'redux-saga/effects';
import {
  getVpnValidationSuccess,
  getVpnValidationRejected
} from '@clean/application/redux/security/actions/securityActions';
import { SecurityActionTypes } from '@clean/application/redux/security/actions/securityActions';
import VPNUseCase from '@clean/domain/useCase/Vpn/vpnUseCase';
import VPNRepositoryImpl from '@clean/infrastructure/repositories/VpnRepositoryImpl';
const VPNRepo = new VPNRepositoryImpl();
const VPNService = new VPNUseCase(VPNRepo);

function* handleVpnValidation() {
  try{
    const vpnValidation = yield VPNService.ValidateOrigin();
    yield put(getVpnValidationSuccess(vpnValidation))
  }catch(e){
    yield put(getVpnValidationRejected())
    console.log('handleError',e)
  }
}

export default all([
  takeLatest(SecurityActionTypes.VPNVALIDATION_REQUEST, handleVpnValidation),
]);
