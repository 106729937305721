import { RegisterActionTypes } from '@clean/application/redux/register/actions/register'
import produce from 'immer';

export const initialState = {
  registerSuccess: false,
  loadingRegister: false,
  errorsRegister: [],
  needFraudValidation: false,
}

const register = (state = initialState, action) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTERREQUEST:
      return produce(state, draft => {
        draft.loadingRegister = true
        draft.registerSuccess = false
    });
    case RegisterActionTypes.REGISTERRESETERROR:
      return produce(state, draft => {
        draft.errorsRegister = []
      })
    case RegisterActionTypes.REGISTERRESETFORM:
      return produce(state, draft => {
        draft.loadingRegister = false
        draft.registerSuccess = false
      });
    case RegisterActionTypes.REGISTERSUCCESS:
      return produce(state, draft => {
        draft.loadingRegister = false
        draft.registerSuccess = true
      });
    case RegisterActionTypes.REGISTERFAILED:
    return produce(state, draft => {
      draft.loadingRegister = false
      draft.registerSuccess = false
      draft.errorsRegister.push(action.payload)
    });
    case RegisterActionTypes.CHANGENEEDFRAUDVALIDATION:
      return produce(state, draft => {
        draft.needFraudValidation = action.payload
      });
    default:
      return state
  }
}

export default register
