import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  rankingActionTypes,
  requestSuccessRanking,
  requestSuccessStatus,
  requestSuccessRankingTop10,
  requestSuccessPosition,
  requestRejectRanking,
  requestRejectStatus,
  requestRejectRankingTop10,
  requestRejectPosition
} from '@clean/application/redux/leaderboard/actions/ranking';
import { updatedRanking } from '@clean/application/redux/leaderboard/adapters/ranking';
import axios from 'axios';

function* GetStatusPlayer(data) {
  try {
    let resultStatus = yield axios.post(`/api/v2/apiLeaderboard/GetStatusPlayer`,data);
    if (resultStatus) {
      return resultStatus.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* getRanking(data) {
  try {
    let resultRanking = yield axios.post(`/api/v2/apiLeaderboard/GetInfoLeaderboard`,data);
    if (resultRanking) {
      return resultRanking.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* getPosition(data) {
  try {
    let resultRanking = yield axios.post(`/api/v2/apiLeaderboard/GetPositionCustomer`,data);
    if (resultRanking.data) {
      return resultRanking.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* handleGetStatus({payload}) {
  try{
    let Status = yield call(GetStatusPlayer, payload)
    if(Status !== null){
      yield put(requestSuccessStatus(Status))
    } else {
      yield put(requestRejectStatus())
    }

  }catch(e){
    console.log(e)
  }
}

function* handleGetStatusCasino({payload}) {
  try{
    let Status = yield call(GetStatusPlayer, payload)
    if(Status !== null){
      yield put(requestSuccessStatus(Status))
    } else {
      yield put(requestRejectStatus())
    }

  }catch(e){
    console.log(e)
  }
}

function* handleGetStatusSportbook({payload}) {
  try{
    let Status = yield call(GetStatusPlayer, payload)
    if(Status !== null){
      yield put(requestSuccessStatus(Status))
    } else {
      yield put(requestRejectStatus())
    }

  }catch(e){
    console.log(e)
  }
}

function* handleGetStatusDeposit({payload}) {
  try{
    let Status = yield call(GetStatusPlayer, payload)
    if(Status !== null){
      yield put(requestSuccessStatus(Status))
    } else {
      yield put(requestRejectStatus())
    }

  }catch(e){
    console.log(e)
  }
}

function* handleGetRanking({payload}) {
  try{
    let Ranking = yield call(getRanking, payload)
    if(Ranking !== null){
      yield put(requestSuccessRanking(Ranking))
    } else {
      yield put(requestRejectRanking())
    }

  }catch(e){
    console.log(e)
  }
}

function* handleGetPosition({payload}) {
  try{
    let Ranking = yield call(getPosition, payload)
    if(Ranking !== null){
      yield put(requestSuccessPosition(updatedRanking(Ranking)))
    } else {
      yield put(requestRejectPosition())
    }
  }catch(e){
    console.log(e)
  }
}

function* handleGetTop10Ranking({payload}) {
  try{
    let Ranking = yield call(getRanking, payload);
    if(Ranking !== null){
      yield put(requestSuccessRankingTop10(Ranking));
    } else {
      yield put(requestRejectRankingTop10())
    }
  }catch(e){
    console.log(e)
  }
}

export default all([
  takeLatest(rankingActionTypes.REQUEST_RANKING, handleGetRanking),
  takeLatest(rankingActionTypes.REQUEST_RANKINGTOP10, handleGetTop10Ranking),
  takeLatest(rankingActionTypes.REQUEST_POSITION,handleGetPosition),
  takeLatest(rankingActionTypes.REQUEST_STATUS,handleGetStatus),
  takeLatest(rankingActionTypes.REQUEST_STATUS_CASINO,handleGetStatusCasino),
  takeLatest(rankingActionTypes.REQUEST_STATUS_DEPOSIT,handleGetStatusDeposit),
  takeLatest(rankingActionTypes.REQUEST_STATUS_SPORTBOOK,handleGetStatusSportbook)
]);
