import { put } from 'redux-saga/effects';
import {
  registerFailed,
} from '@clean/application/redux/register/actions/register';

const translateErrorRegister = (errorMessage) => {
  let messageTranslate = '';
  switch (errorMessage) {
    case 'Customer already exists by phoneNumber':
      messageTranslate =
        'Lo sentimos, el nº de celular ingresado ya está siendo utilizado, ingrese uno diferente';
      break;
    case 'Customer already exists by username':
      messageTranslate =
        'Lo sentimos, el nombre de usuario seleccionado ya está siendo utilizado, ingrese uno diferente';
      break;
    case 'Customer already exists by email':
      messageTranslate =
        'Lo sentimos, el correo ingresado ya está siendo utilizado, ingrese uno diferente';
      break;
    case 'Resource not found':
      messageTranslate =
        'Lo sentimos, el código promocional no es válido';
      break;
    default:
      break;
  }
  if(errorMessage.includes('Customer identificationNumber exists')){
    messageTranslate = 'Lo sentimos, el número de identificación existe';
  }
  return messageTranslate;
};

const translateErrorSeon = (errorMessage) => {
  let messageTranslate = '';
  switch (errorMessage) {
    case 'Customer already exists by phoneNumber':
      messageTranslate =
        'Lo sentimos, el nº de celular ingresado ya está siendo utilizado, ingrese uno diferente';
      break;
    case 'Customer already exists by username':
      messageTranslate =
        'Lo sentimos, el nombre de usuario seleccionado ya está siendo utilizado, ingrese uno diferente';
      break;
    case 'Customer already exists by email':
      messageTranslate =
        'Lo sentimos, el correo ingresado ya está siendo utilizado, ingrese uno diferente';
      break;
    default:
      break;
  }
  return messageTranslate;
};

export function* handleErrorResponseRegister (
  error: any
) {
  yield put(registerFailed({ message: translateErrorRegister(error?.details?.message) }))
};

export function* handleErrorResponseSeon (
  error: any
) {
  yield put(registerFailed({ message: translateError(error?.error?.message) }))
};

