import { put, select } from 'redux-saga/effects';
import PromotionsUseCase from '@clean/domain/useCase/promotions/promotionsUseCase';
import PromotionsRepositoryImpl from '@clean/infrastructure/repositories/PromotionsRepositoryImpl';
import { openModalPromotions } from '@clean/application/redux/modal/actions/modals';
import { getPromotionCustomerSuccess, getPromotionCustomerRejected } from '../actions/index';
const PromotionRepo = new PromotionsRepositoryImpl();

export function* getPromotionsCustomer(action) {
  const promotionService = new PromotionsUseCase(PromotionRepo);
  const promotionsResult = yield promotionService.getPromotionCustomer(action.payload);
  if(promotionsResult.statusCode === 401){
    return yield put(getPromotionCustomerRejected());
  }
  if(promotionsResult.statusCode === 404){
    return yield put(getPromotionCustomerRejected());
  }
  if (promotionsResult.promotions.length > 0) {
    //changeActivePromotion
    const payload = {
      allPromotions: promotionsResult.promotions,
      promotionActive: promotionsResult.promotions[0].promotion
    }
    yield put(getPromotionCustomerSuccess(payload));
  } else {
    yield put(getPromotionCustomerRejected());
  }
}

export function* getPromotionsCustomerSuccess() {

  const promotionActive = yield select((state) => state.Promotions.promotionActive);
  if(promotionActive.id !== null){
    yield put(openModalPromotions())
  }

}
