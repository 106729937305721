import {
  AclTruePlayActionTypes
} from '@clean/application/redux/aclTruePlay/constant';

export function UrlTruePlayRequest (payload) {
  return { type: AclTruePlayActionTypes.REQUEST_GET_URL_TRUE_PLAY, payload }
}

export function UrlTruePlaySuccess (payload) {
  return { type: AclTruePlayActionTypes.SUCCESS_GET_URL_TRUE_PLAY, payload }
}

export function UrlTruePlayRejected () {
  return { type: AclTruePlayActionTypes.REJECTED_GET_URL_TRUE_PLAY }
}

export function BalanceTruePlayRequest (payload) {
  return { type: AclTruePlayActionTypes.REQUEST_GET_BALANCE_TRUE_PLAY, payload }
}

export function BalanceTruePlaySuccess (payload) {
  return { type: AclTruePlayActionTypes.SUCCESS_GET_BALANCE_TRUE_PLAY, payload }
}

export function BalanceTruePlayRejected () {
  return { type: AclTruePlayActionTypes.REJECTED_GET_BALANCE_TRUE_PLAY }
}

