import produce from 'immer';
import { fpActionTypes } from '../constants/fp';

const initialState = {
  countryDetected: process.env.NEXT_PUBLIC_NAME_COMMERCE === 'Betnroll.net' ? 'pe' : 'cl',
  userIp: 'juegaloIp',
  userCity: 'juegaloCity',
  documentType: 'Cédula',
  isPeruvian: false,
  isArgentine: false,
  hashFinger: 'WEB',
  createdFinger: null,
  countryBlockDetected: false,
  browserNotAllowed: false,
  loadingCountryDetected: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fpActionTypes.GETFULLFP:
      return {
        ...state,
        ...action.payload
      }
    case fpActionTypes.UPDATEDIP:
      return produce(state, draft => {
        draft.userIp = action.payload
      });
    case fpActionTypes.UPDATEDCITY:
      return {
        ...state,
        userCity: action.payload,
      }
    case fpActionTypes.UPDATEDHASHFINGER:
      return produce(state, draft => {
        draft.hashFinger = action.payload
        draft.createdFinger = new Date()
      });
    case fpActionTypes.COUNTRYDETECTED:
      return produce(state, draft => {
       draft.countryDetected = action.payload;
       draft.loadingCountryDetected = false;
    });
    case fpActionTypes.BROWSER_NOT_ALLOWED:
      return produce(state, draft => {
        draft.browserNotAllowed = true
    });
    case fpActionTypes.DOCUMENT_TYPE:
      return produce(state, draft => {
        draft.documentType = action.payload;
    });
    case fpActionTypes.RESET_BROWSER_NOT_ALLOWED:
      return produce(state, draft => {
        draft.browserNotAllowed = false
    });
    case fpActionTypes.VERIFICATION_BLOCK_COUNTRY_SUCCESS:
      return produce(state, draft => {
        draft.countryBlockDetected = action.payload
    });
    case fpActionTypes.SETVISITOR:
      return produce(state, draft => {
          draft.hashFinger =  action.payload
      });
    default:
      return state
  }
}

export default reducer
