import {
  GETPROMOTIONS_CUSTOMER_REQUEST,
  GETPROMOTIONS_CUSTOMER_SUCCESS,
  GETPROMOTIONS_CUSTOMER_REJECTED
} from '@clean/application/redux/promotions/constants/actionsTypes';

export function getPromotionCustomerRequest (payload) {
  return { type: GETPROMOTIONS_CUSTOMER_REQUEST, payload }
}
export function getPromotionCustomerSuccess (payload) {
  return { type: GETPROMOTIONS_CUSTOMER_SUCCESS, payload }
}
export function getPromotionCustomerRejected () {
  return { type: GETPROMOTIONS_CUSTOMER_REJECTED }
}
