import { select, put, all, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_COLORS,
    setLastFetch,
    successRequestColors,
    failedRequestColors
} from '@clean/application/redux/strapi/actions/colorConfig'
import StrapiUseCase from '@clean/domain/useCase/strapi/strapiUseCase';
import StrapiRepositoryImpl from '@clean/infrastructure/repositories/StrapiRepositoryImpl';
const StrapiRepo = new StrapiRepositoryImpl();
const StrapiService = new StrapiUseCase(StrapiRepo);

function* handleGetColors() {
    try {
        const response = yield StrapiService.getCountries();
        if (response && response.data && response.data.responseObj && response.data.responseObj.color_config && response.data.responseObj.color_config.data && response.data.responseObj.color_config.data.attributes) {
            const currentColors = response.data.responseObj.color_config.data.attributes;
            yield put(successRequestColors(currentColors));
            yield put(setLastFetch(new Date()));
        } else {
            console.error("Color attributes not found in response", response);
            yield put(failedRequestColors());
        }
    } catch (error) {
        console.error("Error fetching colors:", error);
        yield put(failedRequestColors());
    }
}


export default all([
    takeLatest(REQUEST_COLORS, handleGetColors)
]);
