import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import * as Sentry from "@sentry/nextjs";
import { getOneOrderSuccess, depositFailed, patchDepositDeliverySuccess } from '@clean/application/redux/deposit/actions/depositConfirm';

import axios from 'axios'

function* getOneDeposit (payload)  {
  try{
    let payloadOneOrder = {
      transactionId: payload
    }
    const { data:response } = yield axios.post(`/api/v2/deposits/GetDepositConfirm`, payloadOneOrder)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return response.error
    }
  }catch(err){
    Sentry.captureException(err);
    return []
  }
}

function* patchDepositDelivery (payload)  {
  try{
    let payloadOneOrder = {
      transactionId: payload
    }
    const { data:response } = yield axios.post(`/api/v2/deposits/PatchDepositDelivery`, payloadOneOrder)
    if(response.data && response.data.responseObj){
      return response.data.responseObj
    } else {
      return response.error
    }
  }catch(err){
    Sentry.captureException(err);
    return []
  }
}

function* handleGetOneOrder({payload}) {
  let Order = yield call(getOneDeposit,payload)
  if (Order.error !== undefined) {
    if(Order.code === 400){
      yield put(depositFailed({ message: 'Deposito Invalido'}))
    }
    if(Order.code === 404){
      yield put(depositFailed({ message: 'Error interno, contáctese por soporte'}))
    }
    if(Order.code === 500){
      yield put(depositFailed({ message: 'Error interno, contáctese por soporte'}))
    }
  } else {
    if(Order){
      yield put(getOneOrderSuccess(Order))
    }
  }
}

function* handlePatchDepositDelivery({payload}) {
  let Order = yield call(patchDepositDelivery,payload)
  if(Order){
    yield put(patchDepositDeliverySuccess(Order))
  }
}


export default all([
  takeLatest('@order/GETONEORDERREQUEST', handleGetOneOrder),
  takeLatest('@order/PATCHDEPOSITDELIVERYREQUEST', handlePatchDepositDelivery),
]);
