import { StrapiRepository } from '@clean/domain/repositories/strapi/strapiRepository';

export default class StrapiUseCase implements StrapiRepository {
  strapiRepo: StrapiRepository;

  constructor(strapsiRepo:StrapiRepository) {
    this.strapiRepo = strapsiRepo;
  }
  getStrapiLeaderboard(country: string, url: string): Promise<any> {
    return this.strapiRepo.getStrapiLeaderboard(country, url);
  }
  getStrapiLeaderboardPrincipal(country: string): Promise<any> {
    return this.strapiRepo.getStrapiLeaderboardPrincipal(country);
  }
  getStrapiLeaderboardPromotions(country: string): Promise<any> {
    return this.strapiRepo.getStrapiLeaderboardPromotions(country);
  }
  getStrapiCountdown(country: string): Promise<any> {
    return this.strapiRepo.getStrapiCountdown(country);
  }
  getStrapiLeaderboardMenu(country: string): Promise<any> {
    return this.strapiRepo.getStrapiLeaderboardMenu(country);
  }
  getCountries(): Promise<any> {
    return this.strapiRepo.getCountries();
  }
  getCountry(country: string): Promise<any> {
    return this.strapiRepo.getCountry(country);
  }
  getStrapiJackpotLandingByID(jackpot_id: string): Promise<any> {
    return this.strapiRepo.getStrapiJackpotLandingByID(jackpot_id);
  }
  getStrapiMenuNavbar(country: string): Promise<any> {
    return this.strapiRepo.getStrapiMenuNavbar(country);
  }
  getStrapiJackpotLandingByID(jackpot_id: string): Promise<any> {
    return this.strapiRepo.getStrapiJackpotLandingByID(jackpot_id);
  }
  getStrapiMenuNavbar(country: string): Promise<any> {
    return this.strapiRepo.getStrapiMenuNavbar(country);
  }
}
