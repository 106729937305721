import { LeaderboardRepository } from '@clean/domain/repositories/leaderboard/LeadeboardRepository';
export default class LeaderboardUseCase implements LeaderboardRepository {
  leaderboardRepo: LeaderboardRepository;

  constructor(TR: LeaderboardRepository) {
    this.leaderboardRepo = TR;
  }

  async GetStatusPlayer(data): Promise<any> {
    return this.leaderboardRepo.GetStatusPlayer(data);
  }
}
