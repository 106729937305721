import { VPNRepository } from '@clean/domain/repositories/Vpn/VpnRepository';

export default class VpnUseCase implements VPNRepository {
  vpnRepo: VPNRepository;

  constructor(VR:VPNRepository) {
    this.vpnRepo = VR;
  }

  async ValidateOrigin(
    ): Promise<any>{
    return this.vpnRepo.ValidateOrigin()
  }
}
