import { all, select, takeLatest, call, cancelled, put} from 'redux-saga/effects'
import axios from 'axios'
import {
  bonoPromotionalSuccess,
  bonoPromotionalFailed
} from '@clean/application/redux/bonus/actions/bonusPromotional'
import * as Sentry from "@sentry/nextjs";

function* activeBonusPromotional (PayloadGetBonus) {

  const cancelSource = axios.CancelToken.source()
  try {
    const bonusResponse = yield axios.post(`/api/v2/apiBonus/PostActiveBonusByCode`, PayloadGetBonus, { cancelToken: cancelSource.token })
    if (bonusResponse.data && bonusResponse.data.data) {
      return bonusResponse.data.data
    } else {
      return bonusResponse.data
    }
  } catch (err) {
    Sentry.captureException(err);
    if(err.message === 'Request failed with status code 401'){
      yield put(forceLogoutRequest())
    }
    return null
  }
  finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel)
    }
  }
}


function clone(obj) {
  var copy

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = []
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i])
    }
    return copy
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {}
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr])
    }
    return copy
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}

function* handleBonusPromotional ({payload}) {
  let userInfo = clone(yield select((state) => state.auth.userInfo))
  let PayloadGetBonus = {
    customerId: userInfo.customerId,
    code: payload,
    activatedBy: userInfo.customerId
  }
  const BonusPromotional = yield call(activeBonusPromotional, PayloadGetBonus)
  if (BonusPromotional.error !== undefined) {
    if(BonusPromotional.error.code === 5){
      yield put(bonoPromotionalFailed({ message: 'Bono Invalido, contáctese con soporte'}))
    }
    if(BonusPromotional.error.details?.code === 5){
      yield put(bonoPromotionalFailed({ message: 'Bono Invalido, contáctese con soporte'}))
    }
    if(BonusPromotional.error.code === 400){
      yield put(bonoPromotionalFailed({ message: 'Bono Invalido, contáctese con soporte'}))
    }
    if(BonusPromotional.error.code === 404){
      yield put(bonoPromotionalFailed({ message: 'Error interno, contáctese con soporte'}))
    }
    if(BonusPromotional.error.code === 412){
      yield put(bonoPromotionalFailed({ message: 'Bono no valido, contáctese con soporte'}))
    }
    if(BonusPromotional.error.code === 500){
      yield put(bonoPromotionalFailed({ message: 'Error interno, contáctese con soporte'}))
    }
  } else {
    yield put(bonoPromotionalSuccess())
  }

}

export default all([
  takeLatest('@bonus_promotional/BONOPROMOTIONAL_REQUEST',handleBonusPromotional)
])
