import { authActionTypes } from "../constants/auth"

export function logoutForCookie() {
  return { type: authActionTypes.LOGOUT_FOR_COOKIE }
}

export function verifyCookie() {
  return { type: authActionTypes.VERIFY_COOKIE }
}

export function forceLogoutRequest() {
  return { type: authActionTypes.LOGOUT_FORCE_REQUEST }
}

export function forceLogoutSuccess() {
  return { type: authActionTypes.LOGOUT_FORCE_SUCCESS }
}

export function loginRequest(payloadLogin) {
  return { type: authActionTypes.LOGINREQUEST, payloadLogin }
}

export function loginUser(infoUser) {
  return { type: authActionTypes.LOGIN, payload: infoUser }
}

export function loginSuccess() {
  return { type: authActionTypes.LOGINSUCCESS }
}

export function loginFailed(error) {
  return { type: authActionTypes.LOGINFAILED, payload: error }
}

export function loginResetErrors() {
  return { type: authActionTypes.LOGINRESETERROR }
}

export function inviteSuccess() {
  return { type: authActionTypes.INVITESUCCESS }
}

export function inviteFailed(error) {
  return { type: authActionTypes.INVITEFAILED, payload: error }
}

export function inviteResetErrors() {
  return { type: authActionTypes.INVITERESETERROR }
}

export function readMessage(idCustomer) {
  return {
    type: authActionTypes.READ_MESSAGE,
    idCustomer,
  }
}

export function getNotificationsRequest(infoUser) {
  return { type: authActionTypes.NOTIFICATIONS_REQUEST, payload: infoUser }
}

export function getBadgeRequest(payload) {
  return { type: authActionTypes.BADGE_REQUEST, payload }
}

export function updateNotificationsRequest(infoUser) {
  return { type: authActionTypes.UPDATE_NOTIFICATIONS_REQUEST, payload: infoUser }
}

export function getUser(infoUser) {
  return { type: authActionTypes.GET_USER, payload: infoUser }
}


export function updatedUser(infoUser) {
  return { type: authActionTypes.UPDATEDINFO, payload: infoUser }
}

export function updatedRefreshTokenRequest() {
  return { type: authActionTypes.UPDATE_REFRESHTOKEN_REQUEST }
}

export function updateBalanceRequest(idCustomer) {
  return { type: authActionTypes.UPDATE_BALANCE_REQUEST }
}

export function getFavoriteGamesRequest(payload) {
  return { type: authActionTypes.GET_FAVORITE_GAME_REQUEST, payload }
}

export function updateVerificationRequest(idCustomer) {
  return {
    type: authActionTypes.UPDATE_VERIFICATION_REQUEST,
    idCustomer,
  }
}

export function updatedIsInvite(isInvite) {
  return { type: authActionTypes.ISINVITE, payload: isInvite }
}

export function resetFormLogin() {
  return { type: authActionTypes.RESET_LOGIN }
}

export function logoutRequest() {
  return { type: authActionTypes.LOGOUTREQUEST }
}

export function logoutUser() {
  return { type: authActionTypes.LOGOUT }
}

export function refreshFingerPrint() {
  return { type: authActionTypes.REFRESH_FINGERPRINT }
}

export function setHaveDeposit(haveDeposit: boolean) {
  return { type: authActionTypes.SET_HAVE_DEPOSIT, payload: haveDeposit }
}
