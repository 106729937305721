import produce from 'immer';
import { bonusCodePromotionalActionsTypes } from '../constants/bonusCodePromotional';

const CodePromotionalInitialState = {
  processAddCodePromotional: false,
  loadingBonusCodePromotional: false,
  errorsBonusCodePromotional: []
}

const reducer = (state = CodePromotionalInitialState, action) => {
  switch (action.type) {
    //ADD
    case bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_REQUEST:
      return produce(state, draft => {
        draft.loadingBonusCodePromotional = true
        draft.processAddCodePromotional = false
    });
    case bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_SUCCESS:
      return produce(state, draft => {
        draft.loadingBonusCodePromotional = false
        draft.processAddCodePromotional = true
    });
    //COMMON
    case bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_RESET:
      return produce(state, draft => {
        draft.loadingBonusCodePromotional = false
        draft.processAddCodePromotional = false
      });
    case bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_RESETERRORS:
      return produce(state, draft => {
        draft.errorsBonusCodePromotional = []
      });
    case bonusCodePromotionalActionsTypes.BONUS_CODE_PROMOTIONAL_FAILED:
      return produce(state,draft => {
        draft.loadingBonusCodePromotional = false
        draft.errorsBonusCodePromotional.push(action.payload)
      })
    default:
      return state
  }
}

export default reducer
