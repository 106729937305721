import {
  VIEWPROMOTIONS_CUSTOMER_REQUEST,
  VIEWPROMOTIONS_CUSTOMER_SUCCESS,
  VIEWPROMOTIONS_CUSTOMER_REJECTED
} from '@clean/application/redux/promotions/constants/actionsTypes';

export function viewPromotionCustomerRequest (payload) {
  return { type: VIEWPROMOTIONS_CUSTOMER_REQUEST, payload }
}
export function viewPromotionCustomerSuccess (payload) {
  return { type: VIEWPROMOTIONS_CUSTOMER_SUCCESS, payload }
}
export function viewPromotionCustomerRejected () {
  return { type: VIEWPROMOTIONS_CUSTOMER_REJECTED }
}
