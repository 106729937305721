import { all, select, takeLatest, call, cancelled, put } from 'redux-saga/effects'
import axios from 'axios'
import {
  searchGameSuccess,
  searchGameFailed,
  allGameShow
} from '@clean/application/redux/games/actions/casino'
import {
  forceLogoutRequest
} from '@clean/application/redux/auth/actions/auth'
import * as Sentry from "@sentry/nextjs";
import { casinoActionTypes } from '../constants/casino';

function* getGames({
  searchGame,
  producerSelected,
  page,
  filterOffset,
  filterLimit,
  countryDetected,
  tabSelected,
  isMobile
}) {
  const cancelSource = axios.CancelToken.source()
  try {
    let payloadGetGame = {
      "limit": filterLimit,
      "offset": filterOffset,
      "country": countryDetected,
      "producerId": producerSelected.Id !== null ? producerSelected.producerId : null,
      "search": searchGame,
      "isMobile": isMobile,
      "isLive": false,
      "order": tabSelected
    }
    const gamesResponse = yield axios.post(`/api/v2/games/GetGames`, payloadGetGame, { cancelToken: cancelSource.token })
    if (gamesResponse.data && gamesResponse.data.data && gamesResponse.data.data.responseObj.games.length > 0) {
      if (filterLimit >= gamesResponse.data.data.responseObj.count) {
        yield put(allGameShow(true))
      } else {
        yield put(allGameShow(false))
      }
      return gamesResponse.data.data.responseObj.games
    } else {
      return []
    }
  } catch (err) {
    Sentry.captureException(err);
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest())
    }
    console.log(err)
    return []
  }
  finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel)
    }
  }
}

function* getFavoriteGames({
  searchGame,
  producerSelected,
  page,
  filterOffset,
  filterLimit,
  countryDetected,
  tabSelected,
  userInfo,
  isMobile
}) {
  const cancelSource = axios.CancelToken.source()
  try {
    let payloadGetGame = {
      "limit": filterLimit,
      "offset": filterOffset,
      "country": countryDetected,
      "producerId": producerSelected.Id !== null ? producerSelected.producerId : null,
      "search": searchGame,
      "isMobile": isMobile,
      "isLive": false,
      "order": tabSelected,
      "customerId": userInfo.customerId
    }
    const gamesFavoriteResponse = yield axios.post(`/api/v2/games/GetFavoriteGameForCasino`, payloadGetGame, { cancelToken: cancelSource.token })
    if (gamesFavoriteResponse.data && gamesFavoriteResponse.data.data.responseObj.data.length > 0) {
      if (filterLimit >= gamesFavoriteResponse.data.data.responseObj.count) {
        yield put(allGameShow(true))
      } else {
        yield put(allGameShow(false))
      }
      return gamesFavoriteResponse.data.data.responseObj.data
    } else {
      return []
    }
  } catch (err) {
    Sentry.captureException(err);
    if (err.message === 'Request failed with status code 401') {
      yield put(forceLogoutRequest())
    }
    console.log(err)
    return []
  }
  finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel)
    }
  }
}

function* handleSearchGameRequest() {

  //order for search and isMobile
  const isMobile = yield select((state) => state.casino.isMobile)
  const userLogged = yield select((state) => state.auth.userLogged)
  const userInfo = yield select((state) => state.auth.userInfo)
  const tabSelected = yield select((state) => state.casino.tabSelected)
  const countryDetected = yield select((state) => state.fp.countryDetected)
  const searchGame = yield select((state) => state.casino.searchGame)
  const producerSelected = yield select((state) => state.casino.producerSelected)
  const page = yield select((state) => state.casino.page)
  const filterOffset = yield select((state) => state.casino.filterOffset)
  const filterLimit = yield select((state) => state.casino.filterLimit)
  let games
  if (tabSelected === 'favorite') {
    userLogged ? games = yield call(getFavoriteGames, { searchGame, producerSelected, page, filterOffset, filterLimit, countryDetected, tabSelected, userInfo, isMobile }) : games = []
  } else {
    games = yield call(getGames, { searchGame, producerSelected, page, filterOffset, filterLimit, countryDetected, tabSelected, isMobile })
  }

  if (games === null) {
    yield put(searchGameFailed())
  }

  yield put(searchGameSuccess(games))
}

export default all([
  takeLatest(casinoActionTypes.SEARCH_GAME_REQUEST, handleSearchGameRequest)
])
