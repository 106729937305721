import {
  GET_PROMOTIONS_LEADERBOARD_REJECTED,
  GET_PROMOTIONS_LEADERBOARD_SUCCESS,
  GET_PROMOTIONS_LEADERBOARD_REQUEST
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiLeaderboardPromotionsRequest (payload) {
  return { type: GET_PROMOTIONS_LEADERBOARD_REQUEST, payload }
}
export function getStrapiLeaderboardPromotionsSuccess (payload) {
  return { type: GET_PROMOTIONS_LEADERBOARD_SUCCESS, payload }
}
export function getStrapiLeaderboardPromotionsRejected () {
  return { type: GET_PROMOTIONS_LEADERBOARD_REJECTED }
}
