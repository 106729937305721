import {PromotionsRepository } from '@clean/domain/repositories/promotions/promotionsRepository';
import axios from 'axios';

export default class PromotionsRepositoryImpl implements PromotionsRepository {

  async getPromotionCustomer(customerId: number): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const cancelSource = axios.CancelToken.source()
        const { data: promotionsResult } = await axios.post(`/api/v2/promotions/getPromotionCustomer`, { customerId }, { cancelToken: cancelSource.token });
        resolver(promotionsResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async viewPromotionCustomer(customerId: number, promotionId: number): Promise<any>{
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: promotionsResult } = await axios.post(`/api/v2/promotions/viewPromotionCustomer`, { customerId, promotionId });
        resolver(promotionsResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
