import produce from 'immer';
import { preferencesActionTypes } from '../constants/preferences';

const PreferencesInitialState = {
  loadingFavoriteGame: false,
  favoriteGame: [],
  filterFavoriteGame: [],
}

const reducer = (state = PreferencesInitialState, action) => {
  switch (action.type) {
    case preferencesActionTypes.PUTFAVORITEGAME:
      return produce(state, draft => {
        draft.favoriteGame = action.payload
        draft.filterFavoriteGame = action.payload
      });
    case preferencesActionTypes.SEARCHGAMEREQUESTFAVORITE:
      return produce(state, draft => {
        draft.loadingFavoriteGame = true
      });
    case preferencesActionTypes.SEARCHGAMESUCCESSFAVORITE:
      return produce(state, draft => {
        draft.filterFavoriteGame = action.payload
        draft.loadingFavoriteGame = false
      });
    default:
      return state
  }
}

export default reducer
