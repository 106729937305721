import { put } from 'redux-saga/effects';
import { getStrapiLeaderboardPrincipalRejected, getStrapiLeaderboardPrincipalSuccess } from '@clean/application/redux/strapi/actions';
import StrapiUseCase from '@clean/domain/useCase/strapi/strapiUseCase';
import StrapiRepositoryImpl from '@clean/infrastructure/repositories/StrapiRepositoryImpl';
const StrapiRepo = new StrapiRepositoryImpl();

export function* getStrapiLeaderboardPrincipal(action) {
  const strapiService = new StrapiUseCase(StrapiRepo);
  const { data: strapiResult } = yield strapiService.getStrapiLeaderboardPrincipal(
    action.payload.country);
  if (!strapiResult?.responseObj || strapiResult?.responseCode != 200) {
    return yield put(getStrapiLeaderboardPrincipalRejected());
  }
  if (strapiResult.responseObj) {
    const payload = {
      data: strapiResult.responseObj
    }
    yield put(getStrapiLeaderboardPrincipalSuccess(payload));
  } else {
    yield put(getStrapiLeaderboardPrincipalRejected());
  }
}

