// import { IAuth } from '@clean/common/domain/entities/authEntity'
import type { DataError } from '@clean/domain/entities/dataError';
import { Either } from '@clean/domain/entities/either';
import type { FPRepository } from '@clean/domain/repositories/fpRepository';

const FINGERPRINTPRO_API = process.env.NEXT_PUBLIC_FINGERPRINTPRO_API;

class FPRepositoryImpl implements FPRepository {
  async GetFullData(Visitor: string): Promise<Either<DataError, any>> {
    return new Promise(async (resolver, _reject) => {
      try {
        const response = await fetch(`https://api.fpjs.io/visitors/${Visitor}?token=${FINGERPRINTPRO_API}`);
        if (!response.ok) {
            throw new Error('HTTP error ' + response.status);
        }
        const fpResult = await response.json();
        resolver(Either.right(fpResult));
      } catch (error) {
        resolver(Either.left({ kind: 'UnexpectedError', error }));
      }
    });
  }
}

export default FPRepositoryImpl;
