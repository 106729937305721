export const OrdersActionTypes = {
  //Get ONE
  GETONEORDERREQUEST: '@order/GETONEORDERREQUEST',
  GETONEORDERSUCCESS: '@order/GETONEORDERSUCCESS',
  //Patch ONE
  PATCHDEPOSITDELIVERYREQUEST: '@order/PATCHDEPOSITDELIVERYREQUEST',
  PATCHDEPOSITDELIVERYSUCCESS: '@order/PATCHDEPOSITDELIVERYSUCCESS',
  //COMMON
  DEPOSITFAILED: '@deposit/DEPOSITFAILED',
  RESETERRORSDEPOSIT: '@deposit/RESETERRORSDEPOSIT',
  RESETDEPOSIT: '@deposit/RESETDEPOSIT'
}