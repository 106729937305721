import produce from 'immer';
import { FooterActionsTypes } from '../constants/footer';

const initialState = {
  countryUsed: '',
  menuFooter: [],
  contents: [],
  socialMedia: [],
  providersFooter: [],
  lastUpdatedFooter: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FooterActionsTypes.GET_MENU_FOOTER:
      return produce(state, draft => {
      })
    case FooterActionsTypes.GET_MENU_FOOTER_SUCCESS:
      return produce(state, draft => {
        draft.menuFooter = action.payload.menuFooter,
        draft.contents = action.payload.contents,
        draft.socialMedia = action.payload.socialMedia,
        draft.providersFooter = action.payload.providersFooter,
        draft.countryUsed = action.payload.countryUsed,
        draft.lastUpdatedFooter = new Date()
      })
    case FooterActionsTypes.GET_MENU_FOOTER_REJECT:
      return produce(state, draft => {
      })
    default:
      return state
  }
}

export default reducer
