import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestColors } from '@clean/application/redux/strapi/actions/colorConfig';

const ColorConfig: React.FC = () => {
    const dispatch = useDispatch();
    const currentColors = useSelector((state: any) => state.colors.currentColors);
    const lastFetch = useSelector((state: any) => state.colors.lastFetch);

    const allowedColorNames = [
        'bg-dark', 'bg-medium', 'bg-light','primary','secondary', 'juegalo_body_color', 'juegalo_gris_oscuro',
        'white', 'black', "bg-navbar", "body-font", "juegalo_amarillo",
        'gray-dark', 'gray-medium', 'gray-light', 'gray-disabled', 'gray-soft',
        'orange', "juegalo_azul", "bg-countdown-american-cup", "orange",
        'green', 'green-dark', 'green-confirm', 'green-win', 'green-max',
        'red', 'red-light',
        'blue-link', 'facebook', 'calypso',
        'green-support', 'red-support', 'yellow-support', 'blue-support',
    ];

    const applyColors = (colors: Record<string, string>) => {
        if (colors) {
          const filteredColors = Object.keys(colors)
            .filter(key => allowedColorNames.includes(key))
            .reduce((obj, key) => {
              obj[key] = colors[key];
              return obj;
            }, {} as Record<string, string>);

          Object.keys(filteredColors).forEach((key) => {
            document.documentElement.style.setProperty(`--${key}`, filteredColors[key]);
          });
        }
      };

    useEffect(() => {

        const now = Date.now();

        if (!lastFetch || now - lastFetch > 24 * 60 * 60 * 1000) {
            dispatch(requestColors());
        } else if (currentColors?.colors) {
            applyColors(currentColors?.colors);
        }
    }, [currentColors, lastFetch]);

    return null;
};

export default ColorConfig;
