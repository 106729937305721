export const MODAL_TYPES = {
  POPUP_MARKETING_PE: 'POPUP_MARKETING_PE',
  POPUP_MARKETING_CL: 'POPUP_MARKETING_CL',
  POPUP_SESSION_REMINDER: 'POPUP_SESSION_REMINDER',
  TYC_REGISTER: 'TYC_REGISTER',
};

export const ModalActionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  RESET_MODAL: 'RESET_MODAL'
}
