import type { DataError } from '@clean/domain/entities/dataError';
import type { Either } from '@clean/domain/entities/either';

import type { locationWorkerRepository } from '../repositories/locationWorkerRepository';

class LocationWorkerUseCase implements locationWorkerRepository {
  locationWorkerRepo: locationWorkerRepository;

  constructor(LW: locationWorkerRepository) {
    this.locationWorkerRepo = LW;
  }

  async GetLocationByWorker(): Promise<Either<DataError, any>> {
    return this.locationWorkerRepo.GetLocationByWorker();
  }
}

export default LocationWorkerUseCase;
