export const casinoActionTypes = {
  SEARCH_GAME_REQUEST: '@casino/SEARCH_GAME_REQUEST',
  SEARCH_GAME_SUCCESS: '@casino/SEARCH_GAME_SUCCESS',
  SEARCH_GAME_FAILED: '@casino/SEARCH_GAME_FAILED',
  CHANGE_PRODUCER: '@casino/CHANGE_PRODUCER',
  CHANGE_PAGE: '@casino/CHANGE_PAGE',
  LOAD_MORE_GAME: '@casino/LOAD_MORE_GAME',
  CHANGE_SEARCH_INPUT: '@casino/CHANGE_SEARCH_INPUT',
  CHANGE_FILTER_LIMIT: '@casino/CHANGE_FILTER_LIMIT',
  CASINORESETERROR: '@casino/CASINORESETERROR',
  CHANGE_TAB: '@casino/CHANGE_TAB',
  ALL_GAME_SHOW: '@casino/ALL_GAME_SHOW',
  CHANGE_IS_MOBILE: '@casino/CHANGE_IS_MOBILE',
  CASINORESET: '@casino/CASINORESET',
  CASINORESETINPUTSEARCH: '@casino/CASINORESETINPUTSEARCH'
}