
interface IPosition {
  customerId: number,
  position: number,
  tickets: number,
  userName: string
}

interface IRanking {
  topData: Array<IPosition>,
  customer: {
    position: number,
    tickets: number,
    username: string
  }
}

export const updatedRanking = ({
  topData,
  customer
} : IRanking
) => ({
  topData,
  customer
});
