import produce from 'immer';
import { depositLimitActionTypes } from '@clean/application/redux/limitDeposit/constants/limitDeposit';

const DepositLimitInitialState = {
  isLimitActive: false,
  type: null,
  limitAmount: null,
  currentPercentage: 0,
  remainingAmountToLimit: 0,
  cancellationInProgress: false,
  updateInProgress: false,
  cancelLimitDate: null,
  updateLimitDate: null,
  updateLimitAmount: 0,
  loading: false,
  limitDeposits: [],
  postCompleted: false,
  blockDeposit: false
}

const reducer = (state = DepositLimitInitialState, action) => {
  switch (action.type) {
    case depositLimitActionTypes.REQUEST_GET_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_POST_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_PATCH_CANCEL_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_PATCH_UPDATE_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.blockDeposit = true;
        draft.loading = true;
        draft.postCompleted = false
      });
    case depositLimitActionTypes.REQUEST_POST_SUCCESS_REQUEST_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_PATCH_UPDATE_REQUEST_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.postCompleted = true
        draft.loading = false;
      });
    case depositLimitActionTypes.REQUEST_PATCH_CANCEL_REQUEST_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.loading = false;
      });
    case depositLimitActionTypes.REQUEST_GET_SUCCESS_REQUEST_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.limitDeposits = action?.payload ?? [];
        draft.loading = false;
      });
    case depositLimitActionTypes.REQUEST_GET_REJECT_REQUEST_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.blockDeposit = true;
        draft.loading = false;
      });
    case depositLimitActionTypes.REQUEST_POST_REJECT_REQUEST_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_PATCH_UPDATE_REJECT_REQUEST_DEPOSIT_LIMIT:
    case depositLimitActionTypes.REQUEST_PATCH_CANCEL_REJECT_REQUEST_DEPOSIT_LIMIT:
      return produce(state, draft => {
        draft.loading = false;
      });
    default:
      return state
  }
}

export default reducer;
