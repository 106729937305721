import { StrapiRepository } from '@clean/domain/repositories/strapi/strapiRepository';
import axios from 'axios';

export default class StrapiRepositoryImpl implements StrapiRepository {

  async getStrapiLeaderboard(country: string, url: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiLeaderboardResult } = await axios.post(`/api/v2/apiStrapi/GetStrapi`, { url, country });
        resolver(strapiLeaderboardResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiLeaderboardPrincipal(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiLeaderboardPrincipalResult } = await axios.post(`/api/v2/apiStrapi/GetStrapiHomeLeaderboard`,
          { country });
        resolver(strapiLeaderboardPrincipalResult);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiLeaderboardPromotions(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiLeaderboardPromotions } = await axios.post(`/api/v2/apiStrapi/GetStrapiCampingLeaderboard`,
          { country });
        resolver(strapiLeaderboardPromotions);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiResponsibleGambling(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiResponsibleGambling } = await axios.post(`/api/v2/apiStrapi/GetResponsibleGambling`,
          { country });
        resolver(strapiResponsibleGambling);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiLeaderboardMenu(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiLeaderboardMenu } = await axios.post(`/api/v2/apiStrapi/GetStrapiMenuLeaderboard`,
          { country });
        resolver(strapiLeaderboardMenu);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiCountdown(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiCountdown } = await axios.post(`/api/v2/apiStrapi/GetCountdown`,
          { country });
        resolver(strapiCountdown);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getCountries(): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiCountries } = await axios.get(`/api/v2/apiStrapi/GetCountries`);
        resolver(strapiCountries);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getCountry(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiCountries } = await axios.post(`/api/v2/apiStrapi/GetCountry`, { country: country.payload });
        resolver(strapiCountries);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiJackpotLandingByID(jackpot_id: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiJackpotLandingByID } = await axios.get(`/api/v2/apiStrapi/GetStrapiJackpotLandingByID/${jackpot_id}`);
        resolver(strapiJackpotLandingByID);
      } catch (error) {
        resolver(error);
      }
    });
  }

  async getStrapiMenuNavbar(country: string): Promise<any> {
    return new Promise(async (resolver, _reject) => {
      try {
        const { data: strapiMenusBar } = await axios.post(`/api/v2/apiStrapi/GetMenuNavbar`,
        { country });        
        resolver(strapiMenusBar);
      } catch (error) {
        resolver(error);
      }
    });
  }

}
