import {
  ModalActionTypes
} from '@clean/application/redux/modal/constant';

export function showModal (payload) {
  return { type: ModalActionTypes.SHOW_MODAL, payload }
}

export function hideModal () {
  return { type: ModalActionTypes.HIDE_MODAL }
}

export function resetModal () {
  return { type: ModalActionTypes.RESET_MODAL }
}

