import { configurationPagesActionTypes } from "../constants/configurationPages"

const configurationPagesInitialState = {
  request_configuration_loading: false,
  request_configuration_success: false,
  configuration: [],
}

const reducer = (state = configurationPagesInitialState, action) => {
  switch (action.type) {
    case configurationPagesActionTypes.START_GET_CONFIGURATION:
      return Object.assign({}, state, {
        request_configuration_loading: true,
        request_configuration_success: false,
        configuration: [],
      })
    case configurationPagesActionTypes.SAVE_GET_CONFIGURATION:
      return Object.assign({}, state, {
        request_configuration_loading: false,
        request_configuration_success: true,
        configuration: action.payload,
      })
    default:
      return state
  }
}

export default reducer
