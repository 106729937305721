import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  requestRejectResponsibleGamblingConfig,
  requestSuccessResponsibleGamblingConfig
} from '@clean/application/redux/strapi/actions/responsibleGambling';
import axios from 'axios';
import { responsibleGamblingActionTypes } from '@clean/application/redux/strapi/actions/responsibleGambling';

function* GetResponsibleGamblingConfig() {
  const country = yield select((state) => state.fp.countryDetected);
  try {
    let result = yield axios.post(`/api/v2/apiStrapi/GetResponsibleGambling`, { country });
    if (result) {
      return result.data.data.responseObj
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

function* handleGetResponsibleGamblingConfig() {
  try{
    let Status = yield call(GetResponsibleGamblingConfig)
    if(Status !== null){
      yield put(requestSuccessResponsibleGamblingConfig(Status))
    } else {
      yield put(requestRejectResponsibleGamblingConfig())
    }

  }catch(e){
    console.log(e)
  }
}

export default all([
  takeLatest(responsibleGamblingActionTypes.REQUEST_RESPOSIBLE_GAMBLING, handleGetResponsibleGamblingConfig),
]);
