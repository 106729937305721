import { bonusActionsTypes } from "../constants/bonusPromotional"

export function bonoPromotionalRequest (code) {
  return { type: bonusActionsTypes.BONOPROMOTIONAL_REQUEST, payload: code }
}

export function bonoPromotionalSuccess(bono) {
  return { type: bonusActionsTypes.BONOPROMOTIONAL_SUCCESS, bono }
}

export function bonoPromotionalFailed(error) {
  return { type: bonusActionsTypes.BONOPROMOTIONAL_FAILED, payload: error }
}

export function bonoPromotionalReset() {
  return { type: bonusActionsTypes.RESETBONOPROMOTIONAL }
}

export function bonoPromotionalResetErrors() {
  return { type: bonusActionsTypes.RESETERRORSBONOPROMOTIONAL}
}



