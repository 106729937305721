export const authActionTypes = {
    LOGIN: 'LOGIN',
    LOGINREQUEST: '@auth/LOGINREQUEST',
    LOGINSUCCESS: '@auth/LOGINSUCCESS',
    LOGINFAILED: '@auth/LOGINFAILED',
    LOGINRESETERROR: '@auth/LOGINRESETERROR',
    INVITESUCCESS: '@auth/INVITESUCCESS',
    INVITEFAILED: '@auth/INVITEFAILED',
    INVITERESETERROR: '@auth/INVITERESETERROR',
    GET_USER: 'GET_USER',
    UPDATEDINFO: 'UPDATEDINFO',
    ISINVITE: '@auth/ISINVITE',
    LOGOUT: 'LOGOUT',
    LOGOUTREQUEST: '@auth/LOGOUT_REQUEST',
    NOTIFICATIONS_REQUEST: '@auth/NOTIFICATIONS_REQUEST',
    REFRESH_FINGERPRINT: '@auth/REFRESH_FINGERPRINT',
    BADGE_REQUEST: '@auth/BADGE_REQUEST',
    UPDATE_BALANCE_REQUEST: '@auth/UPDATE_BALANCE_REQUEST',
    UPDATE_VERIFICATION_REQUEST: '@auth/UPDATE_VERIFICATION_REQUEST',
    UPDATE_REFRESHTOKEN_REQUEST: '@auth/UPDATE_REFRESHTOKEN_REQUEST',
    UPDATE_REFRESHTOKEN_DIGITAIN: '@auto/UPDATE_REFRESHTOKEN_DIGITAIN',
    READ_MESSAGE: '@auth/READ_MESSAGE',
    UPDATE_NOTIFICATIONS_REQUEST: '@auth/UPDATE_NOTIFICATIONS_REQUEST',
    VERIFICATION_COUNTRY_REQUEST: '@auth/VERIFICATION_COUNTRY_REQUEST',
    RESET_LOGIN: 'RESET_LOGIN',
    LOGOUT_FORCE_REQUEST: '@auth/LOGOUT_FORCE_REQUEST',
    LOGOUT_FORCE_SUCCESS: '@auth/LOGOUT_FORCE_SUCCESS',
    GET_FAVORITE_GAME_REQUEST: '@auth/GET_FAVORITE_GAME_REQUEST',
    VERIFY_COOKIE: '@auth/VERIFY_COOKIE',
    LOGOUT_FOR_COOKIE: '@auth/LOGOUT_FOR_COOKIE',
    SET_HAVE_DEPOSIT: '@auth/SET_HAVE_DEPOSIT'
  }