import {
  GET_HOME_LEADERBOARD_SUCCESS,
  GET_HOME_LEADERBOARD_REQUEST,
  GET_HOME_LEADERBOARD_REJECTED
} from '@clean/application/redux/strapi/constants/actionsTypes';

export function getStrapiLeaderboardPrincipalRequest (payload) {
  return { type: GET_HOME_LEADERBOARD_REQUEST, payload }
}
export function getStrapiLeaderboardPrincipalSuccess (payload) {
  return { type: GET_HOME_LEADERBOARD_SUCCESS, payload }
}
export function getStrapiLeaderboardPrincipalRejected () {
  return { type: GET_HOME_LEADERBOARD_REJECTED }
}
