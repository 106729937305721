import { all, takeLatest } from 'redux-saga/effects';
import {
  GETPROMOTIONS_CUSTOMER_REQUEST,
  GETPROMOTIONS_CUSTOMER_SUCCESS,
  VIEWPROMOTIONS_CUSTOMER_REQUEST,
  VIEWPROMOTIONS_CUSTOMER_SUCCESS
} from '@clean/application/redux/promotions/constants/actionsTypes';
import { getPromotionsCustomer, getPromotionsCustomerSuccess } from './getPromotionsCustomer';
import { viewPromotionsCustomer, viewPromotionsCustomerSuccess } from './viewPromotionsCustomer';

export default all([
  takeLatest(GETPROMOTIONS_CUSTOMER_REQUEST,  getPromotionsCustomer),
  takeLatest(GETPROMOTIONS_CUSTOMER_SUCCESS, getPromotionsCustomerSuccess),
  takeLatest(VIEWPROMOTIONS_CUSTOMER_REQUEST, viewPromotionsCustomer),
  takeLatest(VIEWPROMOTIONS_CUSTOMER_SUCCESS, viewPromotionsCustomerSuccess),
]);
