import { pendingActionTypes } from '../actions/pendingActions'
import produce from 'immer';
const uuid = require('uuid');


const pendingActionInitialState = {
  action: [{
    id: 1,
    type: 'updatedCustomer'
  }]
}

const reducer = (state = pendingActionInitialState, action) => {
  switch (action.type) {
    case pendingActionTypes.ADDACTIONPENDING:
      return produce(state, draft => {
        let generatedUuid = uuid.v4();
        draft.action.push({
          ...action.payload,
          id: generatedUuid
        })
      });
    case pendingActionTypes.REMOVEACTIONPENDING:
      return produce(state, draft => {
        const idDelete = action.payload;
        let newActions = draft.action.filter(item => item.id !== idDelete);
        draft.action = newActions;
      });
    case pendingActionTypes.RESETPENDINGACTIONS:
      return produce(state, draft => {
        draft.action = [];
      });
    default:
      return state
  }
}

export default reducer
