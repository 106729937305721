export const depositLimitActionTypes = {
  REQUEST_POST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_POST_DEPOSIT_LIMIT',
  REQUEST_POST_SUCCESS_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_POST_SUCCESS_REQUEST_DEPOSIT_LIMIT',
  REQUEST_POST_REJECT_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_POST_REJECT_REQUEST_DEPOSIT_LIMIT',
  REQUEST_GET_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_GET_DEPOSIT_LIMIT',
  REQUEST_GET_SUCCESS_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_GET_SUCCESS_REQUEST_DEPOSIT_LIMIT',
  REQUEST_GET_REJECT_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_GET_REJECT_REQUEST_DEPOSIT_LIMIT',
  REQUEST_PATCH_UPDATE_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_UPDATE_DEPOSIT_LIMIT',
  REQUEST_PATCH_UPDATE_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_UPDATE_REQUEST_DEPOSIT_LIMIT',
  REQUEST_PATCH_UPDATE_REJECT_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_UPDATE_REJECT_REQUEST_DEPOSIT_LIMIT',
  REQUEST_PATCH_CANCEL_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_CANCEL_DEPOSIT_LIMIT',
  REQUEST_PATCH_CANCEL_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_CANCEL_REQUEST_DEPOSIT_LIMIT',
  REQUEST_PATCH_CANCEL_REJECT_REQUEST_DEPOSIT_LIMIT: '@limitDeposit/REQUEST_PATCH_CANCEL_REJECT_REQUEST_DEPOSIT_LIMIT',
}