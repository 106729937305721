export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID; // This is your GA Tracking ID
export const GTM_ID = 'GTM-KGWDGHLN' // This is your GA Tracking ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (name, data) => {
  window.gtag('event', name, data)
}
