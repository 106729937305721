import { gamesActionTypes } from "../constants/games"

const gamesInitialState = {
  favorite: false,
}

const reducer = (state = gamesInitialState, action) => {
  switch (action.type) {
    case gamesActionTypes.CHANGEFAVORITE:
      return {
        favorite: action.payload,
      }
    default:
      return state
  }
}

export default reducer
