import { liveCasinoActionTypes } from "../constants/liveCasino"

export function searchGameRequest (payload) {
  return { type: liveCasinoActionTypes.SEARCH_GAME_REQUEST, payload }
}

export function getHomeLiveCasinoGame (payload) {
  return { type: liveCasinoActionTypes.GET_HOME_LIVECASINO_GAME, payload }
}

export function HomeLiveCasinoGameSuccess(games) {
  return { type: liveCasinoActionTypes.GET_HOME_LIVECASINO_GAME_SUCCESS, games }
}

export function searchGameSuccess(games) {
  return { type: liveCasinoActionTypes.SEARCH_GAME_SUCCESS, games }
}

export function searchGameFailed(error) {
  return { type: liveCasinoActionTypes.SEARCH_GAME_FAILED, payload: error }
}

export function loadMoreGameRequest (payload) {
  return { type: liveCasinoActionTypes.LOAD_MORE_GAME, payload }
}

export function changeSearchInput(input){
  return { type: liveCasinoActionTypes.CHANGE_SEARCH_INPUT, payload: input }
}

export function changeFilterLimit(limit){
  return { type: liveCasinoActionTypes.CHANGE_FILTER_LIMIT, limit }
}

export function changePage(page){
  return { type: liveCasinoActionTypes.CHANGE_PAGE, payload: page }
}

export function allGameShow(payload){
  return { type: liveCasinoActionTypes.ALL_GAME_SHOW, payload }
}

export function changeIsMobile(payload){
  return { type: liveCasinoActionTypes.CHANGE_IS_MOBILE, payload }
}

export function changeProducer(producer){
  return { type: liveCasinoActionTypes.CHANGE_PRODUCER, payload: producer }
}

export function changeTab(tab){
  return { type: liveCasinoActionTypes.CHANGE_TAB, payload: tab }
}

export function casinoReset() {
  return { type: liveCasinoActionTypes.CASINORESET }
}


export function casinoResetErrors() {
  return { type: casinoActionTypes.CASINORESETERROR }
}
